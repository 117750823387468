.flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-filter: FlipH;
          filter: FlipH;
  -ms-filter: "FlipH";
}
/*!
 * angular-confirm v1.1.0 (http://craftpip.github.io/angular-confirm/)
 * Author: boniface pereira
 * Website: www.craftpip.com
 * Contact: hey@craftpip.com
 *
 * Copyright 2016-2017 angular-confirm
 * Licensed under MIT (https://github.com/craftpip/angular-confirm/blob/master/LICENSE)
 */
@-webkit-keyframes ng-confirm-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes ng-confirm-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
body[class*=ng-confirm-no-scroll-] {
  overflow: hidden !important;
}
.ng-confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  font-family: inherit;
  overflow: hidden;
}
.ng-confirm .ng-confirm-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: opacity 0.4s, background 0.4s;
  transition: opacity 0.4s, background 0.4s;
}
.ng-confirm .ng-confirm-bg.ng-confirm-bg-h {
  opacity: 0 !important;
}
.ng-confirm .ng-confirm-scrollpane {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-perspective: 500px;
          perspective: 500px;
  -webkit-perspective-origin: center;
          perspective-origin: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ng-confirm .ng-confirm-box {
  background: white;
  border-radius: 4px;
  position: relative;
  outline: none;
  padding: 15px 15px 0;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.ng-confirm .ng-confirm-box.ng-confirm-loading {
  min-height: 120px;
}
.ng-confirm .ng-confirm-box.ng-confirm-loading:before {
  content: '';
  position: absolute;
  left: 0;
  background: white;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  z-index: 1;
}
.ng-confirm .ng-confirm-box.ng-confirm-loading:after {
  opacity: 0.6;
  content: '';
  height: 30px;
  width: 30px;
  border: solid 3px transparent;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  border-radius: 50%;
  -webkit-animation: ng-confirm-spin 1s infinite linear;
          animation: ng-confirm-spin 1s infinite linear;
  border-bottom-color: dodgerblue;
  top: 50%;
  margin-top: -15px;
  z-index: 2;
}
.ng-confirm .ng-confirm-box div.ng-confirm-closeIcon {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  opacity: 0.6;
  text-align: center;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
  font-size: 27px !important;
  line-height: 14px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.ng-confirm .ng-confirm-box div.ng-confirm-closeIcon:empty {
  display: none;
}
.ng-confirm .ng-confirm-box div.ng-confirm-closeIcon .fa,
.ng-confirm .ng-confirm-box div.ng-confirm-closeIcon .glyphicon,
.ng-confirm .ng-confirm-box div.ng-confirm-closeIcon .zmdi {
  font-size: 16px;
  line-height: 25px;
  vertical-align: bottom;
}
.ng-confirm .ng-confirm-box div.ng-confirm-closeIcon:hover {
  opacity: 1;
}
.ng-confirm .ng-confirm-box div.ng-confirm-title-c {
  display: block;
  font-size: 22px;
  line-height: 20px;
  padding-bottom: 15px;
}
.ng-confirm .ng-confirm-box div.ng-confirm-title-c .ng-confirm-icon-c {
  font-size: inherit;
  display: inline-block;
  vertical-align: top;
}
.ng-confirm .ng-confirm-box div.ng-confirm-title-c .ng-confirm-icon-c i {
  vertical-align: middle;
}
.ng-confirm .ng-confirm-box div.ng-confirm-title-c .ng-confirm-icon-c:empty {
  display: none;
}
.ng-confirm .ng-confirm-box div.ng-confirm-title-c .ng-confirm-title {
  font-size: inherit;
  font-family: inherit;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: initial;
     -moz-user-select: initial;
      -ms-user-select: initial;
          user-select: initial;
}
.ng-confirm .ng-confirm-box div.ng-confirm-title-c .ng-confirm-title:empty {
  display: none;
}
.ng-confirm .ng-confirm-box div.ng-confirm-content-pane {
  margin-bottom: 15px;
  height: 0px;
  display: inline-block;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.ng-confirm .ng-confirm-box div.ng-confirm-content-pane .ng-confirm-content {
  width: 100%;
  height: auto;
  -webkit-user-select: initial;
     -moz-user-select: initial;
      -ms-user-select: initial;
          user-select: initial;
}
.ng-confirm .ng-confirm-box div.ng-confirm-content-pane .ng-confirm-content img {
  width: 100%;
  height: auto;
}
.ng-confirm .ng-confirm-box div.ng-confirm-content-pane .ng-confirm-content:empty {
  display: none;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons {
  padding-bottom: 11px;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons > button {
  margin-bottom: 4px;
  margin-left: 2px;
  margin-right: 2px;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button {
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  min-height: 1em;
  outline: 0;
  -webkit-transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-tap-highlight-color: transparent;
  border: none;
  background-image: none;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-blue {
  background-color: #3498db;
  color: #FFF;
  text-shadow: none;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-blue:hover {
  background-color: #2980b9;
  color: #FFF;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-green {
  background-color: #2ecc71;
  color: #FFF;
  text-shadow: none;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-green:hover {
  background-color: #27ae60;
  color: #FFF;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-red {
  background-color: #e74c3c;
  color: #FFF;
  text-shadow: none;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-red:hover {
  background-color: #c0392b;
  color: #FFF;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-orange {
  background-color: #f1c40f;
  color: #FFF;
  text-shadow: none;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-orange:hover {
  background-color: #f39c12;
  color: #FFF;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-default {
  background-color: #ecf0f1;
  color: #000;
  text-shadow: none;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-default:hover {
  background-color: #bdc3c7;
  color: #000;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-purple {
  background-color: #9b59b6;
  color: #FFF;
  text-shadow: none;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-purple:hover {
  background-color: #8e44ad;
  color: #FFF;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-dark {
  background-color: #34495e;
  color: #FFF;
  text-shadow: none;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}
.ng-confirm .ng-confirm-box .ng-confirm-buttons button.btn-dark:hover {
  background-color: #2c3e50;
  color: #FFF;
}
@-webkit-keyframes type-blue {
  1%,
  100% {
    border-color: #3498db;
  }
  50% {
    border-color: #5faee3;
  }
}
@keyframes type-blue {
  1%,
  100% {
    border-color: #3498db;
  }
  50% {
    border-color: #5faee3;
  }
}
@-webkit-keyframes type-green {
  1%,
  100% {
    border-color: #2ecc71;
  }
  50% {
    border-color: #54d98c;
  }
}
@keyframes type-green {
  1%,
  100% {
    border-color: #2ecc71;
  }
  50% {
    border-color: #54d98c;
  }
}
@-webkit-keyframes type-red {
  1%,
  100% {
    border-color: #e74c3c;
  }
  50% {
    border-color: #ed7669;
  }
}
@keyframes type-red {
  1%,
  100% {
    border-color: #e74c3c;
  }
  50% {
    border-color: #ed7669;
  }
}
@-webkit-keyframes type-orange {
  1%,
  100% {
    border-color: #f1c40f;
  }
  50% {
    border-color: #f4d03f;
  }
}
@keyframes type-orange {
  1%,
  100% {
    border-color: #f1c40f;
  }
  50% {
    border-color: #f4d03f;
  }
}
@-webkit-keyframes type-purple {
  1%,
  100% {
    border-color: #9b59b6;
  }
  50% {
    border-color: #b07cc6;
  }
}
@keyframes type-purple {
  1%,
  100% {
    border-color: #9b59b6;
  }
  50% {
    border-color: #b07cc6;
  }
}
@-webkit-keyframes type-dark {
  1%,
  100% {
    border-color: #34495e;
  }
  50% {
    border-color: #46627f;
  }
}
@keyframes type-dark {
  1%,
  100% {
    border-color: #34495e;
  }
  50% {
    border-color: #46627f;
  }
}
.ng-confirm.ng-confirm-type-animated .ng-confirm-box {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.ng-confirm.ng-confirm-type-blue .ng-confirm-box {
  border-top: solid 7px #3498db;
  -webkit-animation-name: type-blue;
          animation-name: type-blue;
}
.ng-confirm.ng-confirm-type-green .ng-confirm-box {
  border-top: solid 7px #2ecc71;
  -webkit-animation-name: type-green;
          animation-name: type-green;
}
.ng-confirm.ng-confirm-type-red .ng-confirm-box {
  border-top: solid 7px #e74c3c;
  -webkit-animation-name: type-red;
          animation-name: type-red;
}
.ng-confirm.ng-confirm-type-orange .ng-confirm-box {
  border-top: solid 7px #f1c40f;
  -webkit-animation-name: type-orange;
          animation-name: type-orange;
}
.ng-confirm.ng-confirm-type-purple .ng-confirm-box {
  border-top: solid 7px #9b59b6;
  -webkit-animation-name: type-purple;
          animation-name: type-purple;
}
.ng-confirm.ng-confirm-type-dark .ng-confirm-box {
  border-top: solid 7px #34495e;
  -webkit-animation-name: type-dark;
          animation-name: type-dark;
}
.ng-confirm .ng-confirm-clear {
  clear: both;
}
.ng-confirm.ng-confirm-rtl {
  direction: rtl;
}
.ng-confirm .ng-confirm-box.ng-confirm-hilight.ng-confirm-hilight-shake {
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ng-confirm .ng-confirm-box.ng-confirm-hilight.ng-confirm-hilight-glow {
  -webkit-animation: glow 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: glow 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-2px, 0, 0);
            transform: translate3d(-2px, 0, 0);
  }
  20%,
  80% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-8px, 0, 0);
            transform: translate3d(-8px, 0, 0);
  }
  40%,
  60% {
    -webkit-transform: translate3d(8px, 0, 0);
            transform: translate3d(8px, 0, 0);
  }
}
@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-2px, 0, 0);
            transform: translate3d(-2px, 0, 0);
  }
  20%,
  80% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-8px, 0, 0);
            transform: translate3d(-8px, 0, 0);
  }
  40%,
  60% {
    -webkit-transform: translate3d(8px, 0, 0);
            transform: translate3d(8px, 0, 0);
  }
}
@-webkit-keyframes glow {
  0%,
  100% {
    -webkit-box-shadow: 0 0 3px red;
            box-shadow: 0 0 3px red;
  }
  50% {
    -webkit-box-shadow: 0 0 30px red;
            box-shadow: 0 0 30px red;
  }
}
@keyframes glow {
  0%,
  100% {
    -webkit-box-shadow: 0 0 3px red;
            box-shadow: 0 0 3px red;
  }
  50% {
    -webkit-box-shadow: 0 0 30px red;
            box-shadow: 0 0 30px red;
  }
}
/*Transition rules*/
.ng-confirm {
  -webkit-perspective: 400px;
          perspective: 400px;
}
.ng-confirm .ng-confirm-box {
  opacity: 1;
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-top,
.ng-confirm .ng-confirm-box.ng-confirm-animation-left,
.ng-confirm .ng-confirm-box.ng-confirm-animation-right,
.ng-confirm .ng-confirm-box.ng-confirm-animation-bottom,
.ng-confirm .ng-confirm-box.ng-confirm-animation-opacity,
.ng-confirm .ng-confirm-box.ng-confirm-animation-zoom,
.ng-confirm .ng-confirm-box.ng-confirm-animation-scale,
.ng-confirm .ng-confirm-box.ng-confirm-animation-none,
.ng-confirm .ng-confirm-box.ng-confirm-animation-rotate,
.ng-confirm .ng-confirm-box.ng-confirm-animation-rotatex,
.ng-confirm .ng-confirm-box.ng-confirm-animation-rotatey,
.ng-confirm .ng-confirm-box.ng-confirm-animation-scaley,
.ng-confirm .ng-confirm-box.ng-confirm-animation-scalex {
  opacity: 0;
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-rotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-rotatex {
  -webkit-transform: rotateX(90deg);
          transform: rotateX(90deg);
  -webkit-transform-origin: center;
          transform-origin: center;
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-rotatexr {
  -webkit-transform: rotateX(-90deg);
          transform: rotateX(-90deg);
  -webkit-transform-origin: center;
          transform-origin: center;
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-rotatey {
  -webkit-transform: rotatey(90deg);
          transform: rotatey(90deg);
  -webkit-transform-origin: center;
          transform-origin: center;
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-rotateyr {
  -webkit-transform: rotatey(-90deg);
          transform: rotatey(-90deg);
  -webkit-transform-origin: center;
          transform-origin: center;
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-scaley {
  -webkit-transform: scaley(1.5);
          transform: scaley(1.5);
  -webkit-transform-origin: center;
          transform-origin: center;
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-scalex {
  -webkit-transform: scalex(1.5);
          transform: scalex(1.5);
  -webkit-transform-origin: center;
          transform-origin: center;
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-top {
  -webkit-transform: translate(0px, -100px);
          transform: translate(0px, -100px);
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-left {
  -webkit-transform: translate(-100px, 0px);
          transform: translate(-100px, 0px);
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-right {
  -webkit-transform: translate(100px, 0px);
          transform: translate(100px, 0px);
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-bottom {
  -webkit-transform: translate(0px, 100px);
          transform: translate(0px, 100px);
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-zoom {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-scale {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
.ng-confirm .ng-confirm-box.ng-confirm-animation-none {
  visibility: hidden;
}
.ng-confirm.ng-confirm-light .ng-confirm-bg,
.ng-confirm.ng-confirm-white .ng-confirm-bg {
  background-color: #444;
  opacity: 0.2;
}
.ng-confirm.ng-confirm-light .ng-confirm-icon-c,
.ng-confirm.ng-confirm-white .ng-confirm-icon-c {
  margin-right: 8px;
}
.ng-confirm.ng-confirm-light.ng-confirm-rtl .ng-confirm-closeIcon,
.ng-confirm.ng-confirm-white.ng-confirm-rtl .ng-confirm-closeIcon {
  left: 5px !important;
  right: auto !important;
}
.ng-confirm.ng-confirm-light.ng-confirm-rtl .ng-confirm-icon-c,
.ng-confirm.ng-confirm-white.ng-confirm-rtl .ng-confirm-icon-c {
  margin-right: 0;
  margin-left: 8px;
}
.ng-confirm.ng-confirm-light .ng-confirm-box,
.ng-confirm.ng-confirm-white .ng-confirm-box {
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.ng-confirm.ng-confirm-light .ng-confirm-box .ng-confirm-closeIcon,
.ng-confirm.ng-confirm-white .ng-confirm-box .ng-confirm-closeIcon {
  line-height: 20px;
}
.ng-confirm.ng-confirm-light .ng-confirm-box .ng-confirm-buttons,
.ng-confirm.ng-confirm-white .ng-confirm-box .ng-confirm-buttons {
  float: right;
}
.ng-confirm.ng-confirm-light .ng-confirm-box .ng-confirm-buttons button,
.ng-confirm.ng-confirm-white .ng-confirm-box .ng-confirm-buttons button {
  border: none;
  background-image: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-shadow: none;
  -webkit-transition: background 0.1s;
  transition: background 0.1s;
  color: white;
}
.ng-confirm.ng-confirm-light .ng-confirm-box .ng-confirm-buttons button.btn-default,
.ng-confirm.ng-confirm-white .ng-confirm-box .ng-confirm-buttons button.btn-default {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #333;
}
.ng-confirm.ng-confirm-light .ng-confirm-box .ng-confirm-buttons button.btn-default:hover,
.ng-confirm.ng-confirm-white .ng-confirm-box .ng-confirm-buttons button.btn-default:hover {
  background: #ddd;
}
.ng-confirm.ng-confirm-dark .ng-confirm-bg,
.ng-confirm.ng-confirm-black .ng-confirm-bg {
  background-color: darkslategray;
  opacity: 0.4;
}
.ng-confirm.ng-confirm-dark .ng-confirm-icon-c,
.ng-confirm.ng-confirm-black .ng-confirm-icon-c {
  margin-right: 8px;
}
.ng-confirm.ng-confirm-dark.ng-confirm-rtl .ng-confirm-closeIcon,
.ng-confirm.ng-confirm-black.ng-confirm-rtl .ng-confirm-closeIcon {
  left: 5px !important;
  right: auto !important;
}
.ng-confirm.ng-confirm-dark.ng-confirm-rtl .ng-confirm-icon-c,
.ng-confirm.ng-confirm-black.ng-confirm-rtl .ng-confirm-icon-c {
  margin-right: 0;
  margin-left: 8px;
}
.ng-confirm.ng-confirm-dark .ng-confirm-box,
.ng-confirm.ng-confirm-black .ng-confirm-box {
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background: #444;
  border-radius: 5px;
}
.ng-confirm.ng-confirm-dark .ng-confirm-box .ng-confirm-closeIcon,
.ng-confirm.ng-confirm-black .ng-confirm-box .ng-confirm-closeIcon {
  line-height: 20px;
}
.ng-confirm.ng-confirm-dark .ng-confirm-box.ng-confirm-loading:before,
.ng-confirm.ng-confirm-black .ng-confirm-box.ng-confirm-loading:before {
  background: #444;
}
.ng-confirm.ng-confirm-dark .ng-confirm-box.ng-confirm-loading:after,
.ng-confirm.ng-confirm-black .ng-confirm-box.ng-confirm-loading:after {
  border-bottom-color: deepskyblue;
}
.ng-confirm.ng-confirm-dark .ng-confirm-box *:not(input):not(textarea):not(select):not(option),
.ng-confirm.ng-confirm-black .ng-confirm-box *:not(input):not(textarea):not(select):not(option) {
  color: white;
}
.ng-confirm.ng-confirm-dark .ng-confirm-box input,
.ng-confirm.ng-confirm-black .ng-confirm-box input,
.ng-confirm.ng-confirm-dark .ng-confirm-box textarea,
.ng-confirm.ng-confirm-black .ng-confirm-box textarea,
.ng-confirm.ng-confirm-dark .ng-confirm-box select,
.ng-confirm.ng-confirm-black .ng-confirm-box select,
.ng-confirm.ng-confirm-dark .ng-confirm-box option,
.ng-confirm.ng-confirm-black .ng-confirm-box option {
  color: initial;
}
.ng-confirm.ng-confirm-dark .ng-confirm-box .ng-confirm-buttons,
.ng-confirm.ng-confirm-black .ng-confirm-box .ng-confirm-buttons {
  float: right;
}
.ng-confirm.ng-confirm-dark .ng-confirm-box .ng-confirm-buttons button,
.ng-confirm.ng-confirm-black .ng-confirm-box .ng-confirm-buttons button {
  border: none;
  background-image: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-shadow: none;
  -webkit-transition: background 0.1s;
  transition: background 0.1s;
  color: white;
}
.ng-confirm.ng-confirm-dark .ng-confirm-box .ng-confirm-buttons button.btn-default,
.ng-confirm.ng-confirm-black .ng-confirm-box .ng-confirm-buttons button.btn-default {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  background: none;
}
.ng-confirm.ng-confirm-dark .ng-confirm-box .ng-confirm-buttons button.btn-default:hover,
.ng-confirm.ng-confirm-black .ng-confirm-box .ng-confirm-buttons button.btn-default:hover {
  background: #666;
}
.ng-confirm.ng-confirm-modern .ng-confirm-bg {
  background-color: slategray;
  opacity: 0.6;
}
.ng-confirm.ng-confirm-modern .ng-confirm-icon-c {
  margin-right: 8px;
}
.ng-confirm.ng-confirm-modern .ng-confirm-box {
  background-color: white;
  -webkit-box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
          box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  padding: 25px 25px 10px 25px;
}
.ng-confirm.ng-confirm-modern .ng-confirm-box div.ng-confirm-closeIcon {
  color: rgba(0, 0, 0, 0.87);
  border-radius: 50px;
  height: 25px;
  width: 25px;
  line-height: 25px !important;
  top: 10px;
  right: 10px;
  -webkit-box-shadow: 0 0 0 2px #ddd;
          box-shadow: 0 0 0 2px #ddd;
}
.ng-confirm.ng-confirm-modern .ng-confirm-box div.ng-confirm-title-c {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
.ng-confirm.ng-confirm-modern .ng-confirm-box div.ng-confirm-title-c .ng-confirm-icon-c {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 10px;
  font-size: 69px;
  color: #aaa;
}
.ng-confirm.ng-confirm-modern .ng-confirm-box div.ng-confirm-content {
  font-size: 15px;
  color: #777;
  margin-bottom: 25px;
}
.ng-confirm.ng-confirm-modern .ng-confirm-box .ng-confirm-buttons {
  text-align: center;
}
.ng-confirm.ng-confirm-modern .ng-confirm-box .ng-confirm-buttons button {
  -webkit-transition: background 0.1s;
  transition: background 0.1s;
}
.ng-confirm.ng-confirm-modern .ng-confirm-box .ng-confirm-buttons button + button {
  margin-left: 4px;
}
.ng-confirm-el-hide {
  display: none !important;
}
/**
 * Table mixins
 *
 * Only used to generate .dc-table specific padding, border and alignment styles.
 *
 * See: /less/tables/table.less
 *
 **/
.dc-table {
  background-color: #fbfbfb;
  border-collapse: collapse;
  max-width: 100%;
  width: 100%;
  font-variant-numeric: tabular-nums slashed-zero;
  letter-spacing: -0.02em;
  white-space: nowrap;
}
.dc-table--transparent {
  background-color: transparent;
}
.dc-table--brighter {
  background-color: #fefefe;
}
.dc-table--width-auto {
  width: auto;
}
.dc-table--separate {
  border-collapse: separate;
  border-spacing: 3px;
}
.dc-table--fixed {
  table-layout: fixed;
  width: auto;
}
.dc-table--nowrap th:not(.wrap),
.dc-table--nowrap td:not(.wrap) {
  white-space: nowrap;
}
.dc-table-column--alternate {
  background-color: rgba(0, 0, 0, 0.03);
}
.dc-table th,
.dc-table td {
  padding: 5px;
  text-align: left;
}
.dc-table th {
  font-weight: bold;
}
.dc-table--zebra > tbody > tr:nth-child(odd) {
  background-color: #f1f5f8;
}
.dc-table--zebra > tbody > tr:nth-child(odd).child {
  background-color: #fafcff;
}
.dc-table--zebra > tbody > tr.child:nth-child(even) {
  background-color: #fff;
}
.dc-table--zebra > tbody > tr.child:nth-child(even) td {
  border-color: #e1edff;
}
.dc-table--zebra > tbody > tr:last-child:not(:nth-child(odd)) {
  border-bottom: 0px;
}
.dc-table .numbers-neutral {
  background-color: transparent;
}
.dc-table .numbers-bad {
  background-color: #FDDEDE;
  border-color: #fcd0d0 !important;
  color: #9f0a0a;
}
.dc-table .numbers-good {
  background-color: #D2ECD4;
  border-color: #c4e6c6 !important;
  color: #2a622e;
}
.dc-table--hover tbody tr:hover:not(.no-hover) th,
.dc-table--hover tbody tr:hover:not(.no-hover) td,
.dc-table--hover tbody tr:hover:not(.no-hover) .numbers-neutral {
  background-color: rgba(0, 0, 0, 0.05);
}
.dc-table--hover tbody tr:hover:not(.no-hover) .numbers-bad {
  background-color: #fcc6c6;
}
.dc-table--hover tbody tr:hover:not(.no-hover) .numbers-good {
  background-color: #c0e4c3;
}
.dc-table .sortables th:not(.not-sortable) {
  cursor: pointer;
}
.dc-table .not-sortable {
  line-height: 25px;
  white-space: nowrap;
}
.dc-table .not-sortable .fa-stack {
  width: 15px;
}
.dc-table tr:not(:hover) .show-row-hovered {
  display: none;
}
.dc-table tr:hover .hide-row-hovered {
  display: none;
}
.dc-table tr:hover .show-row-hovered {
  display: table-cell;
}
/**
 *
 */
.sorting-indicator {
  border-radius: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.sorting-indicator--active {
  background-color: #4a196f;
  color: #fff;
}
.sorting-indicator--active .fa-arrow-down {
  color: #fff;
}
.sorting-indicator__content {
  padding: 1px 1px 1px 8px;
}
.sorting-indicator__icon-wrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: 22px;
  width: 22px;
  position: relative;
}
.sorting-indicator__icon {
  border-radius: 20px;
  color: #8795a1;
  font-size: 0.6em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 22px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.sorting-indicator__icon .fa {
  -webkit-transition: -webkit-transform 250ms ease-in-out;
  transition: -webkit-transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
}
.sorting-indicator__icon > span,
.sorting-indicator__icon > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 2px;
}
.sorting-indicator__icon > span:hover,
.sorting-indicator__icon > a:hover,
.sorting-indicator__icon > span:focus,
.sorting-indicator__icon > a:focus {
  text-decoration: none;
}
.sorting-indicator__icon > a {
  display: none;
}
.sorting-indicator--active:not(.sorting-indicator--default):hover .sorting-indicator__icon,
.sorting-indicator--active:not(.sorting-indicator--default):hover .sorting-indicator__icon {
  background-color: #4a196f;
  width: 44px;
}
.sorting-indicator--active:not(.sorting-indicator--default):hover .sorting-indicator__icon a,
.sorting-indicator--active:not(.sorting-indicator--default):hover .sorting-indicator__icon a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.sorting-indicator--active .sorting-indicator__icon--asc .fa-arrow-down {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
}
/**
 * .dc-table padding styles
 *
 * Usage:
 *
 * <table class="dc-table padding-medium">
 * 		<tr class="padding-large">
 *			<td></td> // large padding
 *			<td></td> // large padding
 * 		</tr>
 * 		<tr>
 * 			<td class="padding-small"></td> // small padding
 * 			<td class="padding-horizontal-large"></td> // large padding left and right, medium padding top and bottom
 * 		</tr>
 * 	</table>
 *
 **/
.dc-table.padding-none th,
.dc-table.padding-none td,
.dc-table tr.padding-none th,
.dc-table tr.padding-none td,
.dc-table.dc-table th.padding-none,
.dc-table.dc-table td.padding-none {
  padding: 0px;
}
.dc-table.padding-top-none th,
.dc-table.padding-top-none td,
.dc-table tr.padding-top-none th,
.dc-table tr.padding-top-none td,
.dc-table.dc-table th.padding-top-none,
.dc-table.dc-table td.padding-top-none {
  padding-top: 0px;
}
.dc-table.padding-right-none th,
.dc-table.padding-right-none td,
.dc-table tr.padding-right-none th,
.dc-table tr.padding-right-none td,
.dc-table.dc-table th.padding-right-none,
.dc-table.dc-table td.padding-right-none {
  padding-right: 0px;
}
.dc-table.padding-bottom-none th,
.dc-table.padding-bottom-none td,
.dc-table tr.padding-bottom-none th,
.dc-table tr.padding-bottom-none td,
.dc-table.dc-table th.padding-bottom-none,
.dc-table.dc-table td.padding-bottom-none {
  padding-bottom: 0px;
}
.dc-table.padding-left-none th,
.dc-table.padding-left-none td,
.dc-table tr.padding-left-none th,
.dc-table tr.padding-left-none td,
.dc-table.dc-table th.padding-left-none,
.dc-table.dc-table td.padding-left-none {
  padding-left: 0px;
}
.dc-table.padding-horizontal-none th,
.dc-table.padding-horizontal-none td,
.dc-table tr.padding-horizontal-none th,
.dc-table tr.padding-horizontal-none td,
.dc-table.dc-table th.padding-horizontal-none,
.dc-table.dc-table td.padding-horizontal-none {
  padding-left: 0px;
  padding-right: 0px;
}
.dc-table.padding-vertical-none th,
.dc-table.padding-vertical-none td,
.dc-table tr.padding-vertical-none th,
.dc-table tr.padding-vertical-none td,
.dc-table.dc-table th.padding-vertical-none,
.dc-table.dc-table td.padding-vertical-none {
  padding-top: 0px;
  padding-bottom: 0px;
}
.dc-table.padding-small th,
.dc-table.padding-small td,
.dc-table tr.padding-small th,
.dc-table tr.padding-small td,
.dc-table.dc-table th.padding-small,
.dc-table.dc-table td.padding-small {
  padding: 3px;
}
.dc-table.padding-top-small th,
.dc-table.padding-top-small td,
.dc-table tr.padding-top-small th,
.dc-table tr.padding-top-small td,
.dc-table.dc-table th.padding-top-small,
.dc-table.dc-table td.padding-top-small {
  padding-top: 3px;
}
.dc-table.padding-right-small th,
.dc-table.padding-right-small td,
.dc-table tr.padding-right-small th,
.dc-table tr.padding-right-small td,
.dc-table.dc-table th.padding-right-small,
.dc-table.dc-table td.padding-right-small {
  padding-right: 3px;
}
.dc-table.padding-bottom-small th,
.dc-table.padding-bottom-small td,
.dc-table tr.padding-bottom-small th,
.dc-table tr.padding-bottom-small td,
.dc-table.dc-table th.padding-bottom-small,
.dc-table.dc-table td.padding-bottom-small {
  padding-bottom: 3px;
}
.dc-table.padding-left-small th,
.dc-table.padding-left-small td,
.dc-table tr.padding-left-small th,
.dc-table tr.padding-left-small td,
.dc-table.dc-table th.padding-left-small,
.dc-table.dc-table td.padding-left-small {
  padding-left: 3px;
}
.dc-table.padding-horizontal-small th,
.dc-table.padding-horizontal-small td,
.dc-table tr.padding-horizontal-small th,
.dc-table tr.padding-horizontal-small td,
.dc-table.dc-table th.padding-horizontal-small,
.dc-table.dc-table td.padding-horizontal-small {
  padding-left: 3px;
  padding-right: 3px;
}
.dc-table.padding-vertical-small th,
.dc-table.padding-vertical-small td,
.dc-table tr.padding-vertical-small th,
.dc-table tr.padding-vertical-small td,
.dc-table.dc-table th.padding-vertical-small,
.dc-table.dc-table td.padding-vertical-small {
  padding-top: 3px;
  padding-bottom: 3px;
}
.dc-table.padding-medium th,
.dc-table.padding-medium td,
.dc-table tr.padding-medium th,
.dc-table tr.padding-medium td,
.dc-table.dc-table th.padding-medium,
.dc-table.dc-table td.padding-medium {
  padding: 5px;
}
.dc-table.padding-top-medium th,
.dc-table.padding-top-medium td,
.dc-table tr.padding-top-medium th,
.dc-table tr.padding-top-medium td,
.dc-table.dc-table th.padding-top-medium,
.dc-table.dc-table td.padding-top-medium {
  padding-top: 5px;
}
.dc-table.padding-right-medium th,
.dc-table.padding-right-medium td,
.dc-table tr.padding-right-medium th,
.dc-table tr.padding-right-medium td,
.dc-table.dc-table th.padding-right-medium,
.dc-table.dc-table td.padding-right-medium {
  padding-right: 5px;
}
.dc-table.padding-bottom-medium th,
.dc-table.padding-bottom-medium td,
.dc-table tr.padding-bottom-medium th,
.dc-table tr.padding-bottom-medium td,
.dc-table.dc-table th.padding-bottom-medium,
.dc-table.dc-table td.padding-bottom-medium {
  padding-bottom: 5px;
}
.dc-table.padding-left-medium th,
.dc-table.padding-left-medium td,
.dc-table tr.padding-left-medium th,
.dc-table tr.padding-left-medium td,
.dc-table.dc-table th.padding-left-medium,
.dc-table.dc-table td.padding-left-medium {
  padding-left: 5px;
}
.dc-table.padding-horizontal-medium th,
.dc-table.padding-horizontal-medium td,
.dc-table tr.padding-horizontal-medium th,
.dc-table tr.padding-horizontal-medium td,
.dc-table.dc-table th.padding-horizontal-medium,
.dc-table.dc-table td.padding-horizontal-medium {
  padding-left: 5px;
  padding-right: 5px;
}
.dc-table.padding-vertical-medium th,
.dc-table.padding-vertical-medium td,
.dc-table tr.padding-vertical-medium th,
.dc-table tr.padding-vertical-medium td,
.dc-table.dc-table th.padding-vertical-medium,
.dc-table.dc-table td.padding-vertical-medium {
  padding-top: 5px;
  padding-bottom: 5px;
}
.dc-table.padding-large th,
.dc-table.padding-large td,
.dc-table tr.padding-large th,
.dc-table tr.padding-large td,
.dc-table.dc-table th.padding-large,
.dc-table.dc-table td.padding-large {
  padding: 1em;
}
.dc-table.padding-top-large th,
.dc-table.padding-top-large td,
.dc-table tr.padding-top-large th,
.dc-table tr.padding-top-large td,
.dc-table.dc-table th.padding-top-large,
.dc-table.dc-table td.padding-top-large {
  padding-top: 1em;
}
.dc-table.padding-right-large th,
.dc-table.padding-right-large td,
.dc-table tr.padding-right-large th,
.dc-table tr.padding-right-large td,
.dc-table.dc-table th.padding-right-large,
.dc-table.dc-table td.padding-right-large {
  padding-right: 1em;
}
.dc-table.padding-bottom-large th,
.dc-table.padding-bottom-large td,
.dc-table tr.padding-bottom-large th,
.dc-table tr.padding-bottom-large td,
.dc-table.dc-table th.padding-bottom-large,
.dc-table.dc-table td.padding-bottom-large {
  padding-bottom: 1em;
}
.dc-table.padding-left-large th,
.dc-table.padding-left-large td,
.dc-table tr.padding-left-large th,
.dc-table tr.padding-left-large td,
.dc-table.dc-table th.padding-left-large,
.dc-table.dc-table td.padding-left-large {
  padding-left: 1em;
}
.dc-table.padding-horizontal-large th,
.dc-table.padding-horizontal-large td,
.dc-table tr.padding-horizontal-large th,
.dc-table tr.padding-horizontal-large td,
.dc-table.dc-table th.padding-horizontal-large,
.dc-table.dc-table td.padding-horizontal-large {
  padding-left: 1em;
  padding-right: 1em;
}
.dc-table.padding-vertical-large th,
.dc-table.padding-vertical-large td,
.dc-table tr.padding-vertical-large th,
.dc-table tr.padding-vertical-large td,
.dc-table.dc-table th.padding-vertical-large,
.dc-table.dc-table td.padding-vertical-large {
  padding-top: 1em;
  padding-bottom: 1em;
}
.dc-table.padding-xlarge th,
.dc-table.padding-xlarge td,
.dc-table tr.padding-xlarge th,
.dc-table tr.padding-xlarge td,
.dc-table.dc-table th.padding-xlarge,
.dc-table.dc-table td.padding-xlarge {
  padding: 2em;
}
.dc-table.padding-top-xlarge th,
.dc-table.padding-top-xlarge td,
.dc-table tr.padding-top-xlarge th,
.dc-table tr.padding-top-xlarge td,
.dc-table.dc-table th.padding-top-xlarge,
.dc-table.dc-table td.padding-top-xlarge {
  padding-top: 2em;
}
.dc-table.padding-right-xlarge th,
.dc-table.padding-right-xlarge td,
.dc-table tr.padding-right-xlarge th,
.dc-table tr.padding-right-xlarge td,
.dc-table.dc-table th.padding-right-xlarge,
.dc-table.dc-table td.padding-right-xlarge {
  padding-right: 2em;
}
.dc-table.padding-bottom-xlarge th,
.dc-table.padding-bottom-xlarge td,
.dc-table tr.padding-bottom-xlarge th,
.dc-table tr.padding-bottom-xlarge td,
.dc-table.dc-table th.padding-bottom-xlarge,
.dc-table.dc-table td.padding-bottom-xlarge {
  padding-bottom: 2em;
}
.dc-table.padding-left-xlarge th,
.dc-table.padding-left-xlarge td,
.dc-table tr.padding-left-xlarge th,
.dc-table tr.padding-left-xlarge td,
.dc-table.dc-table th.padding-left-xlarge,
.dc-table.dc-table td.padding-left-xlarge {
  padding-left: 2em;
}
.dc-table.padding-horizontal-xlarge th,
.dc-table.padding-horizontal-xlarge td,
.dc-table tr.padding-horizontal-xlarge th,
.dc-table tr.padding-horizontal-xlarge td,
.dc-table.dc-table th.padding-horizontal-xlarge,
.dc-table.dc-table td.padding-horizontal-xlarge {
  padding-left: 2em;
  padding-right: 2em;
}
.dc-table.padding-vertical-xlarge th,
.dc-table.padding-vertical-xlarge td,
.dc-table tr.padding-vertical-xlarge th,
.dc-table tr.padding-vertical-xlarge td,
.dc-table.dc-table th.padding-vertical-xlarge,
.dc-table.dc-table td.padding-vertical-xlarge {
  padding-top: 2em;
  padding-bottom: 2em;
}
.dc-table.padding-huge th,
.dc-table.padding-huge td,
.dc-table tr.padding-huge th,
.dc-table tr.padding-huge td,
.dc-table.dc-table th.padding-huge,
.dc-table.dc-table td.padding-huge {
  padding: 6em;
}
.dc-table.padding-top-huge th,
.dc-table.padding-top-huge td,
.dc-table tr.padding-top-huge th,
.dc-table tr.padding-top-huge td,
.dc-table.dc-table th.padding-top-huge,
.dc-table.dc-table td.padding-top-huge {
  padding-top: 6em;
}
.dc-table.padding-right-huge th,
.dc-table.padding-right-huge td,
.dc-table tr.padding-right-huge th,
.dc-table tr.padding-right-huge td,
.dc-table.dc-table th.padding-right-huge,
.dc-table.dc-table td.padding-right-huge {
  padding-right: 6em;
}
.dc-table.padding-bottom-huge th,
.dc-table.padding-bottom-huge td,
.dc-table tr.padding-bottom-huge th,
.dc-table tr.padding-bottom-huge td,
.dc-table.dc-table th.padding-bottom-huge,
.dc-table.dc-table td.padding-bottom-huge {
  padding-bottom: 6em;
}
.dc-table.padding-left-huge th,
.dc-table.padding-left-huge td,
.dc-table tr.padding-left-huge th,
.dc-table tr.padding-left-huge td,
.dc-table.dc-table th.padding-left-huge,
.dc-table.dc-table td.padding-left-huge {
  padding-left: 6em;
}
.dc-table.padding-horizontal-huge th,
.dc-table.padding-horizontal-huge td,
.dc-table tr.padding-horizontal-huge th,
.dc-table tr.padding-horizontal-huge td,
.dc-table.dc-table th.padding-horizontal-huge,
.dc-table.dc-table td.padding-horizontal-huge {
  padding-left: 6em;
  padding-right: 6em;
}
.dc-table.padding-vertical-huge th,
.dc-table.padding-vertical-huge td,
.dc-table tr.padding-vertical-huge th,
.dc-table tr.padding-vertical-huge td,
.dc-table.dc-table th.padding-vertical-huge,
.dc-table.dc-table td.padding-vertical-huge {
  padding-top: 6em;
  padding-bottom: 6em;
}
/**
 * .dc-table border styles
 *
 * Usage:
 *
 * <table class="dc-table border-bottom-light">
 * 		<tr class="border-bottom-heavy">
 *			<td></td> // heavy border bottom
 *			<td class="border-right-light"></td> // heavy border bottom, light border right
 * 		</tr>
 * 		<tr>
 * 			<td></td> // light border bottom
 * 			<td class="border-horizontal-light"></td> // light border bottom, light border left and right
 * 		</tr>
 * 	</table>
 *
 **/
.dc-table.border-none th,
.dc-table.border-none td,
.dc-table tr.border-none th,
.dc-table tr.border-none td,
.dc-table.dc-table th.border-none,
.dc-table.dc-table td.border-none {
  border: 0px;
}
.dc-table.border-top-none th,
.dc-table.border-top-none td,
.dc-table tr.border-top-none th,
.dc-table tr.border-top-none td,
.dc-table.dc-table th.border-top-none,
.dc-table.dc-table td.border-top-none {
  border-top: 0px;
}
.dc-table.border-right-none th,
.dc-table.border-right-none td,
.dc-table tr.border-right-none th,
.dc-table tr.border-right-none td,
.dc-table.dc-table th.border-right-none,
.dc-table.dc-table td.border-right-none {
  border-right: 0px;
}
.dc-table.border-bottom-none th,
.dc-table.border-bottom-none td,
.dc-table tr.border-bottom-none th,
.dc-table tr.border-bottom-none td,
.dc-table.dc-table th.border-bottom-none,
.dc-table.dc-table td.border-bottom-none {
  border-bottom: 0px;
}
.dc-table.border-left-none th,
.dc-table.border-left-none td,
.dc-table tr.border-left-none th,
.dc-table tr.border-left-none td,
.dc-table.dc-table th.border-left-none,
.dc-table.dc-table td.border-left-none {
  border-left: 0px;
}
.dc-table.border-horizontal-none th,
.dc-table.border-horizontal-none td,
.dc-table tr.border-horizontal-none th,
.dc-table tr.border-horizontal-none td,
.dc-table.dc-table th.border-horizontal-none,
.dc-table.dc-table td.border-horizontal-none {
  border-right: 0px;
  border-left: 0px;
}
.dc-table.border-vertical-none th,
.dc-table.border-vertical-none td,
.dc-table tr.border-vertical-none th,
.dc-table tr.border-vertical-none td,
.dc-table.dc-table th.border-vertical-none,
.dc-table.dc-table td.border-vertical-none {
  border-top: 0px;
  border-bottom: 0px;
}
.dc-table.border-light th,
.dc-table.border-light td,
.dc-table tr.border-light th,
.dc-table tr.border-light td,
.dc-table.dc-table th.border-light,
.dc-table.dc-table td.border-light {
  border: 1px solid #e9e9e9;
}
.dc-table.border-top-light th,
.dc-table.border-top-light td,
.dc-table tr.border-top-light th,
.dc-table tr.border-top-light td,
.dc-table.dc-table th.border-top-light,
.dc-table.dc-table td.border-top-light {
  border-top: 1px solid #e9e9e9;
}
.dc-table.border-right-light th,
.dc-table.border-right-light td,
.dc-table tr.border-right-light th,
.dc-table tr.border-right-light td,
.dc-table.dc-table th.border-right-light,
.dc-table.dc-table td.border-right-light {
  border-right: 1px solid #e9e9e9;
}
.dc-table.border-bottom-light th,
.dc-table.border-bottom-light td,
.dc-table tr.border-bottom-light th,
.dc-table tr.border-bottom-light td,
.dc-table.dc-table th.border-bottom-light,
.dc-table.dc-table td.border-bottom-light {
  border-bottom: 1px solid #e9e9e9;
}
.dc-table.border-left-light th,
.dc-table.border-left-light td,
.dc-table tr.border-left-light th,
.dc-table tr.border-left-light td,
.dc-table.dc-table th.border-left-light,
.dc-table.dc-table td.border-left-light {
  border-left: 1px solid #e9e9e9;
}
.dc-table.border-horizontal-light th,
.dc-table.border-horizontal-light td,
.dc-table tr.border-horizontal-light th,
.dc-table tr.border-horizontal-light td,
.dc-table.dc-table th.border-horizontal-light,
.dc-table.dc-table td.border-horizontal-light {
  border-right: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
}
.dc-table.border-vertical-light th,
.dc-table.border-vertical-light td,
.dc-table tr.border-vertical-light th,
.dc-table tr.border-vertical-light td,
.dc-table.dc-table th.border-vertical-light,
.dc-table.dc-table td.border-vertical-light {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.dc-table.border-medium th,
.dc-table.border-medium td,
.dc-table tr.border-medium th,
.dc-table tr.border-medium td,
.dc-table.dc-table th.border-medium,
.dc-table.dc-table td.border-medium {
  border: 1px solid #d6d6d6;
}
.dc-table.border-top-medium th,
.dc-table.border-top-medium td,
.dc-table tr.border-top-medium th,
.dc-table tr.border-top-medium td,
.dc-table.dc-table th.border-top-medium,
.dc-table.dc-table td.border-top-medium {
  border-top: 1px solid #d6d6d6;
}
.dc-table.border-right-medium th,
.dc-table.border-right-medium td,
.dc-table tr.border-right-medium th,
.dc-table tr.border-right-medium td,
.dc-table.dc-table th.border-right-medium,
.dc-table.dc-table td.border-right-medium {
  border-right: 1px solid #d6d6d6;
}
.dc-table.border-bottom-medium th,
.dc-table.border-bottom-medium td,
.dc-table tr.border-bottom-medium th,
.dc-table tr.border-bottom-medium td,
.dc-table.dc-table th.border-bottom-medium,
.dc-table.dc-table td.border-bottom-medium {
  border-bottom: 1px solid #d6d6d6;
}
.dc-table.border-left-medium th,
.dc-table.border-left-medium td,
.dc-table tr.border-left-medium th,
.dc-table tr.border-left-medium td,
.dc-table.dc-table th.border-left-medium,
.dc-table.dc-table td.border-left-medium {
  border-left: 1px solid #d6d6d6;
}
.dc-table.border-horizontal-medium th,
.dc-table.border-horizontal-medium td,
.dc-table tr.border-horizontal-medium th,
.dc-table tr.border-horizontal-medium td,
.dc-table.dc-table th.border-horizontal-medium,
.dc-table.dc-table td.border-horizontal-medium {
  border-right: 1px solid #d6d6d6;
  border-left: 1px solid #d6d6d6;
}
.dc-table.border-vertical-medium th,
.dc-table.border-vertical-medium td,
.dc-table tr.border-vertical-medium th,
.dc-table tr.border-vertical-medium td,
.dc-table.dc-table th.border-vertical-medium,
.dc-table.dc-table td.border-vertical-medium {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}
.dc-table.border-heavy th,
.dc-table.border-heavy td,
.dc-table tr.border-heavy th,
.dc-table tr.border-heavy td,
.dc-table.dc-table th.border-heavy,
.dc-table.dc-table td.border-heavy {
  border: 1px solid #aaa;
}
.dc-table.border-top-heavy th,
.dc-table.border-top-heavy td,
.dc-table tr.border-top-heavy th,
.dc-table tr.border-top-heavy td,
.dc-table.dc-table th.border-top-heavy,
.dc-table.dc-table td.border-top-heavy {
  border-top: 1px solid #aaa;
}
.dc-table.border-right-heavy th,
.dc-table.border-right-heavy td,
.dc-table tr.border-right-heavy th,
.dc-table tr.border-right-heavy td,
.dc-table.dc-table th.border-right-heavy,
.dc-table.dc-table td.border-right-heavy {
  border-right: 1px solid #aaa;
}
.dc-table.border-bottom-heavy th,
.dc-table.border-bottom-heavy td,
.dc-table tr.border-bottom-heavy th,
.dc-table tr.border-bottom-heavy td,
.dc-table.dc-table th.border-bottom-heavy,
.dc-table.dc-table td.border-bottom-heavy {
  border-bottom: 1px solid #aaa;
}
.dc-table.border-left-heavy th,
.dc-table.border-left-heavy td,
.dc-table tr.border-left-heavy th,
.dc-table tr.border-left-heavy td,
.dc-table.dc-table th.border-left-heavy,
.dc-table.dc-table td.border-left-heavy {
  border-left: 1px solid #aaa;
}
.dc-table.border-horizontal-heavy th,
.dc-table.border-horizontal-heavy td,
.dc-table tr.border-horizontal-heavy th,
.dc-table tr.border-horizontal-heavy td,
.dc-table.dc-table th.border-horizontal-heavy,
.dc-table.dc-table td.border-horizontal-heavy {
  border-right: 1px solid #aaa;
  border-left: 1px solid #aaa;
}
.dc-table.border-vertical-heavy th,
.dc-table.border-vertical-heavy td,
.dc-table tr.border-vertical-heavy th,
.dc-table tr.border-vertical-heavy td,
.dc-table.dc-table th.border-vertical-heavy,
.dc-table.dc-table td.border-vertical-heavy {
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
}
.dc-table.border-blue th,
.dc-table.border-blue td,
.dc-table tr.border-blue th,
.dc-table tr.border-blue td,
.dc-table.dc-table th.border-blue,
.dc-table.dc-table td.border-blue {
  border: 2px solid #3fa3d8;
}
.dc-table.border-top-blue th,
.dc-table.border-top-blue td,
.dc-table tr.border-top-blue th,
.dc-table tr.border-top-blue td,
.dc-table.dc-table th.border-top-blue,
.dc-table.dc-table td.border-top-blue {
  border-top: 2px solid #3fa3d8;
}
.dc-table.border-right-blue th,
.dc-table.border-right-blue td,
.dc-table tr.border-right-blue th,
.dc-table tr.border-right-blue td,
.dc-table.dc-table th.border-right-blue,
.dc-table.dc-table td.border-right-blue {
  border-right: 2px solid #3fa3d8;
}
.dc-table.border-bottom-blue th,
.dc-table.border-bottom-blue td,
.dc-table tr.border-bottom-blue th,
.dc-table tr.border-bottom-blue td,
.dc-table.dc-table th.border-bottom-blue,
.dc-table.dc-table td.border-bottom-blue {
  border-bottom: 2px solid #3fa3d8;
}
.dc-table.border-left-blue th,
.dc-table.border-left-blue td,
.dc-table tr.border-left-blue th,
.dc-table tr.border-left-blue td,
.dc-table.dc-table th.border-left-blue,
.dc-table.dc-table td.border-left-blue {
  border-left: 2px solid #3fa3d8;
}
.dc-table.border-horizontal-blue th,
.dc-table.border-horizontal-blue td,
.dc-table tr.border-horizontal-blue th,
.dc-table tr.border-horizontal-blue td,
.dc-table.dc-table th.border-horizontal-blue,
.dc-table.dc-table td.border-horizontal-blue {
  border-right: 2px solid #3fa3d8;
  border-left: 2px solid #3fa3d8;
}
.dc-table.border-vertical-blue th,
.dc-table.border-vertical-blue td,
.dc-table tr.border-vertical-blue th,
.dc-table tr.border-vertical-blue td,
.dc-table.dc-table th.border-vertical-blue,
.dc-table.dc-table td.border-vertical-blue {
  border-top: 2px solid #3fa3d8;
  border-bottom: 2px solid #3fa3d8;
}
.dc-table.border-orange th,
.dc-table.border-orange td,
.dc-table tr.border-orange th,
.dc-table tr.border-orange td,
.dc-table.dc-table th.border-orange,
.dc-table.dc-table td.border-orange {
  border: 2px solid #d27300;
}
.dc-table.border-top-orange th,
.dc-table.border-top-orange td,
.dc-table tr.border-top-orange th,
.dc-table tr.border-top-orange td,
.dc-table.dc-table th.border-top-orange,
.dc-table.dc-table td.border-top-orange {
  border-top: 2px solid #d27300;
}
.dc-table.border-right-orange th,
.dc-table.border-right-orange td,
.dc-table tr.border-right-orange th,
.dc-table tr.border-right-orange td,
.dc-table.dc-table th.border-right-orange,
.dc-table.dc-table td.border-right-orange {
  border-right: 2px solid #d27300;
}
.dc-table.border-bottom-orange th,
.dc-table.border-bottom-orange td,
.dc-table tr.border-bottom-orange th,
.dc-table tr.border-bottom-orange td,
.dc-table.dc-table th.border-bottom-orange,
.dc-table.dc-table td.border-bottom-orange {
  border-bottom: 2px solid #d27300;
}
.dc-table.border-left-orange th,
.dc-table.border-left-orange td,
.dc-table tr.border-left-orange th,
.dc-table tr.border-left-orange td,
.dc-table.dc-table th.border-left-orange,
.dc-table.dc-table td.border-left-orange {
  border-left: 2px solid #d27300;
}
.dc-table.border-horizontal-orange th,
.dc-table.border-horizontal-orange td,
.dc-table tr.border-horizontal-orange th,
.dc-table tr.border-horizontal-orange td,
.dc-table.dc-table th.border-horizontal-orange,
.dc-table.dc-table td.border-horizontal-orange {
  border-right: 2px solid #d27300;
  border-left: 2px solid #d27300;
}
.dc-table.border-vertical-orange th,
.dc-table.border-vertical-orange td,
.dc-table tr.border-vertical-orange th,
.dc-table tr.border-vertical-orange td,
.dc-table.dc-table th.border-vertical-orange,
.dc-table.dc-table td.border-vertical-orange {
  border-top: 2px solid #d27300;
  border-bottom: 2px solid #d27300;
}
.dc-table.border-green th,
.dc-table.border-green td,
.dc-table tr.border-green th,
.dc-table tr.border-green td,
.dc-table.dc-table th.border-green,
.dc-table.dc-table td.border-green {
  border: 2px solid #2e8d17;
}
.dc-table.border-top-green th,
.dc-table.border-top-green td,
.dc-table tr.border-top-green th,
.dc-table tr.border-top-green td,
.dc-table.dc-table th.border-top-green,
.dc-table.dc-table td.border-top-green {
  border-top: 2px solid #2e8d17;
}
.dc-table.border-right-green th,
.dc-table.border-right-green td,
.dc-table tr.border-right-green th,
.dc-table tr.border-right-green td,
.dc-table.dc-table th.border-right-green,
.dc-table.dc-table td.border-right-green {
  border-right: 2px solid #2e8d17;
}
.dc-table.border-bottom-green th,
.dc-table.border-bottom-green td,
.dc-table tr.border-bottom-green th,
.dc-table tr.border-bottom-green td,
.dc-table.dc-table th.border-bottom-green,
.dc-table.dc-table td.border-bottom-green {
  border-bottom: 2px solid #2e8d17;
}
.dc-table.border-left-green th,
.dc-table.border-left-green td,
.dc-table tr.border-left-green th,
.dc-table tr.border-left-green td,
.dc-table.dc-table th.border-left-green,
.dc-table.dc-table td.border-left-green {
  border-left: 2px solid #2e8d17;
}
.dc-table.border-horizontal-green th,
.dc-table.border-horizontal-green td,
.dc-table tr.border-horizontal-green th,
.dc-table tr.border-horizontal-green td,
.dc-table.dc-table th.border-horizontal-green,
.dc-table.dc-table td.border-horizontal-green {
  border-right: 2px solid #2e8d17;
  border-left: 2px solid #2e8d17;
}
.dc-table.border-vertical-green th,
.dc-table.border-vertical-green td,
.dc-table tr.border-vertical-green th,
.dc-table tr.border-vertical-green td,
.dc-table.dc-table th.border-vertical-green,
.dc-table.dc-table td.border-vertical-green {
  border-top: 2px solid #2e8d17;
  border-bottom: 2px solid #2e8d17;
}
.dc-table.border-grey--dark th,
.dc-table.border-grey--dark td,
.dc-table tr.border-grey--dark th,
.dc-table tr.border-grey--dark td,
.dc-table.dc-table th.border-grey--dark,
.dc-table.dc-table td.border-grey--dark {
  border: 2px solid #8795a1;
}
.dc-table.border-top-grey--dark th,
.dc-table.border-top-grey--dark td,
.dc-table tr.border-top-grey--dark th,
.dc-table tr.border-top-grey--dark td,
.dc-table.dc-table th.border-top-grey--dark,
.dc-table.dc-table td.border-top-grey--dark {
  border-top: 2px solid #8795a1;
}
.dc-table.border-right-grey--dark th,
.dc-table.border-right-grey--dark td,
.dc-table tr.border-right-grey--dark th,
.dc-table tr.border-right-grey--dark td,
.dc-table.dc-table th.border-right-grey--dark,
.dc-table.dc-table td.border-right-grey--dark {
  border-right: 2px solid #8795a1;
}
.dc-table.border-bottom-grey--dark th,
.dc-table.border-bottom-grey--dark td,
.dc-table tr.border-bottom-grey--dark th,
.dc-table tr.border-bottom-grey--dark td,
.dc-table.dc-table th.border-bottom-grey--dark,
.dc-table.dc-table td.border-bottom-grey--dark {
  border-bottom: 2px solid #8795a1;
}
.dc-table.border-left-grey--dark th,
.dc-table.border-left-grey--dark td,
.dc-table tr.border-left-grey--dark th,
.dc-table tr.border-left-grey--dark td,
.dc-table.dc-table th.border-left-grey--dark,
.dc-table.dc-table td.border-left-grey--dark {
  border-left: 2px solid #8795a1;
}
.dc-table.border-horizontal-grey--dark th,
.dc-table.border-horizontal-grey--dark td,
.dc-table tr.border-horizontal-grey--dark th,
.dc-table tr.border-horizontal-grey--dark td,
.dc-table.dc-table th.border-horizontal-grey--dark,
.dc-table.dc-table td.border-horizontal-grey--dark {
  border-right: 2px solid #8795a1;
  border-left: 2px solid #8795a1;
}
.dc-table.border-vertical-grey--dark th,
.dc-table.border-vertical-grey--dark td,
.dc-table tr.border-vertical-grey--dark th,
.dc-table tr.border-vertical-grey--dark td,
.dc-table.dc-table th.border-vertical-grey--dark,
.dc-table.dc-table td.border-vertical-grey--dark {
  border-top: 2px solid #8795a1;
  border-bottom: 2px solid #8795a1;
}
.dc-table.border-blue--light th,
.dc-table.border-blue--light td,
.dc-table tr.border-blue--light th,
.dc-table tr.border-blue--light td,
.dc-table.dc-table th.border-blue--light,
.dc-table.dc-table td.border-blue--light {
  border: 2px solid #a9d6ee;
}
.dc-table.border-top-blue--light th,
.dc-table.border-top-blue--light td,
.dc-table tr.border-top-blue--light th,
.dc-table tr.border-top-blue--light td,
.dc-table.dc-table th.border-top-blue--light,
.dc-table.dc-table td.border-top-blue--light {
  border-top: 2px solid #a9d6ee;
}
.dc-table.border-right-blue--light th,
.dc-table.border-right-blue--light td,
.dc-table tr.border-right-blue--light th,
.dc-table tr.border-right-blue--light td,
.dc-table.dc-table th.border-right-blue--light,
.dc-table.dc-table td.border-right-blue--light {
  border-right: 2px solid #a9d6ee;
}
.dc-table.border-bottom-blue--light th,
.dc-table.border-bottom-blue--light td,
.dc-table tr.border-bottom-blue--light th,
.dc-table tr.border-bottom-blue--light td,
.dc-table.dc-table th.border-bottom-blue--light,
.dc-table.dc-table td.border-bottom-blue--light {
  border-bottom: 2px solid #a9d6ee;
}
.dc-table.border-left-blue--light th,
.dc-table.border-left-blue--light td,
.dc-table tr.border-left-blue--light th,
.dc-table tr.border-left-blue--light td,
.dc-table.dc-table th.border-left-blue--light,
.dc-table.dc-table td.border-left-blue--light {
  border-left: 2px solid #a9d6ee;
}
.dc-table.border-horizontal-blue--light th,
.dc-table.border-horizontal-blue--light td,
.dc-table tr.border-horizontal-blue--light th,
.dc-table tr.border-horizontal-blue--light td,
.dc-table.dc-table th.border-horizontal-blue--light,
.dc-table.dc-table td.border-horizontal-blue--light {
  border-right: 2px solid #a9d6ee;
  border-left: 2px solid #a9d6ee;
}
.dc-table.border-vertical-blue--light th,
.dc-table.border-vertical-blue--light td,
.dc-table tr.border-vertical-blue--light th,
.dc-table tr.border-vertical-blue--light td,
.dc-table.dc-table th.border-vertical-blue--light,
.dc-table.dc-table td.border-vertical-blue--light {
  border-top: 2px solid #a9d6ee;
  border-bottom: 2px solid #a9d6ee;
}
/**
 * .dc-table alignment styles
 *
 * Usage:
 *
 * <table class="dc-table align-right">
 * 		<tr class="align-bottom">
 *			<td class="align-left"></td> // align left, vertical align bottom
 *			<td></td> // vertical align bottom
 * 		</tr>
 * 		<tr class="align-middle">
 * 			<td class="align-center"></td> // align center, vertical align middle
 * 			<td class="align-left"></td> // align left, vertical align middle
 * 		</tr>
 * 	</table>
 *
 **/
.dc-table.align-left th,
.dc-table.align-left td,
.dc-table tr.align-left th,
.dc-table tr.align-left td,
.dc-table.dc-table th.align-left,
.dc-table.dc-table td.align-left {
  text-align: left;
}
.dc-table.align-center th,
.dc-table.align-center td,
.dc-table tr.align-center th,
.dc-table tr.align-center td,
.dc-table.dc-table th.align-center,
.dc-table.dc-table td.align-center {
  text-align: center;
}
.dc-table.align-right th,
.dc-table.align-right td,
.dc-table tr.align-right th,
.dc-table tr.align-right td,
.dc-table.dc-table th.align-right,
.dc-table.dc-table td.align-right {
  text-align: right;
}
.dc-table.align-top th,
.dc-table.align-top td,
.dc-table tr.align-top th,
.dc-table tr.align-top td,
.dc-table.dc-table th.align-top,
.dc-table.dc-table td.align-top {
  vertical-align: top;
}
.dc-table.align-middle th,
.dc-table.align-middle td,
.dc-table tr.align-middle th,
.dc-table tr.align-middle td,
.dc-table.dc-table th.align-middle,
.dc-table.dc-table td.align-middle {
  vertical-align: middle;
}
.dc-table.align-bottom th,
.dc-table.align-bottom td,
.dc-table tr.align-bottom th,
.dc-table tr.align-bottom td,
.dc-table.dc-table th.align-bottom,
.dc-table.dc-table td.align-bottom {
  vertical-align: bottom;
}
.dc-table .has-children tr:not(.expandable) td:first-child {
  padding-left: 2.7em;
}
.dc-table .expandable {
  cursor: pointer;
}
.dc-table .expandable:not(.expanded):hover .expand {
  color: #232323;
}
.dc-table .expanded {
  background-color: #606f7b !important;
  color: #fff;
}
.dc-table .expanded a,
.dc-table .expanded .expand a {
  color: #fff;
}
.dc-table .expanded .expand a:hover {
  color: #ddd;
}
.dc-table .expanded td {
  border-top: 2px solid #606f7b;
  -webkit-box-shadow: 0 -1px 0 #606f7b;
          box-shadow: 0 -1px 0 #606f7b;
  border-bottom-color: #606f7b;
  border-right-color: #606f7b !important;
  border-left-color: #606f7b !important;
}
.dc-table .expanded td .expand {
  color: #fff;
}
.dc-table .expanded .text-positive {
  color: #00ec00;
}
.dc-table .expanded .text-negative {
  color: #ff908d;
}
.dc-table .expanded td:first-child,
.dc-table .child > td:first-child {
  border-left: 3px solid #606f7b !important;
}
.dc-table .expanded td:last-child,
.dc-table .child > td:last-child {
  border-right: 3px solid #606f7b !important;
}
.dc-table .child:last-child > td {
  border-bottom: 3px solid #606f7b !important;
}
.dc-table .child + tr:not(.child) > td {
  border-top: 3px solid #606f7b;
}
.dc-form-group {
  background: #fff;
  padding: 0.5rem 1rem;
  position: relative;
  margin-top: -1px;
  margin-bottom: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.dc-form-group:has(.form-control[disabled]) {
  background: #f8fafc;
}
.dc-form-group:has(.form-control[disabled]) label {
  color: #606f7b;
}
.dc-form-group:after {
  content: "";
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  -webkit-box-shadow: inset 0 0 0 1px #ccc;
          box-shadow: inset 0 0 0 1px #ccc;
  pointer-events: none;
}
.dc-form-group.first::after {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-top: 0;
}
.dc-form-group.last::after {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.dc-form-row.first > .dc-form-group::after {
  margin-top: 0;
}
.dc-form-row.first > .dc-form-group:first-child::after {
  border-top-left-radius: 4px;
}
.dc-form-row.first > .dc-form-group:last-child::after {
  border-top-right-radius: 4px;
}
.dc-form-row.last > .dc-form-group:first-child::after {
  border-bottom-left-radius: 4px;
}
.dc-form-row.last > .dc-form-group:last-child::after {
  border-bottom-right-radius: 4px;
}
.dc-form-group:not(.no-focus):focus-within::after {
  border-color: #4a196f;
  -webkit-box-shadow: inset 0 0 0 2px #4a196f;
          box-shadow: inset 0 0 0 2px #4a196f;
  z-index: 10;
}
.dc-form-group label:not(.form-control, .dc-checkbox) {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 1.2rem;
  padding-top: 0.4em;
  display: block;
}
.dc-form-group label.dc-checkbox {
  margin-top: 0.4em;
}
.dc-form-group input:not(
		input[type="checkbox"],
		input[type="radio"],
		input[type="button"],
		input[type="hidden"],
		input[type="submit"]
	),
.dc-form-group textarea,
.dc-form-group select,
.dc-form-group label.form-control {
  border: none;
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
  height: 28px;
}
.dc-form-group button {
  border: none;
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 4px 12px;
}
.dc-form-group .dc-select2,
.dc-form-group .dc-select2--active {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: transparent;
}
.dc-form-group .dc-select2 > span {
  outline: none;
}
.dc-form-group .dc-select2 .dc-select2__placeholder {
  padding: 0.25em 2.5em 0.25em 0;
}
.dc-form-group .error-messages:has(.message) {
  margin: 0 calc(-1rem + 1px) -0.5rem;
}
.dc-form-group .error-messages:has(.message) .message {
  border: none;
  border-top: 1px solid #ebccd1;
  border-radius: 0;
}
.dc-form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
}
.dc-form-row div[class*="col-"] {
  padding-left: 0;
  padding-right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dc-form-row .formly-field + .formly-field {
  margin-left: -1px;
}
.dc-form-row.grid {
  --col-repetitions: 1;
  counter-reset: count;
  display: grid;
  grid-template-columns: repeat(var(--col-repetitions), 1fr 1px);
}
.dc-form-row.grid-2 {
  --col-repetitions: 2;
}
.dc-form-row.grid-3 {
  --col-repetitions: 3;
}
.dc-form-row.grid-4 {
  --col-repetitions: 4;
}
.dc-form-row.grid-5 {
  --col-repetitions: 5;
}
.dc-form-row.grid-6 {
  --col-repetitions: 6;
}
.dc-form-row.grid .dc-form-group {
  --col-start: 1;
  grid-column-start: var(--col-start);
  grid-column-end: span 2;
  grid-row: 1;
}
.dc-form-row.grid .dc-form-group + .dc-form-group {
  --col-start: 2;
  grid-column-end: span 3;
}
.dc-form-row.grid .dc-form-group + .dc-form-group + .dc-form-group {
  --col-start: 4;
}
.dc-form-row.grid .dc-form-group + .dc-form-group + .dc-form-group + .dc-form-group {
  --col-start: 6;
}
.dc-form-row.grid .dc-form-group + .dc-form-group + .dc-form-group + .dc-form-group + .dc-form-group {
  --col-start: 8;
}
.dc-form-row.grid .dc-form-group + .dc-form-group + .dc-form-group + .dc-form-group + .dc-form-group + .dc-form-group {
  --col-start: 10;
}
.dc-form-row.grid div[class*="col-"] {
  width: 100%;
}
.dc-form-autocomplete div.dropdown-menu {
  -webkit-transform: translate(-1rem, 0);
          transform: translate(-1rem, 0);
  width: calc(100% - 4px);
  border-left: 2px solid #4a196f;
  border-bottom: 2px solid #4a196f;
  border-right: 2px solid #4a196f;
}
.dc-form-autocomplete .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.dc-form-autocomplete ul.dropdown-menu {
  max-height: calc(62px * 5);
  overflow-y: auto;
}
.dc-form-autocomplete div.dropdown-menu::before {
  content: '';
  display: block;
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  border-top: 4px solid white;
}
.dc-form-autocomplete .dropdown-menu li:last-child hr {
  display: none;
}
.dc-form-autocomplete .dropdown-menu .uib-typeahead-match a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5rem 1rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  height: 64px;
}
.dc-form-autocomplete .dropdown-menu .uib-typeahead-match.active a,
.dc-form-autocomplete .dropdown-menu .uib-typeahead-match a:focus {
  background-color: #f1f5f8;
  color: #555;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
  border-top: 1px solid #8795a1;
  border-bottom: 1px solid #8795a1;
}
/**
 * z-index mixin
 *
 * Source: https://gist.github.com/gerbenvandijk/f1c75dab5b1ef10f980d
 *
 * Usage:
 *
 * nav {
 *   .zindex('nav');
 * }
 *
 **/
.l-contents {
  display: contents;
}
.l-block {
  display: block;
}
.l-inline {
  display: inline;
}
.l-inline-block {
  display: inline-block;
}
.l-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.l-inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.l-flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.l-flex-col-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.l-flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.l-flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.l-flex-wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.l-flex-no-wrap {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.l-flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}
.l-flex-justify-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.l-flex-justify-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.l-flex-justify-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.l-flex-justify-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.l-flex-justify-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.l-flex-items-stretch {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.l-flex-items-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.l-flex-items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.l-flex-items-end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.l-flex-items-baseline {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.l-flex-content-start {
  -ms-flex-line-pack: start;
      align-content: flex-start;
}
.l-flex-content-center {
  -ms-flex-line-pack: center;
      align-content: center;
}
.l-flex-content-end {
  -ms-flex-line-pack: end;
      align-content: flex-end;
}
.l-flex-content-between {
  -ms-flex-line-pack: justify;
      align-content: space-between;
}
.l-flex-content-around {
  -ms-flex-line-pack: distribute;
      align-content: space-around;
}
.l-flex-self-auto {
  -ms-flex-item-align: auto;
      align-self: auto;
}
.l-flex-self-start {
  -ms-flex-item-align: start;
      align-self: flex-start;
}
.l-flex-self-center {
  -ms-flex-item-align: center;
      align-self: center;
}
.l-flex-self-end {
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.l-flex-self-stretch {
  -ms-flex-item-align: stretch;
      align-self: stretch;
}
.l-flex-initial {
  -webkit-box-flex: initial;
      -ms-flex: initial;
          flex: initial;
}
.l-flex-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.l-flex-auto {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
.l-flex-none {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
}
.l-flex-grow {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.l-flex-shrink {
  -ms-flex-negative: 1;
      flex-shrink: 1;
}
.l-flex-no-grow {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.l-flex-no-shrink {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.l-grid {
  display: grid;
}
.l-place-items-start {
  place-items: start;
}
.l-place-items-end {
  place-items: end;
}
.l-place-items-center {
  place-items: center;
}
.l-place-items-baseline {
  place-items: baseline;
}
.l-place-items-stretch {
  place-items: stretch;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-auto {
  overflow: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-x-visible {
  overflow-x: visible;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-x-clip {
  overflow-x: clip;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-y-visible {
  overflow-y: visible;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overflow-y-clip {
  overflow-y: clip;
}
.overflow-y-auto {
  overflow-y: auto;
}
/**
 * Percentage based layout widths in 5 percent increments. If finer control is
 * needed it's probably easier to define it separately
 *
 * Usage:
 *
 * <header class="l-w100">
 * 		<div class="logo l-w20"></div>
 * 		<nav class="l-w80"></nav>
 * 	</header>
 *
 **/
.l-w5 {
  width: 5%;
}
.l-max-w5 {
  max-width: 5%;
}
.l-w10 {
  width: 10%;
}
.l-max-w10 {
  max-width: 10%;
}
.l-w15 {
  width: 15%;
}
.l-max-w15 {
  max-width: 15%;
}
.l-w20 {
  width: 20%;
}
.l-max-w20 {
  max-width: 20%;
}
.l-w25 {
  width: 25%;
}
.l-max-w25 {
  max-width: 25%;
}
.l-w30 {
  width: 30%;
}
.l-max-w30 {
  max-width: 30%;
}
.l-w35 {
  width: 35%;
}
.l-max-w35 {
  max-width: 35%;
}
.l-w40 {
  width: 40%;
}
.l-max-w40 {
  max-width: 40%;
}
.l-w45 {
  width: 45%;
}
.l-max-w45 {
  max-width: 45%;
}
.l-w50 {
  width: 50%;
}
.l-max-w50 {
  max-width: 50%;
}
.l-w55 {
  width: 55%;
}
.l-max-w55 {
  max-width: 55%;
}
.l-w60 {
  width: 60%;
}
.l-max-w60 {
  max-width: 60%;
}
.l-w65 {
  width: 65%;
}
.l-max-w65 {
  max-width: 65%;
}
.l-w70 {
  width: 70%;
}
.l-max-w70 {
  max-width: 70%;
}
.l-w75 {
  width: 75%;
}
.l-max-w75 {
  max-width: 75%;
}
.l-w80 {
  width: 80%;
}
.l-max-w80 {
  max-width: 80%;
}
.l-w85 {
  width: 85%;
}
.l-max-w85 {
  max-width: 85%;
}
.l-w90 {
  width: 90%;
}
.l-max-w90 {
  max-width: 90%;
}
.l-w95 {
  width: 95%;
}
.l-max-w95 {
  max-width: 95%;
}
.l-w100 {
  width: 100%;
}
.l-max-w100 {
  max-width: 100%;
}
@media screen and (max-width: 1337px) {
  .s\:l-w5 {
    width: 5%;
  }
  .s\:l-max-w5 {
    max-width: 5%;
  }
  .s\:l-w10 {
    width: 10%;
  }
  .s\:l-max-w10 {
    max-width: 10%;
  }
  .s\:l-w15 {
    width: 15%;
  }
  .s\:l-max-w15 {
    max-width: 15%;
  }
  .s\:l-w20 {
    width: 20%;
  }
  .s\:l-max-w20 {
    max-width: 20%;
  }
  .s\:l-w25 {
    width: 25%;
  }
  .s\:l-max-w25 {
    max-width: 25%;
  }
  .s\:l-w30 {
    width: 30%;
  }
  .s\:l-max-w30 {
    max-width: 30%;
  }
  .s\:l-w35 {
    width: 35%;
  }
  .s\:l-max-w35 {
    max-width: 35%;
  }
  .s\:l-w40 {
    width: 40%;
  }
  .s\:l-max-w40 {
    max-width: 40%;
  }
  .s\:l-w45 {
    width: 45%;
  }
  .s\:l-max-w45 {
    max-width: 45%;
  }
  .s\:l-w50 {
    width: 50%;
  }
  .s\:l-max-w50 {
    max-width: 50%;
  }
  .s\:l-w55 {
    width: 55%;
  }
  .s\:l-max-w55 {
    max-width: 55%;
  }
  .s\:l-w60 {
    width: 60%;
  }
  .s\:l-max-w60 {
    max-width: 60%;
  }
  .s\:l-w65 {
    width: 65%;
  }
  .s\:l-max-w65 {
    max-width: 65%;
  }
  .s\:l-w70 {
    width: 70%;
  }
  .s\:l-max-w70 {
    max-width: 70%;
  }
  .s\:l-w75 {
    width: 75%;
  }
  .s\:l-max-w75 {
    max-width: 75%;
  }
  .s\:l-w80 {
    width: 80%;
  }
  .s\:l-max-w80 {
    max-width: 80%;
  }
  .s\:l-w85 {
    width: 85%;
  }
  .s\:l-max-w85 {
    max-width: 85%;
  }
  .s\:l-w90 {
    width: 90%;
  }
  .s\:l-max-w90 {
    max-width: 90%;
  }
  .s\:l-w95 {
    width: 95%;
  }
  .s\:l-max-w95 {
    max-width: 95%;
  }
  .s\:l-w100 {
    width: 100%;
  }
  .s\:l-max-w100 {
    max-width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .xs\:l-w5 {
    width: 5%;
  }
  .xs\:l-max-w5 {
    max-width: 5%;
  }
  .xs\:l-w10 {
    width: 10%;
  }
  .xs\:l-max-w10 {
    max-width: 10%;
  }
  .xs\:l-w15 {
    width: 15%;
  }
  .xs\:l-max-w15 {
    max-width: 15%;
  }
  .xs\:l-w20 {
    width: 20%;
  }
  .xs\:l-max-w20 {
    max-width: 20%;
  }
  .xs\:l-w25 {
    width: 25%;
  }
  .xs\:l-max-w25 {
    max-width: 25%;
  }
  .xs\:l-w30 {
    width: 30%;
  }
  .xs\:l-max-w30 {
    max-width: 30%;
  }
  .xs\:l-w35 {
    width: 35%;
  }
  .xs\:l-max-w35 {
    max-width: 35%;
  }
  .xs\:l-w40 {
    width: 40%;
  }
  .xs\:l-max-w40 {
    max-width: 40%;
  }
  .xs\:l-w45 {
    width: 45%;
  }
  .xs\:l-max-w45 {
    max-width: 45%;
  }
  .xs\:l-w50 {
    width: 50%;
  }
  .xs\:l-max-w50 {
    max-width: 50%;
  }
  .xs\:l-w55 {
    width: 55%;
  }
  .xs\:l-max-w55 {
    max-width: 55%;
  }
  .xs\:l-w60 {
    width: 60%;
  }
  .xs\:l-max-w60 {
    max-width: 60%;
  }
  .xs\:l-w65 {
    width: 65%;
  }
  .xs\:l-max-w65 {
    max-width: 65%;
  }
  .xs\:l-w70 {
    width: 70%;
  }
  .xs\:l-max-w70 {
    max-width: 70%;
  }
  .xs\:l-w75 {
    width: 75%;
  }
  .xs\:l-max-w75 {
    max-width: 75%;
  }
  .xs\:l-w80 {
    width: 80%;
  }
  .xs\:l-max-w80 {
    max-width: 80%;
  }
  .xs\:l-w85 {
    width: 85%;
  }
  .xs\:l-max-w85 {
    max-width: 85%;
  }
  .xs\:l-w90 {
    width: 90%;
  }
  .xs\:l-max-w90 {
    max-width: 90%;
  }
  .xs\:l-w95 {
    width: 95%;
  }
  .xs\:l-max-w95 {
    max-width: 95%;
  }
  .xs\:l-w100 {
    width: 100%;
  }
  .xs\:l-max-w100 {
    max-width: 100%;
  }
}
.l-w-optimal-line {
  max-width: 65ch;
}
/**
 * EM based layout margins
 *
 * Usage:
 *
 * <header class="l-m1">
 * 		<div class="logo l-ml2"></div>
 * 		<nav class="l-m0"></nav>
 * 	</header>
 *
 **/
.l-ma {
  margin: auto;
}
.l-mta {
  margin-top: auto;
}
.l-mra {
  margin-right: auto;
}
.l-mba {
  margin-bottom: auto;
}
.l-mla {
  margin-left: auto;
}
.l-mtba {
  margin-top: auto;
  margin-bottom: auto;
}
.l-mlra {
  margin-left: auto;
  margin-right: auto;
}
.l-m05 {
  margin: 0.5em;
}
.l-mt05 {
  margin-top: 0.5em;
}
.l-mr05 {
  margin-right: 0.5em;
}
.l-mb05 {
  margin-bottom: 0.5em;
}
.l-ml05 {
  margin-left: 0.5em;
}
.l-mtb05 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.l-mlr05 {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.l-mtpx {
  margin-top: 1px;
}
.l-mt-px {
  margin-top: -1px;
}
.l-mbpx {
  margin-bottom: 1px;
}
.l-mb-px {
  margin-bottom: -1px;
}
.l-m0 {
  margin: 0em;
}
.l-mt0 {
  margin-top: 0em;
}
.l-mr0 {
  margin-right: 0em;
}
.l-mb0 {
  margin-bottom: 0em;
}
.l-ml0 {
  margin-left: 0em;
}
.l-mtb0 {
  margin-top: 0em;
  margin-bottom: 0em;
}
.l-mlr0 {
  margin-left: 0em;
  margin-right: 0em;
}
.l-m1 {
  margin: 1em;
}
.l-mt1 {
  margin-top: 1em;
}
.l-mr1 {
  margin-right: 1em;
}
.l-mb1 {
  margin-bottom: 1em;
}
.l-ml1 {
  margin-left: 1em;
}
.l-mtb1 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.l-mlr1 {
  margin-left: 1em;
  margin-right: 1em;
}
.l-m2 {
  margin: 2em;
}
.l-mt2 {
  margin-top: 2em;
}
.l-mr2 {
  margin-right: 2em;
}
.l-mb2 {
  margin-bottom: 2em;
}
.l-ml2 {
  margin-left: 2em;
}
.l-mtb2 {
  margin-top: 2em;
  margin-bottom: 2em;
}
.l-mlr2 {
  margin-left: 2em;
  margin-right: 2em;
}
.l-m3 {
  margin: 3em;
}
.l-mt3 {
  margin-top: 3em;
}
.l-mr3 {
  margin-right: 3em;
}
.l-mb3 {
  margin-bottom: 3em;
}
.l-ml3 {
  margin-left: 3em;
}
.l-mtb3 {
  margin-top: 3em;
  margin-bottom: 3em;
}
.l-mlr3 {
  margin-left: 3em;
  margin-right: 3em;
}
.l-m4 {
  margin: 4em;
}
.l-mt4 {
  margin-top: 4em;
}
.l-mr4 {
  margin-right: 4em;
}
.l-mb4 {
  margin-bottom: 4em;
}
.l-ml4 {
  margin-left: 4em;
}
.l-mtb4 {
  margin-top: 4em;
  margin-bottom: 4em;
}
.l-mlr4 {
  margin-left: 4em;
  margin-right: 4em;
}
.l-m5 {
  margin: 5em;
}
.l-mt5 {
  margin-top: 5em;
}
.l-mr5 {
  margin-right: 5em;
}
.l-mb5 {
  margin-bottom: 5em;
}
.l-ml5 {
  margin-left: 5em;
}
.l-mtb5 {
  margin-top: 5em;
  margin-bottom: 5em;
}
.l-mlr5 {
  margin-left: 5em;
  margin-right: 5em;
}
.space-x-2 > :not(template) ~ :not(template) {
  margin-left: 0.5rem !important;
}
.space-y-4 > :not(template) ~ :not(template) {
  margin-bottom: 1rem !important;
}
.space-x-4 > :not(template) ~ :not(template) {
  margin-left: 1rem !important;
}
.space-y-6 > :not(template) ~ :not(template) {
  margin-bottom: 1.5rem !important;
}
.space-x-6 > :not(template) ~ :not(template) {
  margin-left: 1.5rem !important;
}
.space-x-8 > :not(template) ~ :not(template) {
  margin-left: 2rem !important;
}
.space-x-12 > :not(template) ~ :not(template) {
  margin-left: 3rem !important;
}
.gap-1 {
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important;
}
.gap-2 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}
.gap-3 {
  grid-gap: 0.75rem !important;
  gap: 0.75rem !important;
}
.gap-4 {
  grid-gap: 1rem !important;
  gap: 1rem !important;
}
.gap-6 {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
}
.gap-8 {
  grid-gap: 2rem !important;
  gap: 2rem !important;
}
.gap-12 {
  grid-gap: 3rem !important;
  gap: 3rem !important;
}
/**
 * EM based layout paddings
 *
 * Usage:
 *
 * <header class="l-p1">
 * 		<div class="logo l-p0"></div>
 * 		<nav class="l-p2"></nav>
 * 	</header>
 *
 **/
.l-p025 {
  padding: 0.25em;
}
.l-pt025 {
  padding-top: 0.25em;
}
.l-pr025 {
  padding-right: 0.25em;
}
.l-pb025 {
  padding-bottom: 0.25em;
}
.l-pl025 {
  padding-left: 0.25em;
}
.l-ptb025 {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.l-plr025 {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.l-p05 {
  padding: 0.5em;
}
.l-pt05 {
  padding-top: 0.5em;
}
.l-pr05 {
  padding-right: 0.5em;
}
.l-pb05 {
  padding-bottom: 0.5em;
}
.l-pl05 {
  padding-left: 0.5em;
}
.l-ptb05 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.l-plr05 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.l-p0 {
  padding: 0em;
}
.l-pt0 {
  padding-top: 0em;
}
.l-pr0 {
  padding-right: 0em;
}
.l-pb0 {
  padding-bottom: 0em;
}
.l-pl0 {
  padding-left: 0em;
}
.l-ptb0 {
  padding-top: 0em;
  padding-bottom: 0em;
}
.l-plr0 {
  padding-left: 0em;
  padding-right: 0em;
}
.l-p1 {
  padding: 1em;
}
.l-pt1 {
  padding-top: 1em;
}
.l-pr1 {
  padding-right: 1em;
}
.l-pb1 {
  padding-bottom: 1em;
}
.l-pl1 {
  padding-left: 1em;
}
.l-ptb1 {
  padding-top: 1em;
  padding-bottom: 1em;
}
.l-plr1 {
  padding-left: 1em;
  padding-right: 1em;
}
.l-p2 {
  padding: 2em;
}
.l-pt2 {
  padding-top: 2em;
}
.l-pr2 {
  padding-right: 2em;
}
.l-pb2 {
  padding-bottom: 2em;
}
.l-pl2 {
  padding-left: 2em;
}
.l-ptb2 {
  padding-top: 2em;
  padding-bottom: 2em;
}
.l-plr2 {
  padding-left: 2em;
  padding-right: 2em;
}
.l-p3 {
  padding: 3em;
}
.l-pt3 {
  padding-top: 3em;
}
.l-pr3 {
  padding-right: 3em;
}
.l-pb3 {
  padding-bottom: 3em;
}
.l-pl3 {
  padding-left: 3em;
}
.l-ptb3 {
  padding-top: 3em;
  padding-bottom: 3em;
}
.l-plr3 {
  padding-left: 3em;
  padding-right: 3em;
}
.l-p4 {
  padding: 4em;
}
.l-pt4 {
  padding-top: 4em;
}
.l-pr4 {
  padding-right: 4em;
}
.l-pb4 {
  padding-bottom: 4em;
}
.l-pl4 {
  padding-left: 4em;
}
.l-ptb4 {
  padding-top: 4em;
  padding-bottom: 4em;
}
.l-plr4 {
  padding-left: 4em;
  padding-right: 4em;
}
.l-p5 {
  padding: 5em;
}
.l-pt5 {
  padding-top: 5em;
}
.l-pr5 {
  padding-right: 5em;
}
.l-pb5 {
  padding-bottom: 5em;
}
.l-pl5 {
  padding-left: 5em;
}
.l-ptb5 {
  padding-top: 5em;
  padding-bottom: 5em;
}
.l-plr5 {
  padding-left: 5em;
  padding-right: 5em;
}
* {
  border: 0 solid currentColor;
}
.border {
  border-width: 1px;
}
.border-t {
  border-top-width: 1px;
}
.border-r {
  border-right-width: 1px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-l {
  border-left-width: 1px;
}
.border-thick {
  border-width: 3px;
}
.border-t-thick {
  border-top-width: 3px;
}
.border-r-thick {
  border-right-width: 3px;
}
.border-b-thick {
  border-bottom-width: 3px;
}
.border-l-thick {
  border-left-width: 3px;
}
.border-none {
  border: 0;
}
.border-t-none {
  border-top: 0;
}
.border-r-none {
  border-right: 0;
}
.border-b-none {
  border-bottom: 0;
}
.border-l-none {
  border-left: 0;
}
.border-dotted {
  border-style: dotted;
}
.border-t-dotted {
  border-top-style: dotted;
}
.border-r-dotted {
  border-right-style: dotted;
}
.border-b-dotted {
  border-bottom-style: dotted;
}
.border-l-dotted {
  border-left-style: dotted;
}
.rounded-sm {
  border-radius: 0.125rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-md {
  border-radius: 0.375rem !important;
}
.rounded-lg {
  border-radius: 0.5rem !important;
}
.rounded-full {
  border-radius: 9999px !important;
}
.border-transparent {
  border-color: transparent;
}
.border-white {
  border-color: #fff;
}
.border-grey {
  border-color: #b8c2cc;
}
.border-grey--lightest {
  border-color: #f8fafc;
}
.border-grey--lighter {
  border-color: #f1f5f8;
}
.border-grey--light {
  border-color: #dae1e7;
}
.border-grey--dark {
  border-color: #8795a1;
}
.border-grey--darker {
  border-color: #606f7b;
}
.border-grey--darkest {
  border-color: #3d4852;
}
.border-black {
  border-color: #22292f;
}
.border-blue {
  border-color: #3fa3d8;
}
.border-blue--light {
  border-color: #a9d6ee;
}
.border-orange {
  border-color: #d27300;
}
.border-brand {
  border-color: #4a196f;
}
.border-danger {
  border-color: #d9534f;
}
.border-success {
  border-color: #5cb85c;
}
.hover\:border-transparent {
  border-color: transparent;
}
.hover\:border-white {
  border-color: #fff;
}
.hover\:border-grey:hover {
  border-color: #b8c2cc;
}
.hover\:border-grey--lightest:hover {
  border-color: #f8fafc;
}
.hover\:border-grey--lighter:hover {
  border-color: #f1f5f8;
}
.hover\:border-grey--light:hover {
  border-color: #dae1e7;
}
.hover\:border-grey--dark:hover {
  border-color: #8795a1;
}
.hover\:border-grey--darker:hover {
  border-color: #606f7b;
}
.hover\:border-grey--darkest:hover {
  border-color: #3d4852;
}
.hover\:border-black:hover {
  border-color: #22292f;
}
.hover\:border-blue:hover {
  border-color: #3fa3d8;
}
.hover\:border-blue--light:hover {
  border-color: #a9d6ee;
}
.hover\:border-orange:hover {
  border-color: #d27300;
}
.hover\:border-brand:hover {
  border-color: #4a196f;
}
.cursor-auto {
  cursor: auto;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-wait {
  cursor: wait;
}
.cursor-text {
  cursor: text;
}
.cursor-move {
  cursor: move;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-help {
  cursor: help;
}
.l-align-top {
  vertical-align: top;
}
.l-align-middle {
  vertical-align: middle;
}
.l-align-bottom {
  vertical-align: bottom;
}
.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-base {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-2xl {
  font-size: 1.5rem;
}
.text-3xl {
  font-size: 1.875rem;
}
.text-4xl {
  font-size: 2.25rem;
}
.text-5xl {
  font-size: 3rem;
}
.font-hairline {
  font-weight: 100;
}
.font-extra-light {
  font-weight: 200;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extra-bold {
  font-weight: 800;
}
.font-black {
  font-weight: 900;
}
.leading-none {
  line-height: 1;
}
.leading-tight {
  line-height: 1.25;
}
.leading-normal {
  line-height: 1.5;
}
.leading-loose {
  line-height: 2;
}
.italic {
  font-style: italic;
}
.roman {
  font-style: normal;
}
.uppercase {
  text-transform: uppercase;
}
.uppercase-first::first-letter {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.normal-case {
  text-transform: none;
}
.underline {
  text-decoration: underline;
}
.line-through {
  text-decoration: line-through;
}
.no-underline {
  text-decoration: none;
}
.hover\:no-underline:hover {
  text-decoration: none;
}
.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.font-brand {
  font-family: 'Roboto Condensed', sans-serif;
}
.text-wrap {
  white-space: normal;
}
.font-tabular-nums {
  font-variant-numeric: tabular-nums slashed-zero;
}
.c-white,
.hover\:c-white:hover {
  color: #fff;
}
.c-grey,
.hover\:c-grey:hover {
  color: #b8c2cc;
}
.c-grey--lightest,
.hover\:c-grey--lightest:hover {
  color: #f8fafc;
}
.c-grey--lighter,
.hover\:c-grey--lighter:hover {
  color: #f1f5f8;
}
.c-grey--light,
.hover\:c-grey--light:hover {
  color: #dae1e7;
}
.c-grey--dark,
.hover\:c-grey--dark:hover {
  color: #8795a1;
}
.c-grey--darker,
.hover\:c-grey--darker:hover {
  color: #606f7b;
}
.c-grey--darkest,
.hover\:c-grey--darkest:hover {
  color: #3d4852;
}
.c-black,
.hover\:c-black:hover {
  color: #22292f;
}
.c-brand,
.hover\:c-brand:hover {
  color: #4a196f;
}
.c-blue,
.hover\:c-blue:hover {
  color: #3fa3d8;
}
.c-blue--light,
.hover\:c-blue--light:hover {
  color: #a9d6ee;
}
.c-orange,
.hover\:c-orange:hover {
  color: #d27300;
}
.bg-transparent {
  background-color: transparent;
}
.bg-brand,
.hover\:bg-brand:hover {
  background-color: #4a196f;
}
.bg-blue,
.hover\:bg-blue:hover {
  background-color: #3fa3d8;
}
.bg-blue--light,
.hover\:bg-blue--light:hover {
  background-color: #a9d6ee;
}
.bg-orange,
.hover\:bg-orange:hover {
  background-color: #d27300;
}
.bg-green {
  background-color: #2e8d17;
}
.bg-white,
.hover\:bg-white:hover {
  background-color: #ffffff;
}
.bg-grey,
.hover\:bg-grey:hover {
  background-color: #b8c2cc;
}
.bg-grey--lightest,
.hover\:bg-grey--lightest:hover {
  background-color: #f8fafc;
}
.bg-grey--lighter,
.hover\:bg-grey--lighter:hover {
  background-color: #f1f5f8;
}
.bg-grey--light,
.hover\:bg-grey--light:hover {
  background-color: #dae1e7;
}
.bg-grey--dark,
.hover\:bg-grey--dark:hover {
  background-color: #8795a1;
}
.bg-grey--darker,
.hover\:bg-grey--darker:hover {
  background-color: #606f7b;
}
.bg-grey--darkest,
.hover\:bg-grey--darkest:hover {
  background-color: #3d4852;
}
.bg-black,
.hover\:bg-black:hover {
  background-color: #000033;
}
.bg-uncertain {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='%23eeeeee' viewBox='0 0 10 10'%3E%3Cpath d='M 10 0 L 0 10 M 6 -1 L -1 6 M 1 -1 L -1 1 M 11 4 L 4 11 M 11 9 L 9 11'/%3E%3C/svg%3E");
}
.shadow {
  -webkit-box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
          box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
}
.text-positive {
  color: #449d44;
}
.text-negative {
  color: #d9534f;
}
.formly.row {
  display: block;
}
.formly .error-messages .message {
  background: #f2dede;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  color: #a94442;
  margin-top: 0.25rem;
  padding: 0.5rem 1rem;
}
.formly div.inline .radio {
  display: inline-block;
  margin-right: 2ch;
}
dc-daterange-picker {
  display: block;
}
.dc-daterangepicker {
  position: relative;
}
.dc-daterangepicker:before,
.dc-daterangepicker:after {
  content: " ";
  display: table;
}
.dc-daterangepicker:after {
  clear: both;
}
.dc-daterangepicker .uib-datepicker > li,
.dc-daterangepicker .uib-datepicker .uib-datepicker,
.dc-daterangepicker .uib-datepicker .uib-daypicker,
.dc-daterangepicker .uib-datepicker table {
  outline: none;
  background: #ffffff;
}
.dc-daterangepicker .uib-datepicker > li td,
.dc-daterangepicker .uib-datepicker .uib-datepicker td,
.dc-daterangepicker .uib-datepicker .uib-daypicker td,
.dc-daterangepicker .uib-datepicker table td {
  padding-block: 1px;
}
@-webkit-keyframes datepickerShowTopLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate(-2%, 100%);
            transform: translate(-2%, 100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-2%, 102%);
            transform: translate(-2%, 102%);
  }
}
@keyframes datepickerShowTopLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate(-2%, 100%);
            transform: translate(-2%, 100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-2%, 102%);
            transform: translate(-2%, 102%);
  }
}
@-webkit-keyframes datepickerShowTopCenter {
  0% {
    opacity: 0;
    -webkit-transform: translate(-2%, -100%);
            transform: translate(-2%, -100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-2%, -102%);
            transform: translate(-2%, -102%);
  }
}
@keyframes datepickerShowTopCenter {
  0% {
    opacity: 0;
    -webkit-transform: translate(-2%, -100%);
            transform: translate(-2%, -100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-2%, -102%);
            transform: translate(-2%, -102%);
  }
}
@-webkit-keyframes datepickerShowTopRight {
  0% {
    opacity: 0;
    -webkit-transform: translate(2%, -100%);
            transform: translate(2%, -100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(2%, -102%);
            transform: translate(2%, -102%);
  }
}
@keyframes datepickerShowTopRight {
  0% {
    opacity: 0;
    -webkit-transform: translate(2%, -100%);
            transform: translate(2%, -100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(2%, -102%);
            transform: translate(2%, -102%);
  }
}
@-webkit-keyframes datepickerShowLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-102%, 0);
            transform: translate(-102%, 0);
  }
}
@keyframes datepickerShowLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-102%, 0);
            transform: translate(-102%, 0);
  }
}
@-webkit-keyframes datepickerShowRight {
  0% {
    opacity: 0;
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(102%, 0);
            transform: translate(102%, 0);
  }
}
@keyframes datepickerShowRight {
  0% {
    opacity: 0;
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(102%, 0);
            transform: translate(102%, 0);
  }
}
@-webkit-keyframes datepickerShowBottomLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate(-2%, 100%);
            transform: translate(-2%, 100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-2%, 102%);
            transform: translate(-2%, 102%);
  }
}
@keyframes datepickerShowBottomLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate(-2%, 100%);
            transform: translate(-2%, 100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-2%, 102%);
            transform: translate(-2%, 102%);
  }
}
@-webkit-keyframes datepickerShowBottomCenter {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, 102%);
            transform: translate(-50%, 102%);
  }
}
@keyframes datepickerShowBottomCenter {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, 102%);
            transform: translate(-50%, 102%);
  }
}
@-webkit-keyframes datepickerShowBottomRight {
  0% {
    opacity: 0;
    -webkit-transform: translate(2%, 100%);
            transform: translate(2%, 100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(2%, 102%);
            transform: translate(2%, 102%);
  }
}
@keyframes datepickerShowBottomRight {
  0% {
    opacity: 0;
    -webkit-transform: translate(2%, 100%);
            transform: translate(2%, 100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(2%, 102%);
            transform: translate(2%, 102%);
  }
}
.dc-daterangepicker__container {
  position: absolute;
  min-height: 200px;
  z-index: 100;
  outline: 2px solid #3d4852;
  border-radius: 4px;
}
.dc-daterangepicker__container--top-left {
  top: 0;
  left: 0;
  -webkit-transform: translate(-2%, -102%);
          transform: translate(-2%, -102%);
  -webkit-animation: 150ms datepickerShowTopLeft;
          animation: 150ms datepickerShowTopLeft;
}
.dc-daterangepicker__container--top-center {
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -102%);
          transform: translate(-50%, -102%);
  -webkit-animation: 150ms datepickerShowTopCenter;
          animation: 150ms datepickerShowTopCenter;
}
.dc-daterangepicker__container--top-right {
  top: 0;
  right: 0;
  -webkit-transform: translate(2%, -102%);
          transform: translate(2%, -102%);
  -webkit-animation: 150ms datepickerShowTopRight;
          animation: 150ms datepickerShowTopRight;
}
.dc-daterangepicker__container--left {
  top: 0;
  left: 0;
  -webkit-transform: translate(-102%, 0);
          transform: translate(-102%, 0);
  -webkit-animation: 150ms datepickerShowLeft;
          animation: 150ms datepickerShowLeft;
}
.dc-daterangepicker__container--right {
  top: 0;
  right: 0;
  -webkit-transform: translate(102%, 0);
          transform: translate(102%, 0);
  -webkit-animation: 150ms datepickerShowRight;
          animation: 150ms datepickerShowRight;
}
.dc-daterangepicker__container--bottom-left {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-2%, 102%);
          transform: translate(-2%, 102%);
  -webkit-animation: 150ms datepickerShowBottomLeft;
          animation: 150ms datepickerShowBottomLeft;
}
.dc-daterangepicker__container--bottom-center {
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 102%);
          transform: translate(-50%, 102%);
  -webkit-animation: 150ms datepickerShowBottomCenter;
          animation: 150ms datepickerShowBottomCenter;
}
.dc-daterangepicker__container--bottom-right {
  bottom: 0;
  right: 0;
  -webkit-transform: translate(2%, 102%);
          transform: translate(2%, 102%);
  -webkit-animation: 150ms datepickerShowBottomRight;
          animation: 150ms datepickerShowBottomRight;
}
.dc-daterangepicker__background {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.dc-daterangepicker__predefined-periods {
  background-color: #f8fafc;
  border-right: 1px solid #eee;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  position: relative;
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dc-daterangepicker__predefined-periods h3 {
  background-color: #f1f5f8;
  margin: 0;
  text-align: right;
  color: #3d4852;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.dc-daterangepicker__predefined-periods-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
}
.dc-daterangepicker__predefined-period {
  display: block;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 0;
}
.dc-daterangepicker__predefined-period button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1.5rem;
  height: 32px !important;
  font-size: 1.4rem;
  padding: 0.4rem 0.8rem;
  text-align: left;
  width: 100%;
  background: transparent;
}
.dc-daterangepicker__predefined-period button:hover {
  background-color: #f1f5f8;
  text-decoration: none;
}
.dc-daterangepicker__predefined-period button:first-letter {
  text-transform: uppercase;
}
.dc-daterangepicker__predefined-period--selected a {
  -webkit-box-shadow: 0 0 0 2px #fff;
          box-shadow: 0 0 0 2px #fff;
}
.dc-daterangepicker__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
}
.dc-daterangepicker__content h3 {
  margin: 0;
  min-height: 18px;
}
.dc-daterangepicker__content .uib-day button {
  padding: 8px 10px;
}
.dc-daterangepicker__content .uib-day button:hover {
  background: inherit;
  outline: 2px solid #22292f;
  outline-offset: 2px;
  -webkit-box-shadow: 0 0 0 2px #fff !important;
          box-shadow: 0 0 0 2px #fff !important;
  border-radius: 4px !important;
  position: relative;
}
.dc-daterangepicker__daterange {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.dc-daterangepicker__daterange-date {
  background: #f6f6f6;
  border: 1px solid #ddd;
  border-radius: 3px;
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 0.5em;
  text-align: center;
}
.dc-daterangepicker__daterange-date--selected {
  border-color: #7427ad;
  -webkit-box-shadow: 0 0 0 1px #7427ad;
          box-shadow: 0 0 0 1px #7427ad;
}
.dc-daterangepicker__daterange-date--selected:last-child {
  color: #999;
}
.dc-daterangepicker__daterange-separator {
  -ms-flex-item-align: center;
      align-self: center;
  margin: 0 0.5em;
}
.dc-daterangepicker__datepicker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dc-daterangepicker__datepicker .text-muted {
  color: #ccc;
}
.dc-daterangepicker__datepicker .btn-info.active {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.dc-daterangepicker__datepicker > div:nth-child(2) {
  margin-left: 1em;
}
.dc-daterangepicker__savecancel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: auto;
}
.dc-daterangepicker__savecancel button {
  margin-left: 1em;
}
.uib-day.selected button.btn {
  color: #fff;
  background-color: #4a196f;
  border-color: #4a196f;
}
.uib-day.selected.start .btn {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.uib-day.selected.end .btn {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.uib-day.inRange .btn {
  color: #3d4852;
  background-color: #f1f5f8;
  border-color: #f1f5f8;
}
.uib-day.inRange .text-muted,
.uib-day.selected .text-muted {
  color: #b8c2cc;
}
.dc-daterangepicker .text-info {
  color: inherit;
}
.dc-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: default;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: normal;
}
.dc-checkbox svg {
  height: 16px;
  width: 16px;
  border-radius: 2px;
  margin-right: 2px;
}
.dc-checkbox svg.fa-check-square {
  color: #4a196f;
}
.dc-checkbox--inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 0;
}
.dc-checkbox input:checked + .dc-checkbox__icon {
  color: #0057ad;
}
.dc-checkbox--disabled,
.dc-checkbox--disabled input:checked + .dc-checkbox__icon,
.dc-checkbox--disabled input:checked ~ svg {
  color: #aaa;
}
.dc-checkbox--focused svg {
  -webkit-box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
          box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
}
.dc-checkbox__content {
  display: contents;
}
.dc-checkbox__icon {
  color: inherit;
  font-size: 1.3em;
  width: 15px;
  position: relative;
  top: 2px;
}
.dc-checkbox__icon + span {
  margin-left: 2px;
}
.alert {
  background-color: #dae1e7;
  min-height: 45px;
  border: 2px solid transparent;
  border-radius: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  gap: 0.5rem;
}
.alert:not([class*="l-p"]) {
  padding: 12px 18px 12px;
}
.alert:not([class*="l-mb"]) {
  margin-bottom: 1rem;
}
.alert * {
  grid-column: 2;
  margin: 0;
}
.alert h1,
.alert h2,
.alert h3,
.alert h4,
.alert h5 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.3em;
  font-weight: normal;
  letter-spacing: 0.04rem;
}
.alert-xs {
  border-radius: 8px;
  min-height: auto;
}
.alert-xs::before {
  font-size: 1.75rem !important;
}
.alert-sm {
  border-radius: 8px;
  min-height: auto;
}
.alert-sm::before {
  font-size: 2rem !important;
}
.alert-lg {
  border-radius: 12px;
  min-height: 67px;
}
.alert-lg::before {
  font-size: 3.5rem !important;
}
.alert.alert-success,
.alert.alert-info,
.alert.alert-warning,
.alert.alert-danger {
  padding-left: 50px;
}
.alert.alert-success::before,
.alert.alert-info::before,
.alert.alert-warning::before,
.alert.alert-danger::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 1.5rem;
  font-size: 2.75rem;
}
.alert.alert-success:not([class*="l-p"]).alert-xs,
.alert.alert-info:not([class*="l-p"]).alert-xs,
.alert.alert-warning:not([class*="l-p"]).alert-xs,
.alert.alert-danger:not([class*="l-p"]).alert-xs {
  padding: 4px 8px 4px 32px;
}
.alert.alert-success:not([class*="l-p"]).alert-xs::before,
.alert.alert-info:not([class*="l-p"]).alert-xs::before,
.alert.alert-warning:not([class*="l-p"]).alert-xs::before,
.alert.alert-danger:not([class*="l-p"]).alert-xs::before {
  left: 1rem;
}
.alert.alert-success:not([class*="l-p"]).alert-sm,
.alert.alert-info:not([class*="l-p"]).alert-sm,
.alert.alert-warning:not([class*="l-p"]).alert-sm,
.alert.alert-danger:not([class*="l-p"]).alert-sm {
  padding: 8px 12px 8px 44px;
}
.alert.alert-success:not([class*="l-p"]).alert-lg,
.alert.alert-info:not([class*="l-p"]).alert-lg,
.alert.alert-warning:not([class*="l-p"]).alert-lg,
.alert.alert-danger:not([class*="l-p"]).alert-lg {
  padding: 16px 22px 17px 60px;
}
.alert-info {
  background-color: #89badb;
  color: #0c1c26;
}
.alert-info::before {
  content: "\f05a";
  display: none;
}
.alert-info h1,
.alert-info h2,
.alert-info h3,
.alert-info h4,
.alert-info h5 {
  color: #0c1c26;
}
.alert-info a {
  color: #0c1c26;
  border-bottom: 1px solid currentColor;
}
.alert-info a:hover {
  text-decoration: none;
  border-bottom: 2px solid currentColor;
}
.alert-success {
  background-color: #8cd191;
  color: #0c1f0e;
}
.alert-success::before {
  content: "\f058";
  display: none;
}
.alert-success h1,
.alert-success h2,
.alert-success h3,
.alert-success h4,
.alert-success h5 {
  color: #0c1f0e;
}
.alert-success a {
  color: #0c1f0e;
  border-bottom: 1px solid currentColor;
}
.alert-success a:hover {
  text-decoration: none;
  border-bottom: 2px solid currentColor;
}
.alert-warning {
  background-color: #ffe066;
  color: #332900;
}
.alert-warning::before {
  content: "\f06a";
  display: none;
}
.alert-warning h1,
.alert-warning h2,
.alert-warning h3,
.alert-warning h4,
.alert-warning h5 {
  color: #332900;
}
.alert-warning a {
  color: #332900;
  border-bottom: 1px solid currentColor;
}
.alert-warning a:hover {
  text-decoration: none;
  border-bottom: 2px solid currentColor;
}
.alert-danger {
  background-color: #e57373;
  color: #200606;
}
.alert-danger::before {
  content: "\f057";
  display: none;
}
.alert-danger h1,
.alert-danger h2,
.alert-danger h3,
.alert-danger h4,
.alert-danger h5 {
  color: #200606;
}
.alert-danger a {
  color: #200606;
  border-bottom: 1px solid currentColor;
}
.alert-danger a:hover {
  text-decoration: none;
  border-bottom: 2px solid currentColor;
}
.alert-icon-spin::before {
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.alert-icon-circle-notch::before {
  content: "\f1ce";
}
@media print {
  #navigation {
    display: none;
  }
}
.header-facility__initials {
  background-color: #fff;
  border-radius: 50%;
  height: 36px;
  text-align: center;
  width: 36px !important;
  margin: 8px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-preferred-size: 36px;
      flex-basis: 36px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  text-transform: uppercase;
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.4);
          box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.4);
}
.header-facility__initials-content {
  color: #fff;
  font-family: sans-serif;
  font-size: calc(36px / 2.4);
  /* 50% of parent */
  line-height: 1;
}
#navigation {
  background-color: #4a196f;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  -webkit-box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.05);
          box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.05);
  z-index: 100;
}
#navigation .header-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#navigation .header-body ng-transclude {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#navigation .header-body ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#navigation .badge {
  position: absolute;
  top: 2px;
  right: calc(100% - 10px);
  background-color: #03A9F4;
  font-size: 12px;
  font-family: arial;
  pointer-events: none;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}
#navigation dc-global-facility-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#header-logo {
  padding: 0;
  display: block;
  width: 32px;
  margin: 0.5em 1.5em 0.5em 0.5em;
  overflow: hidden;
  -webkit-transition: width 150ms ease-in-out;
  transition: width 150ms ease-in-out;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
@media screen and (min-width: 1750px) {
  #header-logo {
    width: 260px;
  }
}
#header-logo img {
  width: 200px;
  margin: 5px;
  -webkit-transition: margin-left 150ms ease-in-out, width 150ms ease-in-out;
  transition: margin-left 150ms ease-in-out, width 150ms ease-in-out;
}
.navigation__separator {
  border: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
  height: auto;
  margin: 1em;
}
.dc-global-nav__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dc-global-nav__container > div {
  display: none;
}
.dc-global-nav__container.open > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dc-global-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.dc-global-nav--pin-right .dc-global-nav__dropdown {
  left: auto;
  right: 0.25em;
  -webkit-transform: translateX(0.25em);
          transform: translateX(0.25em);
}
.dc-global-nav__item {
  height: auto;
  margin-bottom: 0;
  position: relative;
  padding: 0 0.25em;
}
.dc-global-nav__item.open .dc-global-nav__dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 75vh;
  overflow-y: auto;
}
.dc-global-nav__submenu .dc-global-nav__item {
  padding-left: calc(0.75em);
}
.dc-global-nav__submenu {
  background: #f8fafc;
  border-top: 1px solid #dae1e7;
  border-bottom: 1px solid #dae1e7;
}
.dc-global-nav__parent > div {
  display: none;
}
.dc-global-nav__parent.open > div {
  display: block;
}
.dc-global-nav__link {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  white-space: nowrap;
  min-width: 40px;
  width: 100%;
  overflow: hidden;
  background: transparent;
  border: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 7px;
  text-transform: none;
  border-radius: 0;
  color: #fff;
}
.dc-global-nav__link.active .dc-global-nav__text::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 2px solid currentColor;
}
.dc-global-nav__link > .fa {
  margin-left: auto;
  margin-right: 0.5em;
  justify-self: flex-end;
  font-size: 0.85em;
  -webkit-transition: -webkit-transform 125ms ease-in-out;
  transition: -webkit-transform 125ms ease-in-out;
  transition: transform 125ms ease-in-out;
  transition: transform 125ms ease-in-out, -webkit-transform 125ms ease-in-out;
  pointer-events: none;
}
.open > .dc-global-nav__link > .fa {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.dc-global-nav__link:hover .dc-global-nav__text::after,
.dc-global-nav__link:focus .dc-global-nav__text::after {
  border-color: #8795a1;
}
.dc-global-nav__link:focus {
  outline: 1px dotted;
}
.dc-global-nav__link:hover,
.dc-global-nav__link:focus {
  text-decoration: none;
}
.dc-global-nav__link > span {
  -ms-flex-item-align: center;
      align-self: center;
}
.dc-global-nav__icon {
  width: 30px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dc-global-nav__icon .fa {
  font-size: 1.2em;
  width: 20px;
  text-align: center;
  color: #dae1e7;
}
.dc-global-nav__icon--smaller .fa {
  font-size: 1em;
}
.dc-global-nav__text {
  margin: 1px 0.5em 1px;
  DISPLAY: inline-block;
  line-height: 28px;
  vertical-align: text-bottom;
  pointer-events: none;
  position: relative;
}
.dc-global-nav__dropdown {
  display: none;
  position: absolute;
  background: #f1f5f8;
  padding: 0.25em 0;
  -webkit-transform: translateX(-0.25em);
          transform: translateX(-0.25em);
  min-width: calc(100% + 0.5em);
  max-height: 90vh;
  border: 1px solid #b8c2cc;
  border-top: 0;
  border-radius: 0 0 3px 3px;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
}
.dc-global-nav__dropdown * {
  color: #232323 !important;
}
.dc-global-nav__dropdown ul {
  min-width: 200px;
  padding: 0;
}
.dc-global-nav__dropdown ul .dc-global-nav__link:hover .dc-global-nav__text,
.dc-global-nav__dropdown ul .dc-global-nav__link:focus .dc-global-nav__text {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.dc-global-nav__dropdown ul .dc-global-nav__item--selected:not(.dc-global-nav__separator) {
  -webkit-box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
          box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
  border-radius: 4px;
}
.dc-global-nav__dropdown .dc-global-nav__link.active .dc-global-nav__text::after {
  border-color: #4a196f;
}
.dc-global-nav__dropdown .dc-global-nav__icon .fa {
  color: #606f7b !important;
  margin-bottom: 2px;
}
.dc-global-nav__extra-links {
  background-color: #f8fafc;
  border-left: 1px solid #dae1e7;
}
.dc-global-nav__extra-links .dc-global-nav__link {
  max-width: 250px;
}
.dc-global-nav__extra-links .dc-global-nav__text {
  text-overflow: ellipsis;
  overflow: hidden;
}
.dc-global-nav__item-description {
  min-width: 150px;
  max-width: 300px;
  margin: 1.5em 2em;
}
.dc-global-nav__item-description h4 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  margin-top: 0;
}
.dc-global-nav__separator {
  border-top: 1px solid #dae1e7;
  margin: 2px 6px;
}
@media screen and (min-width: 1337px) {
  .offscreen-navigation__trigger {
    display: none;
  }
}
.offscreen-navigation__backdrop {
  position: fixed;
  background: transparent;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 100;
}
.offscreen-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: #f8fafc;
  position: fixed;
  left: 0;
  top: 50px;
  bottom: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-right: 1px solid #ddd;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  z-index: 100;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-transform 150ms ease-in-out;
  transition: -webkit-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  width: 80vw;
  max-width: 400px;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  overflow-x: auto;
}
.offscreen-navigation--toggled {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
dc-accordion-menu {
  display: block;
  min-width: 250px;
  counter-reset: depth;
  max-width: 100%;
}
dc-accordion-menu-item {
  display: block;
  width: 100%;
}
.dc-accordion-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
.dc-accordion-menu__item {
  height: auto;
  margin-bottom: 0;
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.dc-accordion-menu__item--reversed {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.dc-accordion-menu__item.open > .dc-accordion-menu__submenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dc-accordion-menu__link {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  white-space: nowrap;
  min-width: 40px;
  overflow: hidden;
  background: transparent;
  border: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 7px;
  text-transform: none;
  border-radius: 0;
  color: #232323;
  width: 100%;
  text-align: left;
}
.dc-accordion-menu__submenu .dc-accordion-menu__link {
  padding-left: calc(0.75em + 7px);
}
.dc-accordion-menu__submenu ul .dc-accordion-menu__link {
  padding-left: calc(1.5em + 7px);
}
.dc-accordion-menu__submenu ul ul .dc-accordion-menu__link {
  padding-left: calc(1.25em + 7px);
}
.dc-accordion-menu__link.active .dc-accordion-menu__text {
  border-color: currentColor;
  -webkit-box-shadow: 0 2px 0 0 currentColor;
          box-shadow: 0 2px 0 0 currentColor;
}
.dc-accordion-menu__link:hover .dc-accordion-menu__text {
  border-color: #8795a1;
  -webkit-box-shadow: 0 2px 0 0 #8795a1;
          box-shadow: 0 2px 0 0 #8795a1;
}
.dc-accordion-menu__link:hover,
.dc-accordion-menu__link:focus {
  text-decoration: none;
}
.dc-accordion-menu__link:focus {
  z-index: 1;
}
.dc-accordion-menu__link:focus:not(.dc-accordion-menu__separator) {
  -webkit-box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
          box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
  border-radius: 4px;
  outline: none;
}
.dc-accordion-menu__link > .fa {
  margin-left: auto;
  margin-right: 0.5em;
  justify-self: flex-end;
  font-size: 0.85em;
  color: #8795a1;
  -webkit-transition: -webkit-transform 125ms ease-in-out;
  transition: -webkit-transform 125ms ease-in-out;
  transition: transform 125ms ease-in-out;
  transition: transform 125ms ease-in-out, -webkit-transform 125ms ease-in-out;
  pointer-events: none;
}
.open > .dc-accordion-menu__link > .fa {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.dc-accordion-menu__link > span {
  -ms-flex-item-align: center;
      align-self: center;
}
.dc-accordion-menu__icon {
  width: 30px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dc-accordion-menu__icon .fa {
  font-size: 1.2em;
  width: 20px;
  text-align: center;
  color: #8795a1;
}
.dc-accordion-menu__icon--smaller .fa {
  font-size: 1em;
}
.dc-accordion-menu__text {
  margin: 0 0.5em 0 0.5em;
  display: inline-block;
  line-height: 28px;
  vertical-align: text-bottom;
  pointer-events: none;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dc-accordion-menu__extra-link {
  border-radius: 0.25rem;
  color: #8795a1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 7px 1em;
}
.dc-accordion-menu__extra-link span {
  line-height: 28px;
}
.open > div > .dc-accordion-menu__extra-link span {
  display: inline !important;
}
.dc-accordion-menu__extra-link:hover {
  background: #f1f5f8;
  text-decoration: none;
  padding-left: 1em;
}
.dc-accordion-menu__extra-link:focus {
  outline: 2px solid currentColor;
}
.dc-accordion-menu__submenu {
  display: none;
  padding: 0;
  list-style: none;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.dc-accordion-menu__submenu--reversed {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.dc-accordion-menu__submenu .dc-accordion-menu__icon .fa {
  color: #606f7b !important;
}
.dc-accordion-menu__separator {
  border-top: 1px solid #dae1e7;
  margin: 2px 6px;
}
#body [ui-view="aside"] .dc-accordion-menu__container {
  background: #fbfbfb;
  border: 1px solid #e6e6e6;
  border-bottom-color: #ccc;
  border-right-color: #ccc;
  border-radius: 3px;
  -webkit-box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.05);
          box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.05);
}
.offscreen-navigation [ui-view="aside"] .dc-accordion-menu__container {
  border-bottom: 1px solid #dae1e7;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
dc-tabs[slim] li > a {
  padding: 5px 8px;
}
.dc-panel .dc-tabs > li.active > a {
  background-color: #fff;
}
.dc-panel .dc-tabs.nav-pills {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 0.5rem;
  row-gap: 0.5rem;
  padding: 0.5rem;
}
.dc-panel .dc-tabs.nav-pills > li.active > a {
  color: #555555;
  border: 1px solid #ddd;
}
.dc-tabs__tab-row.nav-pills {
  background: #fff;
  padding-top: 0.5rem;
}
dc-tabs[transparent] .dc-tabs__tab-row.nav-pills {
  background: transparent;
}
.dc-tabs__tab-row dc-tab-before {
  padding-left: 1rem;
}
.dc-tabs__tab-row dc-tab-after {
  padding-right: 1rem;
}
.dc-panel .dc-tabs.nav-pills > li > a {
  border: 1px solid transparent;
}
.dc-tabs__tab-row.nav-pills > [ng-transclude]:first-child:not(:empty)::after,
.dc-tabs__tab-row.nav-pills > [ng-transclude]:last-child:not(:empty)::before {
  content: '';
  display: block;
  height: 95%;
  border-right: 1px solid #dae1e7;
}
.dc-tabs__tab-row.nav-pills > [ng-transclude]:first-child:not(:empty)::after,
.dc-tabs__tab-row.nav-pills > [ng-transclude]:last-child:not(:empty)::before {
  margin-left: 1rem;
  margin-right: 1rem;
}
dc-select,
.dc-select {
  display: inline-block;
  position: relative;
}
dc-select select,
.dc-select select {
  min-width: 80px;
}
dc-select select:not(.form-control),
.dc-select select:not(.form-control) {
  border: none;
  border-radius: 0;
  max-width: 100%;
  padding: 0.3em 0.3em 0.3em 0.4em;
}
dc-select select.form-control,
.dc-select select.form-control {
  max-width: 100%;
  padding: 0.3em 0.3em 0.3em 0.4em;
}
.dc-select__arrow {
  background-color: transparent;
  pointer-events: none;
  position: absolute;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ccc;
  top: 4px;
  right: 4px;
  bottom: 4px;
  padding: 0 0.3em;
  display: none;
}
.dc-select__arrow svg {
  height: 16px;
  width: 16px;
}
@supports ((-webkit-appearance: none) or (-moz-appearance: none) or (appearance: none)) or (-webkit-appearance: none) {
  dc-select select:not(.form-control),
  .dc-select select:not(.form-control),
  dc-select select.form-control,
  .dc-select select.form-control {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: #fefefe;
    padding-right: 2em;
    width: 100%;
  }
  dc-select--bordered select,
  .dc-select--bordered select {
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  dc-select:hover select,
  .dc-select:hover select {
    border-color: #aaa;
  }
  .dc-select__arrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
/* Default custom select styles */
dc-select2 {
  max-width: 100%;
}
div.dc-select2 {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 98;
  height: auto;
  min-width: 150px;
  max-width: 500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100% !important;
}
div.dc-select2.form-control {
  padding: 0;
}
div.dc-select2--borderless {
  border: 0;
}
div.dc-select2:focus-within .dc-select2__placeholder {
  background-color: #f1f5f8;
  outline: 1px solid #b8c2cc;
}
div.dc-select2:hover .dc-select2__placeholder {
  background-color: #f1f5f8;
}
div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */
}
.dc-select2 select {
  display: none;
}
.dc-select2 span {
  display: block;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.25em 0.5em;
}
dc-select2[slim] .dc-select2 span.dc-select2__placeholder {
  padding: 0.25em 0.5em;
}
.dc-select2__placeholder {
  padding: 0.5em;
}
/* Placeholder and selected option */
.dc-select2 > span {
  padding-right: 2.5em;
}
.dc-select2--borderless.dc-select2 > span {
  padding: 0.3em 2.5em 0.3em 0.4em;
}
.dc-select2__arrow {
  background-color: transparent;
  pointer-events: none;
  position: absolute;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #8795a1;
  top: 4px;
  right: 4px;
  bottom: 4px;
  padding: 0 0.3em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dc-select2__arrow svg {
  height: 16px;
  width: 16px;
}
.dc-select2--active .dc-select2__arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
div.dc-select2--active {
  z-index: 99;
  -webkit-box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
          box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
  border-radius: 4px;
  border-color: #4a196f;
  outline: 0;
}
/* Options */
.dc-select2 .dc-select2__options {
  position: absolute;
  left: -1px;
  overflow: hidden;
  width: clamp(calc(100% - 10ch), 250px, calc(100% + 10ch));
  background: #fff;
  visibility: hidden;
  top: calc(100% - 1px);
  border-radius: 2px;
  border: 1px solid #b8c2cc;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  max-height: 22em;
  overflow-y: auto;
}
.dc-select2.dc-select2--active .dc-select2__options {
  visibility: visible;
  min-width: 100%;
}
.dc-select2 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 100%;
}
.dc-select2__group {
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #dae1e7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  text-transform: uppercase;
}
.dc-select2__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.dc-select2 .dc-select2__option-text {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0.5em;
}
.dc-select2 ul .dc-select2__group:hover,
.dc-select2 ul .dc-select2__group:focus,
.dc-select2 ul .dc-select2__option:hover,
.dc-select2 ul .dc-select2__option:focus,
.dc-select2 ul .dc-select2__option--focus {
  background-color: #dae1e7;
}
.dc-select2 ul .dc-select2__group:focus,
.dc-select2 ul .dc-select2__option:focus,
.dc-select2 ul .dc-select2__option--focus {
  outline: 1px solid #8795a1;
  outline-offset: -2px;
  border-radius: 4px;
}
.dc-select2 ul li.dc-select2__option--disabled {
  color: #8795a1;
}
/* Optgroup and optgroup label */
.dc-select2 li.dc-select2__optgroup ul {
  padding-left: 0.5em;
}
.dc-select2 li.dc-select2__optgroup > span {
  cursor: default;
}
.dc-select2 [ng-transclude]:empty {
  display: none;
}
dc-input-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
dc-input-number button {
  background-color: transparent;
  font-size: 0.8em;
  min-width: 30px;
}
dc-input-number button:disabled {
  color: #ccc;
}
dc-input-number input {
  max-width: 70px;
  padding: 0.3em 0.4em;
}
dc-input-number.dc-input-number--borderless input {
  border: 0;
}
.page-settings {
  background-color: #fff;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5em 0;
  margin-bottom: 1em;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media screen and (min-width: 1337px) {
  .page-settings {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.page-settings + .page-settings {
  border-top: none;
  margin-top: -1em;
}
.dc-panel-body:not(.no-padding) .page-settings,
.dc-panel-body.l-p05 .page-settings {
  margin-left: -0.5em;
  margin-right: -0.5em;
}
.page-settings .input-group-btn {
  width: auto;
}
.page-settings--left {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.page-settings--right {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.page-settings input:focus,
.page-settings select:focus,
.page-settings textarea:focus {
  outline: 1px solid #b8c2cc;
}
.page-settings__row {
  border-top: 1px solid #ddd;
  margin-top: 0.5em;
  padding-top: 0.5em;
  width: 100%;
}
.page-settings__part {
  padding: 0 0.7em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-right: 1px solid #ddd;
  width: 50%;
}
@media screen and (min-width: 600px) {
  .page-settings__part {
    width: calc(100% / 3);
  }
}
@media screen and (min-width: 800px) {
  .page-settings__part {
    width: calc(100% / 4);
  }
}
@media screen and (min-width: 1337px) {
  .page-settings__part {
    width: auto;
  }
}
.page-settings__part:first-child {
  border-left: 1px solid #ddd;
}
.page-settings.page-settings--left .page-settings__part:first-child {
  border-left: none;
}
.page-settings.page-settings--left .page-settings__part:first-child {
  border-left: none;
}
.page-settings.page-settings--right .page-settings__part:last-child {
  border-right: none;
}
.page-settings__row.page-settings__row--borderless .page-settings__part {
  border: none;
}
.page-settings__part:hover select,
.page-settings__part:hover .dc-select__arrow {
  background-color: #eee;
}
.page-settings__boxlabel {
  display: block;
  font-size: 0.85em;
  margin-bottom: 0;
  margin-left: 0.4em;
}
.page-settings__boxlabel.l-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page-settings__input--text,
.page-settings__input--number,
.page-settings__input--search {
  background: transparent;
  border: 0;
  padding: 0.3em 0.4em;
}
.page-settings__part:hover .page-settings__input--text,
.page-settings__part:hover .page-settings__input--number,
.page-settings__part:hover .page-settings__input--search {
  background-color: #eee;
}
.page-settings__input--number {
  max-width: 100px;
}
.page-settings__input--search {
  padding: 0.3em 2em 0.3em 0.4em;
  width: 200px;
}
.page-settings__radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0.25em 0.5em;
  margin-right: 0.2em;
  margin-bottom: 0;
  font-weight: normal;
}
.page-settings__radio input[type=radio] {
  font-size: 0.8em;
  margin-right: 0.4em;
}
.page-settings__part:hover .page-settings__radio {
  background-color: #eee;
}
/* DATERANGE PICKER SPECIFIC OVERWRITES */
.page-settings .input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-settings .form-control,
.page-settings button {
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  height: auto;
}
.page-settings .form-control {
  padding-left: 0.5em;
}
.page-settings button {
  border-radius: 0;
}
.page-settings .page-settings__part:hover .dc-daterangepicker .input-group button {
  background-color: #eee;
}
.u-page-settings__loading-space:last-child {
  margin-right: 1.6rem;
}
.password-strength__meter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 8px;
  margin: 2px 0;
}
.password-strength__meter-part {
  background-color: #f1f1f1;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.password-strength__meter-part:not(:first-child) {
  margin-left: 2px;
}
.password-strength__meter-part--highlighted {
  background-color: #5cb85c;
  border-color: #449d44;
}
.password-strength__warning,
.password-strength__suggestions {
  margin: 2px 0;
}
.password-strength__suggestions {
  list-style-type: none;
}
.password-strength__suggestion:not(:last-child) {
  border-bottom: 1px solid #85d0e7;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
}
.password-strength__meter-part--bad {
  background-color: #ff7c7c;
  border-color: #ff4949;
}
.password-strength__meter-part--better {
  background-color: #f1c573;
  border-color: #e79e17;
}
.password-strength__meter-part--good {
  background-color: #b3ffb3;
  border-color: #80ff80;
}
.password-strength__meter-part--great {
  background-color: #49ff49;
  border-color: #16ff16;
}
.dc-switch__wrapper {
  gap: 2rem;
  margin-bottom: 0;
}
dc-switch {
  display: inherit;
  padding: 4px;
}
dc-switch[small] .dc-switch__wrapper {
  gap: 1rem;
}
dc-switch[justify-between] .dc-switch__wrapper > span[ng-transclude] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  max-width: 60ch;
}
.dc-switch {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 50px;
  margin: 0;
}
.dc-switch:focus,
.dc-switch:focus-within {
  -webkit-box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.4), 0 0 0 1px rgba(74, 25, 111, 0.4);
          box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.4), 0 0 0 1px rgba(74, 25, 111, 0.4);
  border-radius: 60px;
}
dc-switch[small] .dc-switch {
  height: 22px;
  width: 36px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.dc-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.dc-switch input:checked + .dc-switch__slider {
  background-color: #5cb85c;
}
.dc-switch input:focus + .dc-switch__slider {
  -webkit-box-shadow: 0 0 1px #5cb85c;
          box-shadow: 0 0 1px #5cb85c;
}
.dc-switch input:checked + .dc-switch__slider:before {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
dc-switch[small] .dc-switch input:checked + .dc-switch__slider:before {
  -webkit-transform: translateX(14px);
  transform: translateX(14px);
}
dc-switch[reverse] .dc-switch {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
dc-switch:not([reverse]) .dc-switch {
  margin-left: auto;
}
dc-switch[reverse] span[ng-transclude] {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.dc-switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
dc-switch[dark] .dc-switch__slider {
  background-color: #606f7b;
}
.dc-switch__slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
dc-switch[small] .dc-switch__slider:before {
  height: 14px;
  width: 14px;
}
.dc-switch__slider.round {
  border-radius: 60px;
}
.dc-switch__slider.round:before {
  border-radius: 50%;
}
dc-busy {
  display: block;
}
dc-busy,
[dc-busy] {
  min-height: 60px;
}
.dc-busy__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  min-height: 4em;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.dc-busy__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
}
.dc-busy__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 1;
}
.dc-busy__msg {
  color: #4a196f;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.7em;
  font-weight: 500;
  -webkit-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
  -webkit-transform-origin: 0px -30px;
          transform-origin: 0px -30px;
}
.dc-busy__folding-cube {
  margin: 20px auto;
  width: 20px;
  height: 20px;
  position: relative;
  -webkit-transform: translate(5px, -5px) rotateZ(45deg);
  transform: translate(5px, -5px) rotateZ(45deg);
}
.dc-busy__folding-cube .dc-busy__cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.dc-busy__folding-cube .dc-busy__cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #4a196f;
  border-top-left-radius: 2px;
  -webkit-animation: dc-busy__foldCubeAngle 2.4s infinite linear both;
  animation: dc-busy__foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.dc-busy__folding-cube .dc-busy__cube:nth-child(2) {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.dc-busy__folding-cube .dc-busy__cube:nth-child(4) {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.dc-busy__folding-cube .dc-busy__cube:nth-child(3) {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.dc-busy__folding-cube .dc-busy__cube:nth-child(2):before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.dc-busy__folding-cube .dc-busy__cube:nth-child(4):before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.dc-busy__folding-cube .dc-busy__cube:nth-child(3):before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes dc-busy__foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes dc-busy__foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
dc-facility-select-grid label:not(.selected):hover {
  background: #f1f5f8;
  border-color: #b8c2cc;
  color: #22292f;
}
.legend__square,
.legend__line,
.legend__circle {
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
.legend__line {
  width: 3px;
}
.legend__circle {
  border-radius: 50%;
}
.bg-grey-lightest,
.hover\:bg-grey-lightest:hover {
  background-color: #f8fafc;
}
.bg-grey-lighter,
.hover\:bg-grey-lighter:hover {
  background-color: #f1f5f8;
}
.bg-grey-light,
.hover\:bg-grey-light:hover {
  background-color: #dae1e7;
}
.bg-self {
  background-color: #3fa3d8;
}
.bg-self-negative {
  background-color: #d27300;
}
.bg-self-comp {
  background-color: #3fa3d8;
}
.bg-self-comp-negative {
  background-color: #d27300;
}
.bg-compset {
  background-color: #a9d6ee;
}
.bg-compset-negative {
  background-color: #e8bc87;
}
.bg-compset-comp {
  background-color: #a9d6ee;
}
.bg-compset-comp-negative {
  background-color: #e8bc87;
}
.bg-chain-revenue-otb {
  background-color: #78bee7;
}
.bg-chain-revenue-forecast {
  background-color: #a8daf7;
}
.bg-chain-revenue-forecast-surpassed {
  background-color: #1a5fa1;
}
.bg-rate {
  background-color: #d27300;
}
.bg-vol {
  background-color: #1a5fa1;
}
.bg-positive {
  background-color: #89d789;
}
.bg-negative {
  background-color: #ed8a87;
}
.bg-neutral {
  background-color: #7cb5ec;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .forecast__date-selector {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 98;
  }
  .forecast__dates-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 47px;
    z-index: 98;
    background: #fbfbfb;
    border-bottom: 1px solid #bbb;
  }
}
.forecast__market-demand_day {
  color: #000;
}
.forecast__market-demand_day--full {
  background-color: #77bf85;
}
.forecast__market-demand_day--highest {
  background-color: #8fcc8b;
}
.forecast__market-demand_day--high {
  background-color: #b5d999;
}
.forecast__market-demand_day--low {
  background-color: #ffbc3f;
}
.forecast-overview__day--full {
  background-color: #232323;
  border-color: #232323 !important;
}
.forecast-overview__day--full a:link,
.forecast-overview__day--full a:hover {
  color: #8795a1;
}
.forecast-overview__day--highest {
  background-color: #77bf85;
  border-color: #77bf85 !important;
}
.forecast-overview__day--high {
  background-color: #b5d999;
  border-color: #b5d999 !important;
}
.forecast-overview__day--low {
  background-color: #ffbc3f;
  border-color: #ffbc3f !important;
}
.forecast-overview__day--hasComment {
  -webkit-box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.2);
}
.forecast-overview__day--hasComment.forecast-overview__day--full {
  -webkit-box-shadow: inset 1px -4px 0 0 rgba(255, 255, 255, 0.4);
          box-shadow: inset 1px -4px 0 0 rgba(255, 255, 255, 0.4);
}
.forecast-overview__legend-block {
  margin-right: 0.2em;
  padding: 0.6em;
}
.forecast-overview__legend-block:not(:first-child) {
  margin-left: 1em;
}
[quad-chart-data-chart],
[quad-chart-events-chart],
[quad-chart-navigator] {
  background-color: #fff;
}
.quadchart .quadchart-legend {
  font-size: 85%;
  padding: 0.5em 1em;
}
.quadchart .quadchart-legend .quadchart-legend-row {
  margin-bottom: 10px;
}
.quadchart .quadchart-legend .quadchart-legend-row:before,
.quadchart .quadchart-legend .quadchart-legend-row:after {
  content: " ";
  display: table;
}
.quadchart .quadchart-legend .quadchart-legend-row:after {
  clear: both;
}
.quadchart .quadchart-legend .quadchart-legend-row .quadchart-legend-first,
.quadchart .quadchart-legend .quadchart-legend-row .quadchart-legend-name {
  float: left;
}
.quadchart .quadchart-legend .quadchart-legend-row .quadchart-legend-name {
  font-weight: bold;
  top: -4px;
  position: relative;
}
.quadchart .quadchart-legend .quadchart-legend-row .quadchart-legend-line {
  width: 112px;
  margin-bottom: 0.5em;
  height: 4px;
  display: block;
  position: relative;
  top: -2px;
}
.quadchart .quadchart-sidebar-subheading {
  border-bottom: 1px solid rgba(128, 128, 128, 0.51);
  font-weight: bold;
  line-height: 1.7em !important;
  margin-bottom: 0.5em;
}
.quadchart .quadchart-date-labels {
  height: 62px;
}
.quadchart .quadchart-date-labels-top {
  margin-bottom: 7px;
}
.quadchart .quadchart-data-label {
  line-height: 15px;
  display: inline-block;
}
.quadchart .quadchart-chart-data {
  margin-bottom: 6px;
}
.quadchart .quadchart-chart-data .chart-label > div {
  width: 260px;
}
.quadchart .quadchart-chart-data .chart {
  height: 260px;
  width: 100%;
}
.quadchart .quadchart-spreadsheet-box div {
  line-height: 0.7em;
}
.quadchart .quadchart-chart-events {
  margin-bottom: 6px;
}
.quadchart .quadchart-chart-events .chart-label {
  background-color: #cccccc;
}
.quadchart .quadchart-chart-events .chart-label > div {
  color: #000000;
  width: 140px;
}
.quadchart .quadchart-chart-events .chart {
  height: 140px;
  width: 100%;
}
.quadchart .chart-label-visibility-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  max-height: 29px;
  height: 100%;
  position: relative;
}
.quadchart .quadchart-navigator {
  margin-bottom: 6px;
  height: 70px;
}
.quadchart .quadchart-sidebar {
  margin-left: 20px;
}
.quadchart .quadchart-sidebar .dc-panel {
  margin-bottom: 20px;
}
.quadchart .quad-chart-spreadsheet {
  margin-bottom: 10px;
  background-color: #fff;
  width: 100%;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-row:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-label {
  width: 4%;
  white-space: nowrap;
  font-size: 10px;
  font-weight: bold;
  padding-top: 0.25rem;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-label--compact span {
  display: block;
  line-height: 1.7;
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.2em;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-cell {
  width: 3.42%;
  position: relative;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-cell--error {
  background-color: #f0b9b8;
  -webkit-box-shadow: inset 1px 1px 0 #d9534f, inset -1px -1px 0 #d9534f;
          box-shadow: inset 1px 1px 0 #d9534f, inset -1px -1px 0 #d9534f;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-input {
  float: left;
  height: 22px;
  width: 100%;
  text-align: right;
  border: none;
  border-color: transparent #ddd transparent transparent;
  font-size: 12px;
  padding: 0 3px;
  background-color: transparent;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-input:first-of-type {
  margin-left: 0.2%;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-input:not([readonly]):focus {
  border-radius: 2px;
  outline: none;
  -webkit-box-shadow: inset 0 0 0 1px #7427ad, 0 0 0 1px #7427ad;
          box-shadow: inset 0 0 0 1px #7427ad, 0 0 0 1px #7427ad;
  background-color: #f6f6f6;
  text-align: center;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-input:not([readonly]):focus + .quad-chart-spreadsheet-rounded {
  visibility: hidden;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-input[readonly] {
  color: #bbb;
  cursor: not-allowed;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-input.isNegative {
  color: #d9534f;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-input.isPositive {
  color: #5cb85c;
}
.quadchart .quad-chart-spreadsheet .quadchart-spreadsheet-cell-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
  font-size: 12px;
  line-height: 1;
  pointer-events: none;
  padding: 0 3px;
}
.quadchart .quad-chart-spreadsheet input:hover + .quadchart-spreadsheet-cell-cover,
.quadchart .quad-chart-spreadsheet input:focus + .quadchart-spreadsheet-cell-cover {
  display: none;
}
.quadchart .quad-chart-spreadsheet input:-moz-read-only + .quadchart-spreadsheet-cell-cover:not(.quadchart-spreadsheet-cell-cover--static) {
  color: #777;
}
.quadchart .quad-chart-spreadsheet input:read-only + .quadchart-spreadsheet-cell-cover:not(.quadchart-spreadsheet-cell-cover--static) {
  color: #777;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-rounded {
  background: #fff;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  font-weight: normal;
  cursor: text;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-rounded--error {
  visibility: hidden;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-cell--error .quad-chart-spreadsheet-rounded {
  visibility: hidden;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-cell-editable {
  border: 1px solid #D6D6CE;
}
.quadchart .quad-chart-spreadsheet .quad-chart-spreadsheet-cell--inactive input:not(:focus) {
  color: #bbb;
}
.quadchart .quad-chart-icons .quad-chart-icons-label {
  float: left;
  width: 3.75%;
  margin-left: 0.25%;
  height: 26px;
  font-weight: bold;
  text-align: center;
}
.quadchart .quad-chart-icons .quad-chart-icons-column {
  float: left;
  height: 26px;
  width: 2.97%;
  margin-left: 0.45%;
  text-align: center;
  font-size: 13px;
  padding: 0;
}
.quadchart .quadchart-plotband-label {
  width: 31px;
}
.quadchart .quadchart-plotband-label::before {
  content: "";
  display: none;
  background-color: #f2f2f2;
  width: 100%;
  height: calc(100% + 5px);
  position: absolute;
  top: -3px;
  left: 0;
}
.quadchart .quadchart-plotband-label-with-content::before {
  display: block;
}
.quadchart .quadchart-plotband-label-holiday::before {
  background-color: #ffeaea;
}
.quadchart .quadchart-plotband-label > div {
  color: #8795a1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-size: 0.9em;
  min-height: 10px;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%;
  gap: 1px;
}
.quadchart .quadchart-plotband-holidays,
.quadchart .quadchart-plotband-comments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.quadchart .draggable-point {
  cursor: ns-resize;
}
.quadchart-plotband-tooltip .popover-content {
  border-radius: 3px;
  color: #232323;
  font-size: 0.85em;
  width: 180px;
  padding: 0.3rem 0.6rem;
}
.quadchart-plotband-tooltip .popover-content p {
  margin-bottom: 0;
}
.quadchart-plotband-tooltip .popover-content p:not(:last-child) {
  border-bottom: 0.5px solid lightblue;
  margin-bottom: 0.25em;
  padding-bottom: 0.25em;
}
#strategy-dashboard h2 {
  font-size: 20px;
}
#strategy-dashboard .strategy-dashboard-new_bookings {
  width: 32%;
  float: left;
  padding-right: 25px;
}
#strategy-dashboard .dc-checkbox {
  margin-top: 2px;
}
#strategy-dashboard .legend--forecast .dc-checkbox__content > span,
#strategy-dashboard .legend--actually .dc-checkbox__content > span {
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
#strategy-dashboard .legend--otb .dc-checkbox__content > span,
#strategy-dashboard .legend--otbly .dc-checkbox__content > span {
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}
#strategy-dashboard .legend--forecast span,
#strategy-dashboard .legend--otb span {
  border-color: #3fa3d8;
}
#strategy-dashboard .legend--actually span,
#strategy-dashboard .legend--otbly span {
  border-color: #d27300;
}
.dayByDayPickup__chart-label {
  line-height: 1.1;
}
.dayByDayPickup__chart-menu {
  position: absolute;
}
.dayByDayPickup__table-container {
  border-top: 0;
  margin-top: -1px;
  width: 97%;
}
.dayByDayPickup__table-container--wide {
  width: 99%;
}
.dayByDayPickup__table-container .dayByDayPickup__table--31 td {
  width: 3.22580645%;
}
.dayByDayPickup__table-container .dayByDayPickup__table--30 td {
  width: 3.33333333%;
}
.dayByDayPickup__table-container .dayByDayPickup__table--29 td {
  width: 3.44827586%;
}
.dayByDayPickup__table-container .dayByDayPickup__table--28 td {
  width: 3.57142857%;
}
.dayByDayPickup__table {
  width: 100%;
}
.dayByDayPickup__table:first-child tr:first-child td {
  border-top: 0;
}
.dayByDayPickup__table td {
  font-size: 12px;
}
.dayByDayPickup__table-category {
  width: 55px;
}
.chart-plotband-label {
  width: 31px;
}
.chart-plotband-label::before {
  content: "";
  display: none;
  background-color: #f2f2f2;
  width: 100%;
  height: calc(100% + 5px);
  position: absolute;
  top: -3px;
  left: 0;
}
.chart-plotband-label-with-content::before {
  display: block;
}
.chart-plotband-label-holiday::before {
  background-color: #ffeaea;
}
.chart-plotband-label > div {
  color: #8795a1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-size: 0.9em;
  min-height: 10px;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%;
  gap: 1px;
}
.chart-plotband-holidays,
.chart-plotband-comments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.chart-plotband-tooltip .popover-content {
  border-radius: 3px;
  color: #232323;
  font-size: 0.85em;
  width: 180px;
  padding: 0.3rem 0.6rem;
}
.chart-plotband-tooltip .popover-content p {
  margin-bottom: 0;
}
.chart-plotband-tooltip .popover-content p:not(:last-child) {
  border-bottom: 0.5px solid lightblue;
  margin-bottom: 0.25em;
  padding-bottom: 0.25em;
}
#signin {
  margin: 0 auto;
  max-width: 1337px;
}
#signin .panel {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 2px;
  background-clip: padding-box;
  margin: 0 auto;
  max-width: 500px;
}
#signin .panel-heading {
  background-color: #4a196f;
  padding: 1.5em;
  text-align: center;
}
#signin .panel-heading img {
  width: 60%;
}
#signin .panel-body {
  padding: 43px 71px 27px;
}
#signin .panel-body h1 {
  margin-top: 0;
}
#signin .btn-md {
  border-radius: 6px;
}
#signin .signin-submit {
  margin-top: 25px;
}
#signin .signin-button {
  border-top: 1px solid #DDD;
  padding: 16px 71px;
}
.revenue-management-pickup-page .highcharts-tooltip {
  z-index: 9999;
}
.pickup-chart,
.dc-chart-with-label {
  padding-left: 29px;
  position: relative;
}
.pickup-chart .chart-label,
.dc-chart-with-label .chart-label {
  background-color: #4a196f;
  font-size: 0.95em;
  height: 100% !important;
  width: 29px !important;
  position: absolute;
  left: 0px;
}
.pickup-chart .chart-label > div,
.dc-chart-with-label .chart-label > div {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform: translateY(-29px) rotate(-90deg);
          transform: translateY(-29px) rotate(-90deg);
  color: white;
  font-weight: 100;
  letter-spacing: 1.5px;
  position: absolute;
  text-align: center;
  right: 0;
  width: 211px;
  line-height: 29px;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}
.pickup-chart .chart-label strong,
.dc-chart-with-label .chart-label strong {
  font-weight: 400;
}
.pickup-aggregated .chart-label > div {
  width: 450px;
}
.pickup-aggregated .chart {
  background-color: #fff;
  height: 450px;
}
.pickup-sales .chart-label > div {
  width: 350px;
}
.pickup-sales .chart {
  background-color: #fff;
  height: 350px;
}
.pickup-mix .chart-label > div {
  width: 440px;
}
.pickup-mix .chart {
  background-color: #fff;
  height: 440px;
}
.pickup-spread .chart,
.pickup-mix .chart {
  background-color: #fff;
}
.pickup-mix .chart {
  border: 1px solid #d9d9d9;
}
.pickup-aggregated-tooltip {
  padding: 6px 4px;
  background-color: white;
}
.pickup-aggregated-tooltip p {
  font-weight: bold;
  text-align: center;
  margin: 0 0 4px;
}
.pickup-aggregated-tooltip table th,
.pickup-aggregated-tooltip table td {
  padding: 2px 8px;
}
.pickup-aggregated-tooltip table th {
  font-style: italic;
  font-weight: normal;
}
.pickup-aggregated-tooltip table td:not(:first-child),
.pickup-aggregated-tooltip table th:not(:first-child) {
  text-align: right;
}
.pickup-user-forecast-tooltip {
  padding: 6px 4px;
  background-color: white;
}
.pickup-user-forecast-tooltip p {
  font-weight: bold;
  text-align: center;
  margin: 0 0 4px;
}
.pickup-user-forecast-tooltip table td:nth-child(2) {
  text-align: right;
}
.pickup-user-forecast-tooltip .last-updated {
  background-color: #dae1e7;
  margin-top: 5px;
  font-style: italic;
  font-size: 90%;
  margin: 4px -4px -6px;
  text-align: center;
  padding: 0.5em;
  white-space: nowrap;
}
.pickup-system-forecast-tooltip {
  padding: 6px 4px;
  background-color: white;
}
.pickup-system-forecast-tooltip p {
  text-align: center;
  margin: 0 0 4px;
}
.pickup-system-forecast-tooltip .title,
.pickup-system-forecast-tooltip .value {
  font-weight: bold;
}
.pickup-system-forecast-tooltip .what {
  font-size: 110%;
}
.pickup-system-forecast-tooltip .value {
  font-size: 125%;
}
.pickup-sales-tooltip {
  padding: 6px 4px;
  background-color: white;
}
.pickup-sales-tooltip p {
  font-weight: bold;
  text-align: center;
  margin: 0 0 4px;
}
.pickup-sales-tooltip table tr.booking-status-c {
  text-decoration: line-through;
  color: #8b8b8b;
}
.pickup-sales-tooltip table tr.booking-status-c td {
  color: #333333;
}
.pickup-sales-tooltip table th,
.pickup-sales-tooltip table td {
  padding: 2px 8px;
}
.pickup-sales-tooltip table th:nth-child(2),
.pickup-sales-tooltip table th:last-child {
  text-align: right;
}
.pickup-sales-tooltip table td:nth-child(2),
.pickup-sales-tooltip table td:nth-child(6) {
  text-align: right;
}
.pickup-sales-tooltip table .more {
  text-align: center;
  font-style: italic;
  border-top: 1px dotted gray;
  border-bottom: 1px dotted gray;
  font-size: 90%;
}
.pickup-ratespread-tooltip table {
  white-space: nowrap;
  width: 550px;
  table-layout: fixed;
}
.pickup-ratespread-tooltip table .pickup-tooltip-limited-length {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 1;
}
.pickup__summary {
  background-color: #fff;
}
.pickup__summary h4 {
  margin: 0 0 0.3em;
}
.pickup__summary input[type=text] {
  width: 40px;
}
.pickup__summary table td {
  border: 1px solid #eaeaea;
  font-size: 1.2em;
}
.pickup__summary table td.pickup__summary__extra {
  font-size: 1em;
}
.pickup__summary table th {
  width: 26px;
  font-weight: normal;
  height: 40px;
  border-top: 1px solid #fff;
  background: #4a196f;
  vertical-align: middle !important;
}
.pickup__summary table th > div {
  height: 40px;
}
.pickup__summary table tr:nth-child(1) th {
  height: 60px;
  border-top: 0;
}
.pickup__summary table tr:nth-child(1) th > div {
  height: 60px;
}
.pickup__summary__extra {
  width: 220px;
}
.pickup__potential {
  background: #fff;
  border: 1px solid #eaeaea;
}
.pickup__potential > div:last-child {
  border-left: 1px solid #eaeaea;
}
.pickup__potential .win.q4 {
  background-color: #77BF85;
  border-color: #77BF85;
  color: rgba(0, 0, 0, 0.6);
}
.pickup__potential .win.q3 {
  background-color: #8FCC8B;
  border-color: #8FCC8B;
  color: rgba(0, 0, 0, 0.6);
}
.pickup__potential .win.q2 {
  background-color: #B5D999;
  border-color: #B5D999;
  color: rgba(0, 0, 0, 0.6);
}
.pickup__potential .win.q1 {
  background-color: #D9FBD0;
  border-color: #C1D6B0;
  color: rgba(0, 0, 0, 0.6);
}
.pickup__potential .loss.q1 {
  background-color: #FFE6B4;
  border-color: #E8D0A2;
  color: rgba(0, 0, 0, 0.6);
}
.pickup__potential .loss.q2 {
  background-color: #FFBC3F;
  border-color: #FFBC3F;
  color: rgba(0, 0, 0, 0.6);
}
.pickup__potential .loss.q3 {
  background-color: #F99420;
  border-color: #F99420;
  color: rgba(0, 0, 0, 0.6);
}
.pickup__potential .loss.q4 {
  background-color: #E85727;
  border-color: #E85727;
  color: rgba(0, 0, 0, 0.6);
}
.pickup__potential-part {
  border-left: 1px solid #eaeaea;
}
.pickup__potential-day {
  border-bottom: 1px solid #eaeaea;
  padding: 0.1em;
  color: #606f7b;
  text-transform: uppercase;
  font-size: 0.9em;
}
.pickup__potential-day .pickup__potential-day--smaller {
  font-size: 0.9em;
}
.pickup__potential-number span {
  border-bottom: 1px dotted currentColor;
}
.pickup__potential-index h4 {
  color: #606f7b;
  font-size: 0.8em;
  font-weight: normal;
}
.pickup__potential-strength {
  border-top: 1px solid #eaeaea;
  font-size: 0.8em;
  padding: 0.2em;
  text-transform: uppercase;
}
.pickup__potential-error {
  color: #606f7b;
  font-size: 0.8em;
}
.pickup__potential-popover {
  max-width: 600px;
  font-size: 13px;
}
.pickup__potential-popover .popover-content {
  padding: 0.5em;
}
.year-profile-page .show-advanced-options {
  margin-top: 10px;
  display: inline-block;
}
.year-profile-page .section {
  margin-bottom: 30px;
}
.year-profile-page .potential-summary {
  background-color: #dae1e7;
  border-left: 0 !important;
  padding: 10px;
  text-align: center;
}
.year-profile-page .total-potential {
  font-size: 1.2em;
  font-weight: bold;
}
.year-profile-page .total-potential a {
  color: #232323;
}
.year-profile-page .per-quartile-potential {
  margin-top: 3px;
}
.year-profile-page .per-quartile-potential > span {
  font-style: italic;
  font-size: 90%;
}
.year-profile-page .per-quartile-potential table {
  margin: 0 auto;
  background-color: transparent;
}
.year-profile-page .per-quartile-potential table td {
  padding: 2px;
}
.year-profile-page .per-quartile-potential table td:last-child {
  text-align: right;
}
.year-profile-page .quartiles > div {
  margin-top: 8px;
}
.year-profile-page .quartiles label {
  font-size: 120%;
  display: inline;
  font-weight: normal;
}
.year-profile-page .quartiles input[type="checkbox"] {
  width: 15px;
  height: 15px;
}
.year-profile-page .breakdown-toggle {
  margin-left: 10px;
}
.year-profile-page table:hover td:not(:last-child).month-separator-right {
  border-right-color: rgba(0, 0, 0, 0.5) !important;
}
.year-profile-page table:hover tr:not(:last-child) .month-separator-bottom {
  border-bottom-color: rgba(0, 0, 0, 0.5) !important;
}
.year-profile-page .hasComment {
  position: relative;
}
.year-profile-page .hasComment span:after {
  content: '';
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 0;
  border: 3px solid rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}
.year-profile-page .year-profile:not(.showQ1) td.q1 span,
.year-profile-page .year-profile:not(.showQ2) td.q2 span,
.year-profile-page .year-profile:not(.showQ3) td.q3 span,
.year-profile-page .year-profile:not(.showQ4) td.q4 span {
  display: none;
}
.potential-popover {
  min-width: 160px;
}
.potential-popover .centered {
  text-align: center;
}
.potential-popover .date {
  font-weight: bold;
}
.potential-popover .separator {
  height: 1px;
  background-color: #cfcfcf;
  margin: 4px 0;
}
.potential-popover .potential-popover-right-column {
  float: right;
  text-align: right;
}
.potential-popover .section-header {
  font-style: italic;
}
.potential-popover ul {
  padding-left: 0;
  list-style-position: inside;
  margin: 0;
}
.year-profile {
  width: 100%;
  font-size: 90%;
}
.year-profile .no-data {
  background-color: #DCDCDC;
}
.year-profile.color-warm.showQ4 .win.q4 {
  background-color: #77BF85;
  border-color: #77BF85;
  color: rgba(0, 0, 0, 0.6);
}
.year-profile.color-warm.showQ3 .win.q3 {
  background-color: #8FCC8B;
  border-color: #8FCC8B;
  color: rgba(0, 0, 0, 0.6);
}
.year-profile.color-warm.showQ2 .win.q2 {
  background-color: #B5D999;
  border-color: #B5D999;
  color: rgba(0, 0, 0, 0.6);
}
.year-profile.color-warm.showQ1 .win.q1 {
  background-color: #D9FBD0;
  border-color: #C1D6B0;
  color: rgba(0, 0, 0, 0.6);
}
.year-profile.color-warm.showQ1 .loss.q1 {
  background-color: #FFE6B4;
  border-color: #E8D0A2;
  color: rgba(0, 0, 0, 0.6);
}
.year-profile.color-warm.showQ2 .loss.q2 {
  background-color: #FFBC3F;
  border-color: #FFBC3F;
  color: rgba(0, 0, 0, 0.6);
}
.year-profile.color-warm.showQ3 .loss.q3 {
  background-color: #F99420;
  border-color: #F99420;
  color: rgba(0, 0, 0, 0.6);
}
.year-profile.color-warm.showQ4 .loss.q4 {
  background-color: #E85727;
  border-color: #E85727;
  color: rgba(0, 0, 0, 0.6);
}
.year-profile.color-cold :not(.potential-hidden).win.q4 {
  background-color: #77BF85;
  border-color: #77BF85;
}
.year-profile.color-cold :not(.potential-hidden).win.q3 {
  background-color: #8FCC8B;
  border-color: #8FCC8B;
}
.year-profile.color-cold :not(.potential-hidden).win.q2 {
  background-color: #B5D999;
  border-color: #B5D999;
}
.year-profile.color-cold :not(.potential-hidden).loss.q2 {
  background-color: #FFBFB4;
  border-color: #FFBFB4;
}
.year-profile.color-cold :not(.potential-hidden).loss.q3 {
  background-color: #FF837E;
  border-color: #FF837E;
}
.year-profile.color-cold :not(.potential-hidden).loss.q4 {
  background-color: #FF4242;
  border-color: #FF4242;
}
.year-profile.color-2 :not(.potential-hidden).win.q4 {
  background-color: #34B262;
  border-color: #34B262;
}
.year-profile.color-2 :not(.potential-hidden).win.q3 {
  background-color: #7EFFAC;
  border-color: #7EFFAC;
}
.year-profile.color-2 :not(.potential-hidden).win.q2 {
  background-color: #B4FFCF;
  border-color: #B4FFCF;
}
.year-profile.color-2 :not(.potential-hidden).loss.q2 {
  background-color: #FFB7B4;
  border-color: #FFB7B4;
}
.year-profile.color-2 :not(.potential-hidden).loss.q3 {
  background-color: #FF837E;
  border-color: #FF837E;
}
.year-profile.color-2 :not(.potential-hidden).loss.q4 {
  background-color: #FF4942;
  border-color: #FF4942;
}
.potential-year-overview__rolling12month-highlight {
  border: 2px solid #3d4852 !important;
}
.potential-weekday__chart-container {
  min-height: 70px;
}
.potential-booking-breakdown__name {
  display: inline-block;
  width: 24ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.month-comment-trigger {
  position: relative;
}
.month-comment-trigger__unread {
  position: absolute;
  top: 2px;
  right: -2px;
  line-height: 0;
  font-size: 0.8em;
  color: red;
  width: 0.8em;
  height: 0.8em;
}
.month-comment-trigger__unread i:first-child {
  color: #fff;
}
.entity-analysis__label-parent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.entity-analysis__label-parent a,
.entity-analysis__label-parent span:not(.entity-analysis__expand) {
  max-width: 200px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.entity-analysis__expand {
  min-width: 1em;
  color: #9c9c9c;
  font-size: 85%;
  margin-right: 1em;
}
@media print {
  .company-report__container {
    border: 0 !important;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}
.company-report__info {
  border-top: 1px solid #dae1e7;
  border-bottom: 1px solid #dae1e7;
  padding: 0.5em 1em;
}
@media print {
  .company-report__info {
    border-color: #b8c2cc;
  }
}
.company-report__facilities {
  border-top: 1px solid #dae1e7;
  padding: 0.5em 1em;
}
@media print {
  .company-report__facilities {
    border-color: #b8c2cc;
  }
}
.company-report__info-part {
  max-width: 50%;
}
.company-report__info-company {
  border-radius: 4px;
  padding: 0.15em 0.2em;
  cursor: default;
}
.company-report__info-company:hover {
  background-color: #dae1e7;
}
@media print {
  .company-report__info-company {
    padding: 0;
  }
}
@media print {
  .company-report__info-company:nth-child(n+6),
  .company-report__info-company:nth-child(5) .company-report__info-company-separator {
    display: none;
  }
}
.company-report__info-company button {
  -ms-flex-item-align: center;
      align-self: center;
}
.corporate-company-report-pager__container {
  background-color: #dae1e7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.corporate-company-report-pager__selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0.5em;
  color: #3d4852;
}
.corporate-company-report-pager__selector:hover,
.corporate-company-report-pager__selector:focus {
  background-color: #8795a1;
  color: #fff;
}
.corporate-company-report-pager__selector:hover::after,
.corporate-company-report-pager__selector:focus::after {
  border-color: #8795a1 transparent transparent transparent;
}
.corporate-company-report-pager__selector--active {
  background-color: #606f7b;
  color: #fff;
  position: relative;
}
.corporate-company-report-pager__selector--active::after {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  border: 5px solid;
  border-color: #606f7b transparent transparent transparent;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 100%;
}
.company-report__info-company-separator {
  display: none;
}
@media print {
  .company-report__info-company-separator {
    display: inline;
  }
}
@media print {
  .company-report__info-company-toggle:after,
  .company-report__content-clickable-row:after {
    display: none;
  }
}
.company-report__info-company-name {
  font-size: 1.1em;
  font-weight: 400;
  margin-right: 0.2em;
}
p.company-report__info-company-name {
  margin-bottom: 0;
}
p.company-report__info-company-name + p {
  color: #606f7b;
  margin-bottom: 0;
}
.company-report__info-period {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
@media print {
  .company-report__info-period {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.company-report__info-period-value {
  font-size: 1.1em;
}
.company-report__part-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 3em;
}
.company-report__part-header h2 {
  line-height: 2em;
  margin: 0;
}
.hidden-print .company-report__part-header h2 {
  color: #b8c2cc;
}
.company-report__part-header .print-toggle {
  margin-right: 0.25rem;
}
.hidden-print .company-report__part-header .print-toggle {
  color: #b8c2cc;
}
.company-report__part-header > i:last-child {
  margin: 0 1em 0 auto;
}
.company-report__content {
  padding: 0 0.5em;
}
.company-report__part-header-explanation {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #8795a1;
  margin-left: 1em;
}
.hidden-print .company-report__part-header-explanation {
  color: #b8c2cc;
}
.company-report__graph-container {
  min-width: 310px;
  max-width: 100%;
  margin: 0 auto;
}
.company-report__graph-container:not([data-highcharts-chart]) {
  padding: 0 1em 1em;
}
.company-report__section {
  background-color: #f1f5f8;
  border-bottom: 1px solid #dae1e7;
  border-top: 1px solid #dae1e7;
  display: block;
  margin-bottom: 1em;
  padding: 0 0 0.5em;
  page-break-inside: avoid;
}
.company-report__section--graph {
  background-color: #f8fafc;
  padding: 0.5em 0;
}
@media print {
  .company-report__section {
    border-bottom: none;
  }
}
.company-report__section.hidden-print {
  padding: 0;
}
.company-report__section header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.company-report__section .highcharts-container,
.company-report__section .highcharts-root {
  width: 100% !important;
}
.company-report__print-footer {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media print {
  .company-report__print-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.company-report__toplist-name {
  display: inline-block;
  width: 24ch;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}
.page-break-after {
  page-break-after: always;
  page-break-inside: avoid;
}
.early-warning-trends-report__table-label {
  width: 80px;
}
.early-warning-trends-report__month-highlight {
  background-color: #E8E7E3;
}
.early-warning-trends-report__pickup-highlight {
  background-color: #D5D4D1;
}
.crm__hoverable {
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(61, 72, 82, 0.122);
          box-shadow: inset 0 -1px 0 0 rgba(61, 72, 82, 0.122);
  overflow: hidden;
  position: relative;
}
.crm__hoverable:hover {
  -webkit-box-shadow: inset 1px 0 0 #b8c2cc, inset -1px 0 0 #b8c2cc, 0 1px 2px 0 rgba(61, 72, 82, 0.3), 0 1px 3px 1px rgba(61, 72, 82, 0.15);
          box-shadow: inset 1px 0 0 #b8c2cc, inset -1px 0 0 #b8c2cc, 0 1px 2px 0 rgba(61, 72, 82, 0.3), 0 1px 3px 1px rgba(61, 72, 82, 0.15);
  z-index: 1;
  text-decoration: none;
}
.modal__crm {
  opacity: 0.2 !important;
}
.modal__crm .topbar,
.modal__crm .modal-footer {
  -webkit-transition: -webkit-box-shadow 250ms ease;
  transition: -webkit-box-shadow 250ms ease;
  transition: box-shadow 250ms ease;
  transition: box-shadow 250ms ease, -webkit-box-shadow 250ms ease;
}
@media screen and (max-width: 768px) {
  .modal__crm h1 {
    font-size: 20px;
  }
}
.modal__crm .modal-content {
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
}
.modal__crm legend {
  padding: 1.5rem;
  margin-bottom: 0;
}
.modal__crm fieldset > div {
  margin-bottom: 0;
  padding: 1.5rem;
}
.modal__crm fieldset > div:nth-of-type(even) {
  background-color: #f1f5f8;
}
.modal__crm textarea {
  resize: vertical;
}
.modal__crm [crm-modal-scroll-container] {
  max-height: calc(100vh - 126px);
  overflow-y: auto;
}
@media screen and (min-width: 768px) {
  .modal__crm [crm-modal-scroll-container] {
    max-height: calc(100vh - 200px);
  }
}
.modal__crm .scroll-shadow {
  position: relative;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.modal--hasFocus {
  opacity: 1 !important;
}
.modal--hasFocus .modal-content {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.modal-crm-grid__center-divider {
  grid-column: 2;
  grid-row: 1 / span 2;
  display: none;
}
@media screen and (min-width: 768px) {
  .modal-crm-grid__container {
    display: grid;
    grid-template-columns: minmax(275px, 1fr) auto minmax(275px, 1fr);
    grid-gap: 0.5em;
  }
  .modal-crm-grid__center-divider {
    display: block;
    border-right: 1px solid #e9e9e9;
  }
}
.modal-crm-grid__deal-basic {
  grid-column: 1;
  grid-row: 1;
}
.modal-crm-grid__deal-specifics {
  grid-column: 1;
  grid-row: 2;
}
.modal-crm-grid__deal-parties {
  grid-column: 3;
  grid-row: 1;
}
.modal-crm-grid__deal-notes {
  grid-column: 3;
  grid-row: 2;
}
.modal-crm-grid__meeting-basic {
  grid-column: 1;
  grid-row: 1;
}
.modal-crm-grid__meeting-parties {
  grid-column: 3;
  grid-row: 1;
}
.modal-crm-grid__meeting-notes {
  grid-column: 3;
  grid-row: 2;
}
.crm__meta-bar {
  height: 50px;
  position: fixed;
  top: 50px;
  background: #f1f5f8;
  border-bottom: 1px solid #dae1e7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  right: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 99;
}
.crm-search label {
  margin: 0;
  width: 278px;
}
@supports (display: flex) {
  .crm-search label {
    width: auto;
  }
}
.crm-search input {
  font-weight: normal;
  -webkit-transition: width 100ms ease-in-out;
  transition: width 100ms ease-in-out;
}
@supports (display: flex) {
  .crm-search input:placeholder-shown {
    width: 80px !important;
  }
}
.crm-search input:focus,
.crm-search input:not(:placeholder-shown) {
  width: 240px !important;
}
.crm-search__results-container {
  background: #fff;
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  padding: 0 2em 2em;
  border-bottom: 1px solid #b8c2cc;
}
.crm-search__results-content {
  max-width: 1337px;
  margin: 0 auto;
}
.crm-search__results-content hr {
  border-top-color: #dae1e7;
}
.crm-search__results-content ul {
  list-style: none;
  padding: 0;
}
.crm-search__results-content img {
  width: 35px;
  margin: 2px;
  border-radius: 50%;
}
.crm-search__results-part {
  -webkit-box-flex: 1;
      -ms-flex: 1 0;
          flex: 1 0;
  -ms-flex-preferred-size: calc(33.33% - 2em);
      flex-basis: calc(33.33% - 2em);
  padding: 0.5rem;
  position: relative;
}
@supports (display: flex) {
  .crm-search__results-part {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media screen and (min-width: 1337px) {
  .crm-search__results-part:not(:first-child):before {
    content: '';
    display: block;
    border-right: 1px solid #dae1e7;
    position: absolute;
    height: 100%;
    left: 0;
  }
}
@media screen and (max-width: 1337px) {
  .crm-search__results-part {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}
.crm-pipeline {
  background-color: #ffffff;
}
.crm-pipeline__pipes {
  gap: 1rem;
  min-height: 250px;
  display: grid;
  grid-template-columns: repeat(6, 1fr) 0.5fr;
  grid-gap: 1rem;
  max-width: 100vw;
  overflow-x: auto;
}
.crm__dashboard .crm-pipeline__pipes {
  gap: 0.5rem;
  min-height: 25px;
  grid-template-columns: repeat(9, 1fr);
}
.crm-pipeline__heading {
  background-color: #4a196f;
  color: #fff;
  text-align: center;
  padding: 0.5em;
  margin: 0;
  white-space: nowrap;
}
.crm__dashboard .crm-pipeline__heading {
  font-size: 13px;
  padding: 0.25em;
}
.crm-pipeline__pipe-subheading {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  background-color: #f1f5f8;
  padding: 0.2rem 0.5rem 0.1rem;
  border-bottom: none;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.crm-pipeline__pipe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 160px;
}
.crm-pipeline__pipe--small {
  min-width: 120px;
}
.crm-pipeline__pipe--small .crm-pipeline__pipe-content {
  overflow-x: hidden;
}
@media screen and (max-width: 40em) {
  .crm-pipeline__pipe {
    width: calc(35vw - 1.5rem);
  }
}
.crm-pipeline__pipe:nth-child(1) {
  grid-column: 1;
}
.crm-pipeline__pipe:nth-child(2) {
  grid-column: 2;
}
.crm-pipeline__pipe:nth-child(3) {
  grid-column: 3;
}
.crm-pipeline__pipe:nth-child(4) {
  grid-column: 4;
}
.crm-pipeline__pipe:nth-child(5) {
  grid-column: 5;
}
.crm-pipeline__pipe:nth-child(6) {
  grid-column: 6;
}
.crm-pipeline__pipe:nth-child(7) {
  grid-column: 7;
}
.crm__dashboard .crm-pipeline__pipe {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  min-width: 130px;
}
.crm-pipeline__pipe-total {
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 0.5rem 1rem;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  z-index: 1;
}
.crm-pipeline__pipe-total div {
  padding: 0.3em 0.4em;
}
.crm-pipeline__pipe-total div:not(:last-child) {
  border-color: #dae1e7;
}
.crm-pipeline__pipe-total div.bg-danger,
.crm-pipeline__pipe-total div.bg-warning {
  border-color: currentColor;
}
.crm-pipeline__pipe-total strong {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: inherit;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
.crm__dashboard .crm-pipeline__pipe-total {
  font-size: 1.2rem;
  padding: 0.25rem 0.25rem;
}
.crm-pipeline__pipe-content {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0.5rem 0.5rem 4rem;
  max-height: 60vh;
  overflow-y: auto;
}
.crm-pipeline__pipe-content--hovered {
  border-color: #606f7b;
  border-style: dashed;
  border-width: 2px;
  padding: calc(0.5rem - 1px);
}
.crm__dashboard .crm-pipeline__pipe-content {
  gap: 0;
  padding: 0;
}
dc-crm-pipeline-deal:not(:last-child) {
  margin-bottom: 0.5rem;
}
.crm-pipeline__deal {
  color: #555;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fff;
  padding-bottom: 0.25em;
  border: 1px solid #dae1e7;
  border-left-width: 3px;
  border-radius: 3px;
  position: relative;
}
.crm-pipeline__deal.crm-pipeline__deal--contract {
  border-left-color: #3fa3d8;
}
.crm-pipeline__deal--active {
  border-color: #606f7b;
}
.crm-pipeline__deal > * {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.gu-mirror .crm-pipeline__deal {
  cursor: -webkit-grab;
  cursor: grab;
}
.crm-pipeline__deal:hover,
.crm-pipeline__deal:active,
.crm-pipeline__deal:focus {
  border-color: #8795a1;
  text-decoration: none;
}
.crm__dashboard .crm-pipeline__deal {
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
}
.crm__dashboard .crm-pipeline__deal:hover {
  border-color: #dae1e7;
}
dc-crm-dashboard-pipeline-deal:last-child:not(:first-child) .crm-pipeline__deal {
  border-bottom: none;
}
.crm-pipeline__deal-title {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: inherit;
  text-transform: uppercase;
  letter-spacing: 0px;
  padding: 0.25em 0.5em;
  color: #3d4852;
  font-weight: 400;
  text-transform: none;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.crm-pipeline__deal-title .label {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1;
}
.pipeline-activities__activity .label {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1;
}
.crm-pipeline__deal-activity {
  padding: 0.25em;
}
.crm-pipeline__deal-peek {
  max-width: 100vw;
}
.crm-pipeline__deal-peek .popover-content {
  padding: 0;
}
.crm-pipeline__tip {
  padding: 0.5em;
}
.crm-pipeline__no-owner {
  position: relative;
  border: 2px solid #d9534f;
  border-radius: 30%;
}
.crm-pipeline__no-owner::after {
  content: '';
  display: block;
  position: absolute;
  height: 22px;
  width: 0;
  border-right: 2px solid #d9534f;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
  -webkit-box-shadow: 0 0 0 2px #f1f5f8;
          box-shadow: 0 0 0 2px #f1f5f8;
}
@media screen and (max-width: 1337px) {
  .crm__dashboard > div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .crm__dashboard > div > div {
    width: auto;
    margin-right: 0;
  }
  .crm__dashboard .crm-pipeline__pipe {
    max-width: none;
  }
}
/**
 * Calendar
 */
dc-crm-dashboard-calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
dc-crm-dashboard-calendar .topbar {
  position: relative;
}
dc-crm-dashboard-calendar.crm-dashboard-calendar--compact {
  min-width: 25%;
  margin-right: 1em;
}
dc-crm-dashboard-calendar.crm-dashboard-calendar--full {
  min-width: 100%;
}
.crm-dashboard-calendar {
  overflow: hidden;
}
.crm-dashboard-calendar__day-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 70px;
  padding-right: 1em;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.crm-dashboard-calendar__day-header > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  position: relative;
  padding: 0.25rem;
  min-width: 150px;
  border-bottom: 1px solid #e1e1e1;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0px;
}
.crm-dashboard-calendar__day-header > div::before {
  position: absolute;
  content: '';
  display: block;
  border-right: 1px solid #e1e1e1;
  height: 600px;
  max-height: 80vh;
  right: 100%;
}
.crm-dashboard-calendar__day-date {
  background: #dae1e7;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 3rem;
  width: 3rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1.5rem;
}
.crm-dashboard-calendar__day-date--active {
  background: #4a196f;
  color: #fff;
  font-weight: 600;
}
.crm-dashboard-calendar__content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 500px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.crm-dashboard-calendar__times {
  width: 70px;
}
.crm-dashboard-calendar__expand,
.crm-dashboard-calendar__expand:hover,
.crm-dashboard-calendar__expand:focus {
  position: absolute;
  top: 100%;
  right: 1em;
  z-index: 1;
  background: #f1f5f8;
  border: 1px solid #dae1e7;
  border-radius: 4px;
}
.crm-dashboard-calendar__expand:hover,
.crm-dashboard-calendar__expand:focus {
  border-color: #b8c2cc;
}
dc-crm-dashboard-calendar.crm-dashboard-calendar--full .crm-dashboard-calendar__expand {
  top: calc(100% + 3em);
}
.crm-dashboard-calendar .hour__container {
  position: relative;
  height: 48px;
  /*border-bottom: 1px solid #e1e1e1;*/
  border-right: 1px solid #e1e1e1;
  pointer-events: none;
}
.crm-dashboard-calendar .hour__container::after {
  position: absolute;
  top: -1px;
  left: 100%;
  content: '';
  display: block;
  border-bottom: 1px solid #e1e1e1;
  width: 1000vw;
}
.crm-dashboard-calendar .hour {
  position: relative;
  top: -1em;
}
.crm-dashboard-calendar__entry {
  background-color: #89badb;
  border: 1px solid currentColor;
  position: absolute;
  border-radius: 4px;
  /*width: 100%;*/
  left: 0.5rem;
  right: 0.5rem;
  padding: 0.25rem 0.5rem;
  line-height: 1.2;
  overflow: hidden;
  min-height: 24px;
}
.crm-dashboard-calendar__entry:hover {
  z-index: 1;
  left: 0 !important;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  height: auto !important;
  /*margin-top: -2px; !* If we want it to move a bit, we have to restructure the HTML a bit due to the overflow: hidden *! */
}
.crm-dashboard-calendar__entry:hover,
.crm-dashboard-calendar__entry:focus {
  text-decoration: none;
}
.crm-dashboard-calendar__now {
  position: absolute;
  border: 1px solid red;
  left: -0.25em;
  right: 0;
  z-index: 1;
  pointer-events: none;
}
.crm-dashboard-calendar__now::before,
.crm-dashboard-calendar__now::after {
  content: '';
  display: block;
  border: 6px solid;
  border-color: transparent transparent transparent red;
  height: 0;
  width: 0;
  position: absolute;
  right: 100%;
  top: 0;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.crm-dashboard-calendar__now::after {
  border-color: transparent red transparent transparent;
  left: 100%;
  right: auto;
  -webkit-transform: translate(-95%, -50%);
          transform: translate(-95%, -50%);
}
.crm-dashboard-calendar__date-header {
  position: fixed;
}
dc-crm-activities-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.crm-activities__list-actions {
  gap: 1rem;
}
.crm-activities__list-headings {
  border-bottom: 1px solid #dae1e7;
  font-weight: bold;
}
.crm-activities__list-item.crm-activities__list-item--selected {
  background-color: #a9d6ee;
  border-color: #87bbd7;
}
.crm-activities__list-item.crm-activities__list-item--overdue td:first-child {
  -webkit-box-shadow: inset 5px 0 0 0 #d9534f;
          box-shadow: inset 5px 0 0 0 #d9534f;
}
.crm-activities__list-item a.crm-activities__list-item-link {
  color: #555;
  position: relative;
}
.crm-activities__list-item a.crm-activities__list-item-link:hover,
.crm-activities__list-item a.crm-activities__list-item-link:focus {
  text-decoration: none;
  outline: none;
}
.crm-activities__list-item a.crm-activities__list-item-link:hover::before,
.crm-activities__list-item a.crm-activities__list-item-link:focus::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
  border-radius: 4px;
}
.crm-activities__list-item a.crm-activities__list-item-link:hover::before {
  -webkit-box-shadow: 0 0 0 1px #606f7b;
          box-shadow: 0 0 0 1px #606f7b;
}
.crm-activities__list-item a.crm-activities__list-item-link:focus::after {
  -webkit-box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
          box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
}
.crm-activities__list-item-contact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.crm-activities__list-item-contact > a:nth-child(1) {
  grid-column: 1;
}
.crm-activities__list-item-contact > a:nth-child(2) {
  grid-column: 2;
}
@media screen and (max-width: 1000px) {
  .crm-activities__list-item-variable-content {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0.25em;
  }
  .crm-activities__list-item-customer {
    margin-left: auto;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .crm-activities__list-item-customer > a {
    max-width: 200px;
  }
  .crm-activities__list-item-title {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
  .crm-activities__list-item-contact {
    margin-left: auto;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .crm-activities__list-item-variable-content {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .crm-activities__list-item-customer {
    margin-left: 0;
    margin-bottom: 0.25em;
    width: 100%;
  }
  .crm-activities__list-item-customer > a {
    max-width: 100%;
  }
  .crm-activities__list-item-title {
    width: 100%;
  }
  .crm-activities__list-item-contact {
    margin-left: 0;
    width: 100%;
  }
}
.customers__overview {
  margin-left: -0.5em;
  margin-right: -0.5em;
}
.customers__overview .dc-panel-body {
  max-width: calc(100vw - 1em);
  overflow-y: scroll;
}
.customers__overview .customers-detail__metric-number {
  font-size: 1.8em;
}
.dc-panel:first-child > .customers__overview .customers-detail__metric-number {
  font-size: 2.8em;
}
.customers-detail__metric:not(:last-child) {
  margin-bottom: 0.5em;
}
.customers-detail__metric-number {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 3em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin-right: 1ch;
}
.customers-detail__metric-diff {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.customers-detail-deals__statistics > *:nth-child(1) {
  grid-column: 1;
}
@media screen and (min-width: 1337px) {
  .customers-detail-deals__statistics {
    display: grid;
    grid-template-columns: 133.9375px 267.875px 401.8125px 267.875px;
    grid-gap: 0.5em;
  }
  .customers-detail-deals__statistics > *:nth-child(1) {
    grid-column: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .customers-detail-deals__statistics > *:nth-child(2) {
    grid-column: 2 / span 1;
  }
  .customers-detail-deals__statistics > *:nth-child(3) {
    grid-column: 3 / span 1;
  }
  .customers-detail-deals__statistics > *:nth-child(4) {
    grid-column: 4 / span 1;
  }
}
.customers-detail-deals__metrics,
.manage-detail-activities__metrics {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.customers-detail-deals__metrics > *,
.manage-detail-activities__metrics > * {
  -ms-flex-preferred-size: 45%;
      flex-basis: 45%;
}
.customers-detail-deals__metrics > *:nth-child(odd),
.manage-detail-activities__metrics > *:nth-child(odd) {
  margin-right: 0.5em;
}
@media screen and (min-width: 1337px) {
  .customers-detail-deals__metrics,
  .manage-detail-activities__metrics {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .customers-detail-deals__metrics > *,
  .manage-detail-activities__metrics > * {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
  .customers-detail-deals__metrics > *:nth-child(odd),
  .manage-detail-activities__metrics > *:nth-child(odd) {
    margin-right: 0;
  }
}
.customers-detail-activities__statistics {
  margin-bottom: 0.5em;
}
.customers-detail-activities__statistics > *:nth-child(1) {
  grid-column: 1;
}
@media screen and (min-width: 1337px) {
  .customers-detail-activities__statistics {
    display: grid;
    grid-template-columns: 133.9375px 267.875px 401.8125px 267.875px;
    grid-gap: 0.5em;
  }
  .customers-detail-activities__statistics > *:nth-child(1) {
    grid-column: 1 / span 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .customers-detail-activities__statistics > *:nth-child(2) {
    grid-column: 2 / span 3;
  }
}
dc-crm-customers-detail-deals .u--active-contract > td {
  border-top: 20px solid #3fa3d8 !important;
  border-bottom: 1px solid #3fa3d8 !important;
}
dc-crm-customers-detail-deals .u--active-contract > td:first-child {
  border-left: 3px solid #3fa3d8 !important;
}
dc-crm-customers-detail-deals .u--active-contract > td:last-child {
  border-right: 1px solid #3fa3d8 !important;
}
dc-crm-customers-detail-deals .u--active-contract.expanded {
  background-color: #54addc !important;
}
dc-crm-customers-detail-deals .u--active-contract .u--active-contract-content {
  position: relative;
}
dc-crm-customers-detail-deals .u--active-contract .u--active-contract-content > span {
  position: absolute;
  display: block;
  width: 200px;
  top: -2.4em;
  color: #fff;
  font-size: small;
}
dc-crm-customers-detail-deals .u--active-contract.expanded > td,
dc-crm-customers-detail-deals .u--active-contract + .child > td,
dc-crm-customers-detail-deals .u--active-contract.expanded > td:first-child,
dc-crm-customers-detail-deals .u--active-contract + .child > td:first-child,
dc-crm-customers-detail-deals .u--active-contract.expanded > td:last-child,
dc-crm-customers-detail-deals .u--active-contract + .child > td:last-child {
  border-color: #3fa3d8 !important;
}
.dc-entity-multi-select__content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 50%));
}
.dc-entity-multi-select__content ul {
  max-height: 75vh;
  overflow-y: auto;
}
.dc-entity-multi-select__all-or-none-message p {
  margin: 0.25em 0;
}
.dc_metric:not(:last-child) {
  margin-bottom: 0.5em;
}
.dc-metric__number {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 3em;
  font-weight: 400;
  margin-right: 1ch;
}
.dc-metric__comparison {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  margin-bottom: 0;
}
dc-metric[small] .dc-metric__number {
  font-size: 2em;
}
dc-metric[small] .dc-metric__comparison {
  font-size: 1em;
}
.coa-channel__cost-groups {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.coa-channel__cost-groups label {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-weight: normal;
}
.coa-channel__cost-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  margin-bottom: 2em;
  padding: 0.5em;
}
.coa-channel__cost-group h3 {
  margin-top: 0.5em;
  margin-left: 0.5em;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}
.coa-channel__cost-group table {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 40%;
          flex: 0 1 40%;
  width: 40%;
  margin-left: 2em;
  border: 1px solid #e9e9e9;
}
.coa-channel__cost-add {
  padding: 0.5em;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.coa-channel__cost-add .input-group-datepicker-sm {
  width: auto;
}
.coa-channel__cost-add .input-group-datepicker-sm .datepicker.input-sm {
  width: 100%;
}
.coa-channel__cost-add .btn-success {
  min-width: 20%;
  -ms-flex-item-align: end;
      align-self: flex-end;
  margin-bottom: 5px;
  margin-left: 0.5em;
}
.coa-channel__cost-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.coa-channel__cost-error {
  border-radius: 3px;
  color: #b52b27;
  padding: 4px 8px;
  width: 100%;
}
.coa-channel__cost-remove {
  text-align: right;
}
.coa-channel__cost-remove > button {
  opacity: 0;
  -webkit-transition: opacity 100ms ease-in-out;
  transition: opacity 100ms ease-in-out;
}
.coa-channel__cost-group table:hover .coa-channel__cost-remove button {
  opacity: 1;
}
.coa-channel__save {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  margin-top: 2em;
  text-align: right;
}
@-webkit-keyframes highlightRow {
  0% {
    background-color: transparent;
  }
  25% {
    background-color: #fae3c4;
  }
  100% {
    background-color: transparent;
  }
}
@keyframes highlightRow {
  0% {
    background-color: transparent;
  }
  25% {
    background-color: #fae3c4;
  }
  100% {
    background-color: transparent;
  }
}
.highlight-row--added td {
  -webkit-animation-name: highlightRow;
          animation-name: highlightRow;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
.highlight-row--active td {
  position: relative;
}
.highlight-row--active td:first-child::before {
  content: "active";
  position: absolute;
  -webkit-transform: rotate(-90deg) translateY(-100%);
          transform: rotate(-90deg) translateY(-100%);
  left: -8px;
  font-size: 0.6em;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 0 4px;
  display: block;
  background: #5bc0de;
  color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: -1px 1px 0 rgba(0, 0, 0, 0.1);
          box-shadow: -1px 1px 0 rgba(0, 0, 0, 0.1);
  top: 33%;
  font-weight: 400;
  line-height: 1.8;
}
.coa-channel__cost-remove {
  max-width: 50px;
}
.coa-channel__fixed-costs-label {
  width: 220px;
}
.table-fixed {
  table-layout: fixed;
}
.testingtesting {
  -webkit-transition: all 400ms ease-in;
  transition: all 400ms ease-in;
}
.agent-config {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.5em;
  background-color: #dae1e7;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.agent-config__item-label {
  display: block;
  margin: 0;
  padding: 1em;
}
.chooseRole {
  margin: 0 auto;
  max-width: 500px;
  padding-bottom: 1em;
}
.chooseRole ul + .topbar-small {
  margin-top: 1.5em;
}
.chooseRole__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.chooseRole__list .chooseRole__list {
  border: none;
}
.chooseRole__list .chooseRole__list .chooseRole__facility::before,
.chooseRole__list .chooseRole__list .chooseRole__chain::before {
  content: '';
  display: block;
  height: 37px;
  width: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  top: -10px;
  left: 2em;
}
.chooseRole__list .chooseRole__list .chooseRole__facility:not(:last-child)::after,
.chooseRole__list .chooseRole__list .chooseRole__chain:not(:last-child)::after {
  content: '';
  display: block;
  height: 17px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  top: 27px;
  left: 2em;
  position: absolute;
}
.chooseRole__facility,
.chooseRole__chain {
  position: relative;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.chooseRole__facility .header-facility__initials,
.chooseRole__chain .header-facility__initials {
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.chooseRole__facility a,
.chooseRole__chain a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  line-height: 50px;
}
.chooseRole__facility a:hover,
.chooseRole__chain a:hover,
.chooseRole__facility a:focus,
.chooseRole__chain a:focus {
  text-decoration: none;
}
.chooseRole__facility:hover,
.chooseRole__chain:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-color: #ddd;
}
.chooseRole__list--chains .chooseRole__facility {
  padding-left: 3em;
}
.chooseRole__list--facilities .chooseRole__facility {
  padding-left: 1em;
}
.chooseRole__list--master .chooseRole__facility {
  padding-left: 1em;
}
.board-report__table {
  background-color: #fff;
  border: 1px dashed #999;
  font-size: 11px;
  text-align: right;
  max-width: 1600px;
  width: 100%;
  font-variant-numeric: tabular-nums slashed-zero;
  letter-spacing: -0.02em;
}
.board-report__table.board-report__table--comfortable {
  font-size: 1.2rem;
}
.board-report__table.board-report__table--comfortable table {
  line-height: 1.4;
}
@media print {
  .board-report__table {
    width: 100vw;
  }
}
.dc-panel .board-report__table {
  width: 100%;
}
.board-report__table > tbody > tr > td {
  padding: 5px;
}
.board-report__table > tbody > tr > td.board-report__header {
  padding: 0;
}
.board-report__table table {
  background: transparent;
  width: 100%;
  line-height: 1.3;
}
.board-report__table h3 {
  color: #000;
  margin: 0;
}
.board-report__table th,
.board-report__table td {
  padding: 1px 4px;
  vertical-align: top;
  white-space: nowrap;
}
.board-report__table th {
  border-bottom: 1px solid #444;
  font-weight: normal;
  text-align: right;
}
.board-report__row-separator td {
  border-bottom: 1px solid #444;
}
.board-report__row-padding--bottom td,
.board-report__row-padding--bottom th {
  padding-bottom: 2px;
}
.board-report__row-padding--top td,
.board-report__row-padding--top th {
  padding-top: 2px;
}
.board-report__table .u-border--none {
  border: 0 !important;
}
.board-report__table .u-empty {
  border: 0;
  padding: 0;
}
.board-report__section {
  padding: 5px;
}
.board-report__section table {
  border-top: 3px solid #000;
}
h3.board-report__title {
  margin: 5px auto;
}
h3.board-report__section-heading {
  margin: 3px 0;
}
.board-report__separator {
  height: 5px;
  width: 5px;
}
/*	.board-report__table tr:last-child .board-report__part {
	border: 0;
}*/
.board-report__comment {
  font-size: 13px;
  height: 125px;
  width: 100%;
  resize: none;
  padding: 0.5em;
  border: 0;
  white-space: normal;
  margin-bottom: 0;
  overflow-y: auto;
}
@media print {
  .board-report__comment {
    padding: 0;
    overflow-y: hidden;
  }
}
.dashboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.dashboard .dc-table {
  font-size: 0.9em;
}
.dashboard .highcharts-tooltip .dc-table {
  font-size: 1em;
}
.dashboard__track {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
}
.dashboard__track:first-child {
  margin-right: 1em;
}
.dashboard__section {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.dashboard__content-row--alternate {
  background-color: #f4f4f4;
}
.dashboard__market-index-value {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-size: 1.5em;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.dashboard__calendar {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 105px;
  width: 100%;
}
.dashboard__calendar-day {
  font-size: 10px;
  height: 10px;
  margin-bottom: 1px;
  width: calc(100% / 7 - 1px);
}
.dashboard__calendar-day:not(:last-child) {
  margin-right: 1px;
}
.dashboard__calendar-day:not(.dashboard__calendar-day--header):not(.dashboard__calendar-day--):hover,
.dashboard__calendar-day.dashboard__calendar-day--no-data:hover {
  outline: 1px solid #888;
}
.dashboard__calendar-day--header {
  color: #777;
  height: 14px;
  text-align: center;
}
.dashboard__calendar-day--good {
  background-color: #89d789;
}
.dashboard__calendar-day--bad {
  background-color: #ed8a87;
}
.dashboard__calendar-day--neutral {
  background-color: #c4c4c4;
}
.dashboard__calendar-day--no-data {
  background: repeating-linear-gradient(-45deg, #ddd, #ddd 3px, #bbb 3px, #bbb 6px);
}
dc-dashboard-years-bar-chart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
dc-dashboard-years-bar-chart:not([vertical]) {
  height: 90px;
  width: 180px;
}
dc-dashboard-years-bar-chart[vertical] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.dashboard__barchart-track {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 100%;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
dc-dashboard-years-bar-chart:not([vertical]) .dashboard__barchart-track {
  background: -webkit-gradient(linear, left top, right top, from(#f2f2f2), to(#f2f2f2));
  background: linear-gradient(to right, #f2f2f2, #f2f2f2);
  background-size: 5px 2000px;
  background-repeat: no-repeat;
  background-position: 50% 4px;
  padding-top: 2em;
}
[vertical] .dashboard__barchart-track {
  background: -webkit-gradient(linear, left top, right top, from(#f2f2f2), to(#f2f2f2));
  background: linear-gradient(to right, #f2f2f2, #f2f2f2);
  background-size: 2px 5px;
  background-repeat: repeat-x;
  background-position: 0 50%;
  padding-right: 20%;
  margin-bottom: 2px;
  height: 20px;
}
.dashboard__barchart-track:not(:last-child) {
  margin-right: 2px;
}
.dashboard__barchart-bar {
  background-color: #e6e6e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: bold;
  color: #777;
  border-top: 2px solid #fff;
  padding-top: 4px;
  font-weight: 400;
  width: 100%;
}
[vertical] .dashboard__barchart-bar {
  border-top: 0;
  border-right: 2px solid #fff;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-top: 0;
  padding-right: 6px;
}
.dashboard__barchart-track--active .dashboard__barchart-bar {
  background-color: #3fa3d8;
  color: #fff;
  min-width: 60px;
}
.dashboard__barchart-track--future .dashboard__barchart-bar {
  background-color: #1a5fa1;
  color: #fff;
}
[show-header] .dashboard__barchart-bar::before {
  content: attr(data-year);
  background-color: #fbfbfb;
  color: #777;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}
[vertical][show-header] .dashboard__barchart-bar::before {
  left: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding-left: 4px;
}
dc-dashboard-on-the-books-graph {
  width: 450px;
}
.dashboard__kpi-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.dashboard__yesterday-kpi {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.7em;
  font-weight: 300;
  padding: 0.3em;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
}
.dashboard__yesterday-kpi small {
  font-size: 65%;
  font-weight: 400;
  margin-left: 0.2em;
}
.dashboard__box {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}
.dashboard__box h3,
.dashboard__box h4 {
  margin: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dashboard-room-forecast__table {
  height: 400px;
  width: auto;
}
.dashboard-room-forecast__table th {
  height: 46px;
}
.dashboard-room-forecast__fake-th {
  font-size: 12.6px;
  margin-top: 23px;
  margin-bottom: 0;
  padding-left: 1em;
}
.dc-panel {
  background: #fbfbfb;
  border: 1px solid #e6e6e6;
  border-bottom-color: #ccc;
  border-right-color: #ccc;
  border-radius: 3px;
  -webkit-box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
          box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
}
.dc-panel .nav-tabs {
  border-bottom: 0;
}
.nav-tabs + .dc-panel,
.nav-tabs + [ui-view] > .dc-panel {
  margin-top: -1px;
}
.dc-panel .dc-panel {
  background-color: #fefefe;
  border-top: 1px solid;
  border-color: #e6e6e6;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.dc-panel-body {
  padding: 0.5em;
}
.dc-panel-body:before,
.dc-panel-body:after {
  content: " ";
  display: table;
}
.dc-panel-body:after {
  clear: both;
}
.dc-panel-body > *:last-child {
  margin-bottom: 0;
}
.dc-panel-body.no-padding > table {
  border-radius: 3px;
}
@media print {
  .dc-panel.border-hidden-print {
    border: 0;
  }
}
.topbar {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 1em;
  padding-bottom: 0.5em;
}
.topbar > :not(.topbar-buttons) {
  margin-right: 1rem;
}
.topbar.topbar-centered {
  text-align: center;
}
.topbar h1,
.topbar h2,
.topbar h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 2.5;
}
.topbar-buttons {
  margin-left: auto;
  -ms-flex-item-align: center;
      align-self: center;
}
.topbar-buttons .topbar-buttons-divider {
  width: 1px;
  height: 100%;
  background-color: #BDBCB9;
}
.topbar-buttons-group {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 10px;
}
.topbar .dropdown,
.dropdown.styled {
  -webkit-box-shadow: inset 0 -3px 0 #dae1e7;
          box-shadow: inset 0 -3px 0 #dae1e7;
  -webkit-transition: -webkit-box-shadow 150ms;
  transition: -webkit-box-shadow 150ms;
  transition: box-shadow 150ms;
  transition: box-shadow 150ms, -webkit-box-shadow 150ms;
  white-space: nowrap;
}
.topbar .dropdown-menu,
.dropdown.styled .dropdown-menu {
  text-transform: initial;
  letter-spacing: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 0;
  border-left: 3px solid #4a196f;
}
.topbar .dropdown-menu.dropdown-columned,
.dropdown.styled .dropdown-menu.dropdown-columned {
  -webkit-columns: 2;
     -moz-columns: 2;
          columns: 2;
}
.topbar .dropdown.open,
.dropdown.styled.open {
  -webkit-box-shadow: inset 0 -3px 0 #4a196f;
          box-shadow: inset 0 -3px 0 #4a196f;
}
.topbar .dropdown:hover,
.topbar .dropdown:focus,
.dropdown.styled:hover,
.dropdown.styled:focus {
  -webkit-box-shadow: inset 0 -40px 0 #4a196f;
          box-shadow: inset 0 -40px 0 #4a196f;
  text-decoration: none;
}
.topbar .dropdown:hover .dropdown-toggle,
.dropdown.styled:hover .dropdown-toggle {
  color: #fff;
}
.topbar .dropdown-toggle,
.dropdown.styled .dropdown-toggle {
  display: inline-block;
  padding: 0.25em 0.5em;
  -webkit-transition: color 150ms;
  transition: color 150ms;
}
.topbar a:hover,
.topbar a:focus,
.dropdown.styled a:hover,
.dropdown.styled a:focus {
  text-decoration: none;
}
.topbar a:focus,
.dropdown.styled a:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
          box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
  border-radius: 4px;
}
:root {
  font-size: 62.5%;
}
body {
  background-color: #f1f1f1;
  padding-top: 50px;
  font-family: Inter, sans-serif;
  -webkit-font-feature-settings: 'liga' 1, 'calt' 1;
          font-feature-settings: 'liga' 1, 'calt' 1;
  /* fix for Chrome */
  -webkit-font-feature-settings: 'liga' 1, 'calt' 1, 'ss01' 1;
          font-feature-settings: 'liga' 1, 'calt' 1, 'ss01' 1;
  font-variant-numeric: slashed-zero;
  font-size: 1.4rem;
  color: #22292f;
}
@supports (font-variation-settings: normal) {
  body {
    font-family: InterVariable, sans-serif;
  }
}
@supports (font-variation-settings: normal) {
  body {
    font-family: InterVariable, sans-serif;
  }
}
body .highcharts-tooltip > *,
body .highcharts-tooltip-container > * {
  font-family: Inter, sans-serif !important;
  font-variant-numeric: tabular-nums slashed-zero;
}
@supports (font-variation-settings: normal) {
  body .highcharts-tooltip > *,
  body .highcharts-tooltip-container > * {
    font-family: InterVariable, sans-serif !important;
  }
}
@media print {
  body {
    padding: 0;
  }
}
#content-container {
  margin: 0 160px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-font-smoothing: antialiased;
}
h1 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: #4a196f;
  line-height: 24px;
}
h2 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #4a196f;
  line-height: 18px;
  margin: 20px 0 10px 0;
}
h3 {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #3D3B3B;
}
h3.brand {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0px;
}
h3.brand[no-caps] {
  text-transform: initial;
}
h4 {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #3D3B3B;
}
h4.brand {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0px;
}
h4.brand[no-caps] {
  text-transform: initial;
}
input:not([formly-custom-validation], .form-control).ng-invalid.ng-dirty,
select:not([formly-custom-validation], .form-control).ng-invalid.ng-dirty.ng-invalid.ng-dirty,
textarea:not([formly-custom-validation], .form-control).ng-invalid.ng-dirty.ng-invalid.ng-dirty {
  background-color: #FFC7D4;
}
.p {
  margin-bottom: 10px;
}
.p2 {
  margin-bottom: 22px;
}
.no-padding {
  padding: 0px !important;
}
.strong {
  font-weight: bold;
}
.margin-btm-md {
  margin-bottom: 1em;
}
.nl2br {
  white-space: pre-line;
}
.print-only {
  display: none;
}
@media print {
  .print-only {
    display: initial;
  }
}
.bg-separator {
  height: 100%;
  width: 2px;
  background: #fff url('/img/header/bg-separator-light.png') repeat-y;
}
#body,
footer {
  margin: 2em 0.5em 0;
  min-width: calc(100vw - 1em);
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  max-width: calc(100vw - 2em);
}
@media screen and (min-width: 1337px) {
  #body,
  footer {
    min-width: 1337px;
    margin: 2em auto;
  }
  #body > aside > div:not(:empty),
  footer > aside > div:not(:empty) {
    margin-right: 1em;
  }
}
body.section_ #body,
body.section_ footer,
body.section_crm #body,
body.section_crm footer {
  width: auto;
  max-width: 100vw;
}
@media screen and (min-width: 1337px) {
  body.section_ #body,
  body.section_ footer,
  body.section_crm #body,
  body.section_crm footer {
    margin-top: 5em;
    width: 1337px;
  }
}
#header ~ #body,
#header ~ footer {
  margin-left: 75px;
}
@media print {
  #header ~ #body,
  #header ~ footer {
    margin: 0;
  }
}
@media print {
  #body {
    margin: 0!important;
    max-width: 100%;
  }
}
footer {
  border-top: 1px solid #D5d4d1;
  color: #828180;
  font: 'Arial';
  font-size: 12px;
  font-weight: normal;
  padding: 10px;
  text-align: center;
  margin-top: 50px;
}
@media print {
  footer {
    display: none;
  }
}
.dc-fa-circled {
  display: inline-block;
  border: 1px solid;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  line-height: 2em;
}
.dc-fa-circled > .fa {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: inherit;
}
.dc-fa-circled > .fa-close {
  margin-top: -1px;
}
.dc-fa-circled.dc-fa-circled-sm {
  width: 24px;
  height: 24px;
  font-size: 0.87em;
}
.dc-fa-circled.dc-fa-circled-md {
  width: 28px;
  height: 28px;
}
.dc-fa-circled.dc-fa-circled-default {
  border-color: #B4B3B0;
  background-color: #E8E7E2;
}
.dc-fa-circled.dc-fa-circled-default:hover,
.dc-fa-circled.dc-fa-circled-default:focus,
.dc-fa-circled.dc-fa-circled-default:active {
  background-color: #D4D3D1;
}
.dc-fa-circled.dc-fa-circled-default > .fa {
  color: #828180;
}
.dc-fa-circled.dc-fa-circled-info {
  border-color: rgba(120, 90, 193, 0.35);
  background-color: #EAEAEA;
}
.dc-fa-circled.dc-fa-circled-info:hover,
.dc-fa-circled.dc-fa-circled-info:focus,
.dc-fa-circled.dc-fa-circled-info:active {
  background-color: rgba(120, 90, 193, 0.21);
}
.dc-fa-circled.dc-fa-circled-info > .fa {
  color: #785AC1;
}
.table {
  border: 1px solid #e6e6e6;
}
.nav-tabs + .table {
  border-top: 0;
}
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 1px solid #ececec;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th {
  padding-top: 4px;
  padding-bottom: 4px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FAFAFA), to(#EEEEEE));
  background-image: linear-gradient(to bottom, #FAFAFA 0%, #EEEEEE 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffafafa', endColorstr='#ffeeeeee', GradientType=0);
}
.table > thead > tr > th {
  border-bottom: 1px solid #DDDEDC;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.btn-secondary {
  color: #4a196f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FAF9F7), to(#E7E7E5));
  background-image: linear-gradient(to bottom, #FAF9F7 0%, #E7E7E5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffaf9f7', endColorstr='#ffe7e7e5', GradientType=0);
  border: 1px solid #D9D9D9;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  color: #4a196f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#F5F4F0));
  background-image: linear-gradient(to bottom, #FFFFFF 0%, #F5F4F0 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff5f4f0', GradientType=0);
  -webkit-box-shadow: 1px 1px 1px 0 #EFEEE9 inset;
          box-shadow: 1px 1px 1px 0 #EFEEE9 inset;
  border-color: #DFDEDC;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-text {
  color: #4a196f;
  border-radius: 0;
}
.btn-text:hover,
.btn-text:focus,
.btn-text:active {
  color: #520166;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-text:after {
  position: relative;
  top: -1px;
  left: 5px;
  font: normal normal normal 70% FontAwesome;
  content: "\f054";
}
.btn-primary.btn-lg,
.btn-secondary.btn-lg,
.btn-text.btn-lg {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0px;
  height: 52px;
  border-radius: 26px;
  padding-left: 39px;
  padding-right: 39px;
}
.btn-primary.btn-md,
.btn-secondary.btn-md,
.btn-text.btn-md {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0px;
  height: 41px;
  border-radius: 26px;
  padding-left: 29px;
  padding-right: 29px;
}
.btn-primary.btn-sm,
.btn-secondary.btn-sm,
.btn-text.btn-sm {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0px;
  height: 33px;
  padding-left: 19px;
  padding-right: 19px;
}
.btn-primarya.btn-md,
.btn-secondarya.btn-md,
.btn-texta.btn-md {
  padding-top: 8px;
}
/*
.btn-fwd {
  border-radius: 0;
  border: none;
  box-shadow: none;

  &.btn-default {

    &.btn-xl {
      .fwd-background(default, xl);
      height: 61px;
      padding-right: 48px;

      &:hover,
      &:focus {
        .fwd-background-hover(default, xl);
        box-shadow: none;
      }
    }

    &.btn-lg {
      .fwd-background(default, lg);
      height: 51px;
      padding-right: 35px;
      padding-left: 25px;

      &:hover,
      &:focus {
        .fwd-background-hover(default, lg);
        box-shadow: none;
      }
    }

    &.btn-md {
      .fwd-background(default, md);
      height: 41px; // is this one necessary?
      padding-right: 30px;
      padding-left: 23px;

      &:hover,
      &:focus {
        .fwd-background-hover(default, md);
        box-shadow: none;
      }
    }
  }

  &.btn-secondary {

    &.btn-md {
      .fwd-background(secondary, md);
      height: 41px; // is this one necessary?
      padding-right: 30px;
      padding-left: 23px;

      &:hover,
      &:focus {
        .fwd-background-hover(secondary, md);
        box-shadow: none;
      }
    }
  }

  .fwd-background(@theme, @size) {
    background-image: url('/img/btn-fwd/@{theme}-@{size}-left.png'), url('/img/btn-fwd/@{theme}-@{size}-right.png'), url('/img/btn-fwd/@{theme}-@{size}-mid.png');
    background-repeat: no-repeat, no-repeat, repeat-x;
    background-position: left top, right top, left top;
  }

  .fwd-background-hover(@theme, @size) {
    background-image: url('/img/btn-fwd/@{theme}-@{size}-left-hover.png'), url('/img/btn-fwd/@{theme}-@{size}-right-hover.png'), url('/img/btn-fwd/@{theme}-@{size}-mid-hover.png');
    background-repeat: no-repeat, no-repeat, repeat-x;
    background-position: left top, right top, left top;
  }
}
*/
.dc-filter-inline .form-group {
  float: left;
}
.dc-filter-inline .form-group:not(:first-child) {
  margin-left: 20px;
}
.autocomplete {
  position: relative;
}
.autocomplete input:not(.form-control) {
  z-index: 1;
  height: 30px;
}
.autocomplete input:not(.form-control)[typeahead-editable="false"].ng-invalid-editable {
  background-color: #ffffff;
}
.autocomplete .fa-spin {
  position: absolute;
  z-index: 2;
}
.autocomplete.autocomplete-sm .fa-spin {
  right: 9px;
  top: 9px;
}
.autocomplete.autocomplete-md .fa-spin {
  right: 10px;
  top: 11px;
}
.autocomplete.autocomplete-lg .fa-spin {
  right: 15px;
  top: 17px;
}
.modal .modal-header {
  height: 60px;
}
.modal .modal-header:before,
.modal .modal-header:after {
  content: " ";
  display: table;
}
.modal .modal-header:after {
  clear: both;
}
.modal .modal-header h1,
.modal .modal-header h2 {
  float: left;
}
.modal .modal-header .modal-close {
  display: inline-block;
  border: 1px solid;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  line-height: 2em;
  border-color: #B4B3B0;
  background-color: #E8E7E2;
  width: 28px;
  height: 28px;
  float: right;
  margin-right: 5px;
}
.modal .modal-header .modal-close > .fa {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: inherit;
}
.modal .modal-header .modal-close > .fa-close {
  margin-top: -1px;
}
.modal .modal-header .modal-close.dc-fa-circled-sm {
  width: 24px;
  height: 24px;
  font-size: 0.87em;
}
.modal .modal-header .modal-close.dc-fa-circled-md {
  width: 28px;
  height: 28px;
}
.modal .modal-header .modal-close.dc-fa-circled-default {
  border-color: #B4B3B0;
  background-color: #E8E7E2;
}
.modal .modal-header .modal-close.dc-fa-circled-default:hover,
.modal .modal-header .modal-close.dc-fa-circled-default:focus,
.modal .modal-header .modal-close.dc-fa-circled-default:active {
  background-color: #D4D3D1;
}
.modal .modal-header .modal-close.dc-fa-circled-default > .fa {
  color: #828180;
}
.modal .modal-header .modal-close.dc-fa-circled-info {
  border-color: rgba(120, 90, 193, 0.35);
  background-color: #EAEAEA;
}
.modal .modal-header .modal-close.dc-fa-circled-info:hover,
.modal .modal-header .modal-close.dc-fa-circled-info:focus,
.modal .modal-header .modal-close.dc-fa-circled-info:active {
  background-color: rgba(120, 90, 193, 0.21);
}
.modal .modal-header .modal-close.dc-fa-circled-info > .fa {
  color: #785AC1;
}
.modal .modal-header .modal-close:hover,
.modal .modal-header .modal-close:focus,
.modal .modal-header .modal-close:active {
  background-color: #D4D3D1;
}
.modal .modal-header .modal-close > .fa {
  color: #828180;
}
.modal .modal-header .modal-close > .fa {
  margin-top: -1px;
}
.modal .modal-body:first-child {
  margin-top: 6px;
}
.modal-info .modal-header {
  background-color: #4a196f;
  border-bottom: none;
}
.modal-info .modal-header h1 {
  margin: 0;
  color: white;
  height: 29px;
  line-height: 1.2em;
}
.modal-info .modal-header .modal-close {
  border-color: #B4B3B0;
  background-color: #E8E7E2;
}
.modal-info .modal-header .modal-close:hover,
.modal-info .modal-header .modal-close:focus,
.modal-info .modal-header .modal-close:active {
  background-color: #D4D3D1;
}
.modal-info .modal-header .modal-close > .fa {
  color: #828180;
}
.modal-info .modal-body h1,
.modal-info .modal-body h2,
.modal-info .modal-body h3,
.modal-info .modal-body h4,
.modal-info .modal-body h5,
.modal-info .modal-body h6 {
  color: #785AC1;
}
.modal-generic .modal-header {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FAFAFA), to(#EEEEEE));
  background-image: linear-gradient(to bottom, #FAFAFA 0%, #EEEEEE 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffafafa', endColorstr='#ffeeeeee', GradientType=0);
  border-bottom: 1px solid #DAD8D9;
}
.modal-generic .modal-header h1 {
  margin: 0;
  height: 29px;
  line-height: 1.2em;
}
.modal-dialog-news .modal-dialog {
  max-width: 530px;
}
.modal-dialog-news .modal-dialog .modal-body h1:first-child,
.modal-dialog-news .modal-dialog .modal-body h2:first-child,
.modal-dialog-news .modal-dialog .modal-body h3:first-child,
.modal-dialog-news .modal-dialog .modal-body h4:first-child,
.modal-dialog-news .modal-dialog .modal-body h5:first-child {
  margin-top: 0.5em;
}
.modal-dialog-news .modal-dialog .modal-body img {
  max-width: 100%;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -15px);
          transform: translate(0, -15px);
  -webkit-transition: -webkit-transform 150ms ease-out;
  transition: -webkit-transform 150ms ease-out;
  transition: transform 150ms ease-out;
  transition: transform 150ms ease-out, -webkit-transform 150ms ease-out;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
@media screen and (max-width: 768px) {
  .modal-dialog {
    margin: 0;
  }
}
.modal--visually-centered.fade .modal-dialog {
  position: absolute;
  top: 25%;
  left: 50%;
  -webkit-transform: translate(-50%, -15px);
          transform: translate(-50%, -15px);
  -webkit-transition: -webkit-transform 150ms ease-out;
  transition: -webkit-transform 150ms ease-out;
  transition: transform 150ms ease-out;
  transition: transform 150ms ease-out, -webkit-transform 150ms ease-out;
}
.modal--visually-centered.in .modal-dialog {
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  /* added line */
}
.popover-content h1,
.popover-content h2,
.popover-content h3,
.popover-content h4,
.popover-content h5,
.popover-content h6 {
  color: #785AC1;
}
.popover-content h1:first-child,
.popover-content h2:first-child,
.popover-content h3:first-child,
.popover-content h4:first-child,
.popover-content h5:first-child,
.popover-content h6:first-child {
  margin-top: 6px;
}
.popover-content > *:last-child {
  margin-bottom: 0;
}
.input-lg,
.input-md,
input {
  font-size: 14px;
}
.form-control {
  border-radius: 4px;
}
.form-control:focus,
.form-control.form-control--focused {
  -webkit-box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
          box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
  border-radius: 4px;
}
.form-control:not(input).form-control--focused,
.form-control:not(select).form-control--focused,
.form-control:not(textarea).form-control--focused {
  border-color: #4a196f;
}
/*-------------------------------*/
.daily-btns .disabled {
  opacity: 1;
  filter: alpha(opacity=100);
}
.daily-btns .btn {
  margin-right: 10px;
}
/*-------------------------------*/
input.error-glowing-border {
  border: 2px solid red;
  border-radius: 7px;
}
/**** Calendar ****/
.calendar {
  width: 225px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 1px 1px 5px 0 #B2B2B2;
  box-shadow: 1px 1px 5px 0 #B2B2B2;
  border: 1px solid #E0E0E0;
}
.calendar table {
  border-collapse: separate;
  border-spacing: 2px;
  font-size: 12px;
}
.calendar-heading {
  text-align: center;
  height: 37px;
  color: white;
  font-weight: bold;
  background-color: #4a196f;
  padding-top: 8px;
}
.calendar-heading .prev {
  margin-left: 18px;
}
.calendar-heading .prev a {
  color: white;
}
.calendar-heading .next {
  margin-right: 18px;
}
.calendar-heading .next a {
  color: white;
}
.calendar-body {
  padding: 9px;
}
.calendar-body th {
  font-weight: bold;
  text-align: center;
}
.calendar-days {
  text-align: right;
}
.calendar-days td {
  width: 27px;
  height: 26px;
  padding-right: 6px;
  border: 1px solid #DDDDDD;
  border-radius: 3px;
}
.calendar-days td:hover,
.calendar-days td:focus {
  cursor: pointer;
}
.calendar-days td.no-day {
  border: none;
}
.calendar-days td.green {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#E8F6DF), to(#C3E4AB));
  background-image: linear-gradient(to bottom, #E8F6DF 0%, #C3E4AB 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe8f6df', endColorstr='#ffc3e4ab', GradientType=0);
}
.calendar-days td.green:hover,
.calendar-days td.green:focus {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#dff0d2));
  background-image: linear-gradient(to bottom, #ffffff 0%, #dff0d2 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffdff0d2', GradientType=0);
}
.calendar-days td.red {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#EA4444), to(#B41819));
  background-image: linear-gradient(to bottom, #EA4444 0%, #B41819 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffea4444', endColorstr='#ffb41819', GradientType=0);
  border: none;
  color: white;
}
.calendar-days td.red:hover,
.calendar-days td.red:focus {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ef7272), to(#e11e1f));
  background-image: linear-gradient(to bottom, #ef7272 0%, #e11e1f 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffef7272', endColorstr='#ffe11e1f', GradientType=0);
}
.calendar-days td.gray {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#F7F7F7), to(#E2E2E2));
  background-image: linear-gradient(to bottom, #F7F7F7 0%, #E2E2E2 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff7f7f7', endColorstr='#ffe2e2e2', GradientType=0);
}
.calendar-days td.gray:hover,
.calendar-days td.gray:focus {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#fbfbfb));
  background-image: linear-gradient(to bottom, #ffffff 0%, #fbfbfb 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fffbfbfb', GradientType=0);
}
.calendar-days td.opaque {
  color: #7A7A7A;
  border-color: #F3F3F3;
  background-color: #F7F7F7;
}
.calendar-days.calSelectedRow td {
  -webkit-box-shadow: inset 1px 1px 1px gray;
          box-shadow: inset 1px 1px 1px gray;
  background-color: white;
  color: black;
  background-image: none;
}
.calendar-days.calSelectedRow td:hover,
.calendar-days.calSelectedRow td:focus {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#fbfbfb));
  background-image: linear-gradient(to bottom, #ffffff 0%, #fbfbfb 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fffbfbfb', GradientType=0);
}
.dly-br-cal-container {
  width: 923px;
  margin: 0 auto;
}
.dly-br-cal {
  width: 923px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 1px 1px 5px 0 #B2B2B2;
  box-shadow: 1px 1px 5px 0 #B2B2B2;
  border: 1px solid #d0d0d0;
  color: #3F3F3D;
}
.dly-br-cal-header {
  text-align: center;
  height: 70px;
  color: white;
  font-weight: bold;
  background-color: #4a196f;
}
.dly-br-cal-header .header {
  font-size: 20px;
  margin-top: 20px;
  display: inline-block;
}
.dly-br-cal-header .prev,
.dly-br-cal-header .next {
  width: 78px;
  height: 70px;
  background-image: url('/img/cal-prev.png');
  cursor: pointer;
}
.dly-br-cal-header .prev:hover,
.dly-br-cal-header .next:hover {
  background-image: url('/img/cal-prev-hover.png');
}
.dly-br-cal-header .next {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-filter: FlipH;
          filter: FlipH;
  -ms-filter: "FlipH";
}
.dly-br-cal-body {
  padding: 18px 68px;
}
.dly-br-cal-body table {
  width: 100%;
}
.dly-br-cal-body table th {
  text-align: center;
  padding-bottom: 5px;
}
.dly-br-cal-body table > thead > tr > th {
  border-bottom: 1px solid #B4B4AC;
}
.dly-br-cal-body table > tbody > tr:first-child > td {
  padding-top: 29px;
}
.dly-br-cal-day,
.dly-br-cal-day-empty {
  width: 102px;
  height: 67px;
}
.dly-br-cal-day {
  border: 1px solid #E8E8E6;
  border-radius: 2px;
  text-align: center;
  padding: 4px;
  margin: 4px;
  background-color: #F4F3EE;
}
.dly-br-cal-day:before,
.dly-br-cal-day:after {
  content: " ";
  display: table;
}
.dly-br-cal-day:after {
  clear: both;
}
.dly-br-cal-day span {
  display: block;
  margin-bottom: 5px;
}
.dly-br-cal-day input {
  float: left;
  width: 45px;
  height: 33px;
  border: 1px solid #D7D6D1;
  padding: 4px;
  text-align: right;
}
.dly-br-cal-day input:last-child {
  margin-left: 2px;
}
#dly-oc-cal-container {
  margin-right: 36px;
  float: left;
}
#dly-oc-self-container {
  float: left;
}
#dly-oc-comp-container {
  float: right;
}
a.dly-oc-add-comment {
  background-image: url('/img/add-outcome-comment.png');
  display: inline-block;
  width: 14px;
  height: 13px;
}
table.dly-oc input {
  width: 70px;
  text-align: right;
  padding-left: 2px;
}
table.dly-oc th {
  text-align: center;
}
table.dly-oc th.dly-oc-type {
  border: none;
  padding: 0;
  text-align: left;
}
table.dly-oc td {
  text-align: right;
  height: 52px;
  min-width: 80px;
}
table.dly-oc td,
table.dly-oc th {
  border: 1px solid #D7D6D1;
  padding: 6px 11px;
}
table.dly-oc .dly-oc-dates {
  text-align: left;
  border: none;
  width: 100px;
}
table.dly-oc .dly-oc-dates > div {
  position: relative;
}
table.dly-oc .dly-oc-dates > div > a.dly-oc-add-comment {
  position: absolute;
  left: -17px;
  top: 2px;
}
table.dly-oc .dly-oc-gray {
  background-color: #F8F8F6;
}
table.dly-oc > thead > tr > th:first-child {
  border: none;
}
table.dly-oc .dly-oc-avlb {
  border: 1px solid #D7D6D1 !important;
  text-align: right;
}
#daily-dashboard h2 {
  font-size: 20px;
}
#daily-dashboard .daily-dashboard-new-events {
  margin-bottom: 30px;
}
#daily-dashboard .daily-dashboard-new-events .dc-panel-body > div:first-child {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}
#daily-dashboard .daily-dashboard-new-events table {
  margin-bottom: 0;
}
#daily-dashboard .daily-dashboard-stats:before,
#daily-dashboard .daily-dashboard-stats:after {
  content: " ";
  display: table;
}
#daily-dashboard .daily-dashboard-stats:after {
  clear: both;
}
#daily-dashboard .daily-dashboard-stats .daily-dashboard-stats-yesterday {
  width: 25%;
  float: left;
  padding-right: 25px;
}
#daily-dashboard .daily-dashboard-stats .daily-dashboard-stats-yesterday .btn {
  margin: 15px 10px 10px;
}
#daily-dashboard .daily-dashboard-stats .daily-dashboard-stats-today {
  width: 25%;
  float: left;
  padding-right: 25px;
}
#daily-dashboard .daily-dashboard-stats .daily-dashboard-stats-today .btn {
  margin-top: 20px;
}
#daily-dashboard .daily-dashboard-stats .daily-dashboard-stats-a-week-ahead {
  width: 50%;
  float: left;
}
#daily-dashboard .daily-dashboard-forecast {
  margin-top: 30px;
}
#daily-dashboard .daily-dashboard-forecast .daily-dashboard-forecast-links {
  margin-top: 6px;
  float: right;
}
#daily-dashboard .daily-dashboard-forecast .daily-dashboard-forecast-links:before,
#daily-dashboard .daily-dashboard-forecast .daily-dashboard-forecast-links:after {
  content: " ";
  display: table;
}
#daily-dashboard .daily-dashboard-forecast .daily-dashboard-forecast-links:after {
  clear: both;
}
#daily-dashboard .daily-dashboard-forecast .daily-dashboard-forecast-links > a {
  float: left;
  margin-left: 20px;
}
.event-impact {
  padding: 0;
}
.event-impact .event-impact-textboxes {
  height: 33px;
  position: relative;
  left: 24px;
}
.event-impact .event-impact-textbox {
  position: absolute;
}
.event-impact .event-impact-chart {
  width: 100%;
  height: 315px;
}
.event-impact .event-impact-chart-bg {
  background-image: url('/img/chart/bg-grid.png');
}
.input-group-datepicker-sm .datepicker.input-sm {
  width: 85px;
}
.input-group-datepicker-md {
  min-width: 144px;
}
.input-group-datepicker-md .datepicker.input-sm {
  width: 100px;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-left: 0;
}
.mapper-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 450px;
}
.mapper-wrapper .mapper {
  height: 100%;
  width: 100%;
  margin-bottom: 5px;
  border: 1px solid #A6A5A1;
}
.mapper-wrapper .mapper-address-searcher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mapper-wrapper-700x450 {
  width: 700px;
  height: 450px;
}
/* Markdown editor */
.wmd-button-bar {
  width: 100%;
  background-color: Silver;
}
.wmd-input {
  height: 300px;
  width: 100%;
}
.wmd-preview {
  background-color: #c0e0ff;
}
.wmd-button-row {
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 10px;
  padding: 0px;
  height: 20px;
}
.wmd-spacer {
  width: 1px;
  height: 20px;
  margin-left: 14px;
  position: absolute;
  background-color: Silver;
  display: inline-block;
  list-style: none;
}
.wmd-button {
  width: 20px;
  height: 20px;
  padding-left: 2px;
  padding-right: 3px;
  position: absolute;
  display: inline-block;
  list-style: none;
  cursor: pointer;
}
.wmd-button > span {
  background-image: url('/img/wmd-buttons.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
  width: 20px;
  height: 20px;
  display: inline-block;
}
#wmd-link-button,
#wmd-quote-button,
#wmd-code-button,
#wmd-image-button {
  display: none;
}
.wmd-spacer1 {
  left: 50px;
}
.wmd-spacer2 {
  left: 175px;
}
.wmd-spacer3 {
  left: 300px;
}
.wmd-prompt-background {
  background-color: Black;
}
.wmd-prompt-dialog {
  border: 1px solid #999999;
  background-color: #F5F5F5;
}
.wmd-prompt-dialog > div {
  font-size: 0.8em;
  font-family: arial, helvetica, sans-serif;
}
.wmd-prompt-dialog > form > input[type="text"] {
  border: 1px solid #999999;
  color: black;
}
.wmd-prompt-dialog > form > input[type="button"] {
  border: 1px solid #888888;
  font-family: trebuchet MS, helvetica, sans-serif;
  font-size: 0.8em;
  font-weight: bold;
}
/* Markdown overlay */
.l10n-md-preview .modal-dialog {
  margin: 30px 0;
}
.l10n-md-preview .modal-info {
  width: 600px;
}
.l10n-md-preview .popover {
  position: relative;
  display: block;
  float: left;
  max-width: 276px;
  margin-top: 5px;
}
.l10n-md-preview .popover-content > markdown > p:last-child {
  margin-bottom: 0;
}
.status-bar {
  position: fixed;
}
.chart-bg-grid {
  background-image: url('/img/chart/bg-grid-big-vert.png'), url('/img/chart/bg-grid.png');
}
#market-dashboard .market-total > .market-title > div {
  background-color: #b45f06;
}
#market-dashboard .market-business > .market-title > div {
  background-color: #597eaa;
}
#market-dashboard .market-leisure > .market-title > div {
  background-color: #6aa84f;
}
#market-dashboard .market-business-individual .market-title div {
  background-color: #073763;
}
#market-dashboard .market-business-group .market-title div {
  background-color: #085394;
}
#market-dashboard .market-leisure-individual .market-title div {
  background-color: #274e13;
}
#market-dashboard .market-leisure-group .market-title div {
  background-color: #38761d;
}
#market-dashboard .market {
  cursor: pointer;
}
#market-dashboard .market .market-title {
  width: 100%;
  text-align: center;
}
#market-dashboard .market .market-title div {
  border: 1px solid black;
  border-radius: 5px;
  margin: 2px;
  color: white;
}
#market-dashboard .market-static .market-title div {
  height: 75px;
  font-size: 140%;
  padding-top: 23px;
}
#market-dashboard .market-dynamic .market-title div {
  height: 75px;
  font-size: 100%;
  padding-top: 13px;
}
#market-dashboard .market-children {
  width: 100%;
}
#market-dashboard .market-children:before,
#market-dashboard .market-children:after {
  content: " ";
  display: table;
}
#market-dashboard .market-children:after {
  clear: both;
}
#market-dashboard .market-children .market {
  float: left;
}
/*
// temp
.alert-info {
  background-color: #80D3F9;
  border-color: #02A8F3;
  color: #3d3b3b;
}

.alert-warning {
  background-color: #FFCB7F;
  border-color: #FF9700;
  color: #3d3b3b;
}

.alert-danger {
  background-color: #EE9999;
  border-color: #F34235;
  color: #3d3b3b;
}

.alert-success {
  background-color: #A4D5A6;
  border-color: #4BAE4F;
  color: #3d3b3b;
}*/
div.six-week-forecast {
  page-break-inside: avoid;
}
table.six-week-forecast td:nth-child(1) {
  width: 90px;
  padding-right: 0;
}
table.six-week-forecast td:nth-child(2) {
  width: 125px;
  padding-left: 10px;
}
table.six-week-forecast td:nth-child(3),
table.six-week-forecast td:nth-child(4),
table.six-week-forecast td:nth-child(5),
table.six-week-forecast td:nth-child(6) {
  width: 120px;
}
table.six-week-forecast th:nth-child(6),
table.six-week-forecast td:nth-child(7) {
  padding-left: 60px;
}
table.six-week-forecast td:nth-child(7),
table.six-week-forecast td:nth-child(8) {
  width: 295px;
}
table.six-week-forecast tr.history {
  color: #B4B3B1;
  font-style: italic;
}
#debug-panel {
  position: fixed;
  right: 15px;
  bottom: 15px;
  margin-bottom: 0;
  width: 200px;
}
#debug-panel input[type='text'],
#debug-panel textarea {
  width: 100%;
}
.fa-sort-asc,
.fa-sort-desc {
  color: #ccc;
}
.sorting-asc .fa-sort-asc {
  color: #232323;
}
.sorting-desc .fa-sort-desc {
  color: #232323;
}
.u-text--left {
  text-align: left !important;
}
.u-text--center {
  text-align: center !important;
}
.u-text--right {
  text-align: right !important;
}
.u-text--normal {
  font-weight: normal;
}
.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.truncate-clip {
  overflow: clip !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.label-vertical {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-lr;
          writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  background-color: #4a196f;
  color: #fff;
  line-height: 1;
  padding: 0.4em;
  width: 26px;
}
.performance-dashboard-otb__table {
  height: 434px;
  width: 160px;
}
.performance-dashboard-otb__fake-th {
  line-height: 21px;
  font-size: 14px;
  margin: 0;
  padding: 5px 1em 0;
}
.chain-facility-facility-list {
  white-space: normal;
}
.chain-facility-facility-list__facility {
  display: inline-block;
  white-space: nowrap;
}
.highcharts-tooltip-container svg.highcharts-root {
  overflow: visible !important;
}
.highcharts-tooltip-container {
  z-index: 9999;
}
dc-sorted-event-comment-holiday-list fieldset {
  padding: 0;
  border-top: 1px solid #eaeaea;
}
dc-sorted-event-comment-holiday-list fieldset:not(:last-child) {
  margin-bottom: 0.5em;
}
dc-sorted-event-comment-holiday-list legend {
  margin-left: 0.5em;
  margin-bottom: 0;
  padding: 0 0.5em;
  width: auto;
  font-size: inherit;
  border: 0;
}
dc-sorted-event-comment-holiday-list .sorted-bucket-list__entries {
  margin: 0;
  padding-left: 1.25em;
}
dc-sorted-event-comment-holiday-list .sorted-bucket-list__entry {
  margin-bottom: 0.2em;
}
dc-sorted-event-comment-holiday-list .sorted-bucket-list__entry-date {
  color: #606f7b;
}
dc-sorted-event-comment-holiday-list .sorted-bucket-list__entry-title {
  border-bottom: 1px dotted;
}
.dropdown {
  font-size: 1.3rem;
}
.dropdown-menu > li > a {
  padding: 3px 15px;
  line-height: 1.8;
}
.dropdown-menu > li > a i {
  margin-right: 1em;
}
.dropdown-menu > li > button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  gap: 4px;
  width: 100%;
}
.modal-open .dropdown-menu {
  z-index: 1050;
}
.dropdown-menu-top {
  top: auto;
  bottom: 100%;
}
[uib-typeahead-popup].dropdown-menu {
  padding: 0;
  margin-bottom: 1rem;
}
[uib-typeahead] + [uib-typeahead-popup].dropdown-menu {
  min-width: 100%;
}
.dropdown-menu .uib-typeahead-match a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5rem 1rem;
}
.dropdown-menu .uib-typeahead-match.active a,
.dropdown-menu .uib-typeahead-match a:focus {
  background-color: #f1f5f8;
  color: #555;
  -webkit-box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
          box-shadow: 0 0 0 4px rgba(74, 25, 111, 0.2), 0 0 0 1px rgba(74, 25, 111, 0.2);
  border-radius: 4px;
  outline: none;
}
[typeahead-inline-result="true"] + .dropdown-menu {
  position: relative;
  top: auto !important;
  left: auto !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 100%;
  border: 0;
}
.pac-container {
  z-index: 1051;
}
.fa-rotate-45 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.group:hover .group-hover\:visible {
  visibility: visible !important;
}
.group:hover .group-hover\:invisible {
  visibility: hidden !important;
}
.group:hover .group-hover\:inline {
  display: inline !important;
}
.group:hover .group-hover\:block {
  display: block !important;
}
.group:hover .group-hover\:flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.group:hover .group-hover\:inline-block {
  display: inline-block !important;
}
.group:hover .group-hover\:inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
@-webkit-keyframes animatedThreeDots {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
}
@keyframes animatedThreeDots {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
}
.animated-periods::after {
  content: "";
  display: inline;
  -webkit-animation: animatedThreeDots 1s linear infinite;
          animation: animatedThreeDots 1s linear infinite;
}
@media screen {
  .truncated--indicator {
    border-bottom: 1px dotted;
  }
}
dc-graph-heatmap .highcharts-container,
dc-graph-heatmap .highcharts-root {
  width: 100% !important;
}
